import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'glamor';
import * as styles from './subscriptioncategory.style';
import HeaderSection from '../common/header/header';
import HeaderTabletSection from '../common/header/header.tablet';
import FooterSection from '../common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import axios from 'axios';
import * as navigation from '../../configs/navigation';
import Collapsible from 'react-collapsible';
import expandIcon from '../../assets/icons/expand-small_darkblue.svg';
import * as translate from '../../assets/translations/translations';
import * as categories from '../../configs/subscription';
import { connect } from 'react-redux';
import * as subscription from '../../store/subscription'

class SubscriptionCategory extends React.Component {

    constructor(props) {
        super();

        this.state = {
            category: '',
            subCategoriesList: [],
            selectedList: [],
            subscriptionDetail: [],
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let categoryType = this.props.match.params.category;
        this.setState({ category: categoryType })
        let categoryData = categories.subscriptions.filter(subscription => subscription.category == categoryType)
        let subCategories = categoryData[0].subcategory;
        this.setState({ subCategoriesList: subCategories })
    }

    handleCheckboxChange(categoryname, language, event) {
        event.stopPropagation()

        if (event.target.checked == true) {
            this.setState({ selectedList: this.state.selectedList.concat({ "Id": null, "SubscriptionId": null, "CategoryName": categoryname, "Language": language }) });
        }
        else {
            let selectedListSet = this.state.selectedList;
            selectedListSet = selectedListSet.filter(function (obj) {
                return obj.CategoryName !== categoryname;
            });
            this.setState({ selectedList: selectedListSet });
        }
        setTimeout(() => {
        }, 200)

    }

    updateSubscriptionDetails() {
        let subscriptionObject = {
            "SubscriptionId": null,
            "Category": "",
            "Name": "",
            "Company": "",
            "Advisor": "",
            "Agreement": true,
            "Confirm": true,
            "Email": "",
            "SubCategory": []
        };

        subscriptionObject.Category = this.state.category;
        subscriptionObject.SubCategory = this.state.selectedList;
    }

    generateHeading(subcategory) {
        return <div className="container-fluid" {...css(styles.noPadding)}>
            <div className="row">
                <div className="col-md-8 col-lg-8 col-sm-8 col-8" {...css(styles.subCategory)}>
                    {subcategory.categoryname}
                </div>
                <div className="col-md-3 col-lg-3 col-sm-3 col-3" {...css(styles.subCategoryCountry)}>
                    {subcategory.language}
                </div>
                <div className="col-md-1 col-lg-1 col-sm-1 col-1">
                    <img src={expandIcon} />
                </div>
            </div>
        </div>;
    }

    generatesubcategories(subcategories) {
        return subcategories.map((subcategory, key) => {
            return (
                <div className="container-fluid">
                    <div className="row" {...css(styles.headingRow)}>
                        <div className="col-md-1 col-lg-1 col-sm-1 col-1">
                            <input type="checkbox" name={subcategory.categoryname} value={subcategory.categoryname}
                                onChange={(e) => this.handleCheckboxChange(subcategory.categoryname, subcategory.language, e)} key={subcategory.categoryname} ref={subcategory.categoryname} />
                        </div>
                        <div className="col-md-11 col-lg-11 col-sm-11 col-11" {...css(styles.subCategory)}>
                            <Collapsible key={key} trigger={this.generateHeading(subcategory)} transitionTime="200">
                                <p {...css(styles.categoryDetails)}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse quis venenatis metus, eget tincidunt tellus. Praesent eget hendrerit orci. Donec pulvinar nulla non arcu ultrices, in tincidunt purus ultrices. Ut lacinia sem leo, id rhoncus felis aliquet a. Donec interdum viverra ipsum, in dapibus ante hendrerit ac. Aliquam erat volutpat. Donec dui nisl, facilisis volutpat bibendum nec, elementum vel sapien. Sed vel sollicitudin mauris.
                                    <br /><br />
                                    Aliquam a pulvinar tellus, a tincidunt nisi. Suspendisse suscipit ligula a elit eleifend porttitor. Ut auctor feugiat dictum. Morbi hendrerit vitae dui at volutpat.
                                </p>
                            </Collapsible>
                        </div>

                    </div>
                </div>
            )
        })

    }

    render() {
        return (
            <div>
                <HeaderTabletSection />
                <HeaderSection />
                <NavBar items={navigation.navInfo} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-6" {...css(styles.subscriptionContent)}>
                            <h2 {...css(styles.H2)}>{translate.strings.TR54}</h2>
                            <p {...css(styles.subTitle)}>{translate.strings.TR37}</p>
                            <div {...css(styles.collapsibleContainer)}>
                                {this.generatesubcategories(this.state.subCategoriesList)}

                                <Link {...css(styles.Button)} to={`/subscription`} onClick={this.updateSubscriptionDetails()}>Done</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterSection />
            </div>
        )
    }
}

export default SubscriptionCategory;