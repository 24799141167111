import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './imagetext.style';
import { Link } from 'react-router-dom';
import DKFlag from '../../assets/icons/DK.svg';
import UKFlag from '../../assets/icons/GB.svg';
import SEFlag from '../../assets/icons/SE.svg';
import NOFlag from '../../assets/icons/NO.svg';
import FIFlag from '../../assets/icons/FIN.svg';
import rightArrow from '../../assets/icons/arrow-link-next_darkblue.svg';
import chevronRight from '../../assets/icons/chevronRight.svg';
import * as translate from '../../assets/translations/translations';

export default class ImageText extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        var url = this.props.url.replace(/\s+/g, '-');
        return (
            <Link className="container-fluid" {...css(styles.imageTextContainer)} to={`/articlelist/${this.props.url}`}>
                <div className="row" {...css(styles.rowHeight)}>
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12" {...css(styles.container)}>
                        <div {...css(styles.imageContainer)}>
                            <img src={this.props.image} {...css(styles.image)} alt={"What's new"} />
                        </div>
                        <div {...css(styles.textContent)} style={{ marginBottom: '30px' }} role="region" aria-label={'Article'}>
                            <p {...css(styles.date)} >{this.props.date}</p>
                            <Link {...css(styles.H2)} to={`/articlelist/${url}`}>{this.props.title} </Link>
                            <p {...css(styles.textParagraph)} >{this.props.desc}</p>

                        </div>
                        <div {...css(styles.flagsContent)} role="region" aria-label='Read More'>
                            <div >
                                <Link {...css(styles.readMore)}
                                    to={`/articlelist/${url}`} style={{ minWidth: '44px', minHeight: '44px' }}>
                                    {translate.strings.TR18} <img {...css(styles.rightArrow)} src={chevronRight} alt={"Right Arrow"} />
                                    {/* <img {...css(styles.rightArrow)} src={rightArrow} alt={"Right Arrow"} /> */}
                                </Link>
                            </div>

                        </div>
                    </div>

                </div>
            </Link>
        );
    }
}