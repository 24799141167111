import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './navbarlink.style';
import { Link } from 'react-router-dom';
import * as menuStyles from './popupmenu.style';
import * as translate from '../../assets/translations/translations';
import { v4 as uuidv4 } from 'uuid';

export default class NavBarLink extends Component {
    render() {
        return (
            <div className="nav-item" {...css(styles.link)} >
                <span className="nav-item-link-text" tabindex="0">
                 {this.props.text == translate.strings.TR251 
                    ? <Link {...css(menuStyles.submenuItems)} to={'/home'}> {this.props.text}</Link> 
                    : this.props.text == translate.strings.TR252 
                        ? <Link {...css(menuStyles.submenuItems)} to={'/commissionedresearch'} key = {uuidv4()}> {this.props.text}</Link> 
                        : this.props.text == translate.strings.TR83 && this.props.commr == true
                            ? <Link {...css(menuStyles.submenuItems)} to={'/commrAboutPage'} key = {uuidv4()}> {this.props.text}</Link> 
                            : <span>{this.props.text}</span>}
                </span>
            </div>
        );
    }
}