export const tabs = {
    textAlign: 'left',    
    color: '#003F63',
    font: '500 2.25em Danske Human',
    marginBottom: '30px'
}

export const tab = {
    width: '100%',
    height: '100%',
    backgroundColor:'white'
}

export const menubar = {
    padding: '0 5% 0',
    overflow: 'auto',
    marginBottom: '0'    
}

export const locale = {
    padding: '15px 25px'
}

export const categories = {
    borderBottom: '1px solid #dbe0de',
    padding: '12px 0',
    cursor:'pointer',
    backgroundColor: 'transparent',
    color: '#003755',
    '&:first-child': {
        borderTop: '1px solid hsla(0,0%,100%,0)'
    }   
}

export const categoryItem = {
    backgroundColor: '#d7e9f1',
    padding: '10px 5% 10px 0'
}

export const selectedCategory = {
    fontWeight: 'bold',
    color: '#003755'
}
export const submenuTitle = {
    fontWeight: 'bold',
    padding: '12px 0',
    color: '#003755',
    borderBottom: '1px solid #dbe0de'
}
export const submenuLinks = {
    padding: '12px 0',
    borderBottom: '1px solid #dbe0de',
    color: '#003755',
    textDecoration: 'none'
}
export const link = {
    color: '#003755'
}
export const padding15 = {
    margin: '0 5% 20px 5%'
}
export const submenuContainer = {
    padding: '0 5%'
}
export const nextArrow = {
    float: 'right',
    height: '25px',
    width: '18px'
}
export const externalContent = {
    backgroundColor: '#F3F5F4',
    borderBottom: '1px solid #dbe0de',
    padding: '12px 0',
    cursor:'pointer',    
    color: '#003755',
    '&:first-child': {
        borderTop: '1px solid hsla(0,0%,100%,0)'
    }
}
export const externalContentAnchor = {    
    color: '#003755'    
}

export const externalImages = {    
    margin: '0 10px 0 5px'
}