export const signupContainer = {
    padding: '0 7%',
    backgroundColor: '#003755',
    height: '400px',
    overflow: 'hidden'
};
export const signupH2 = {
    textAlign: 'center',
    color: '#FFF',
    font: '500 2.75em Danske Human',
    textAlign: 'left',
    '@media(min-width: 0px) and (max-width:767px)': {
        font: '500 2.25em Danske Human'
    }
};
export const signupParagraph = {
    textAlign: 'center',
    color: '#FFF',
    font: '1em Danske Text',
    textAlign: 'left',
    marginTop: '20px',
    opacity: '.8'
};
export const signupButton = {
    // border: '1px solid #37B0DE',
    border: '1px solid #007BC7',
    borderRadius: '40px',
    // background: 'linear-gradient(0deg, #0097CF 0%, #00AAE8 100%)',
    backgroundColor: '#007BC7',
    padding: '15px 50px',
    textAlign: 'center',
    color: '#FFF',
    textDecoration: 'none',
    display: 'inline-block',
    marginTop: '25px',
    '&:hover': {
        color: '#FFF',
        textDecoration: 'none',
    }
};
export const textSection = {
    padding: '85px 10% 0 0'
};
export const researchImage = {
    width: '68%',
    marginTop: '50px',
    '@media(min-width: 0px) and (max-width:767px)': {
        display: 'none'
    }
}