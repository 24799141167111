import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'glamor';
import * as styles from './subscription.style';
import HeaderSection from '../common/header/header';
import HeaderTabletSection from '../common/header/header.tablet';
import FooterSection from '../common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import axios from 'axios';
import * as navigation from '../../configs/navigation';
import * as translate from '../../assets/translations/translations';

class SubscriptionConfirm extends React.Component {

    render() {
        return (
            <div>
                <HeaderTabletSection />
                <HeaderSection />
                <NavBar items={navigation.navInfo} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 col-lg-5 col-sm-8 col-8" {...css(styles.subscriptionContent)}>
                            <div>
                                <h2 {...css(styles.H2)}>{translate.strings.TR40}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterSection />
            </div>
        )
    }

}

export default SubscriptionConfirm;
