import React from 'react';
import httpProvider from '../../services/httpProvider.service'
import { css } from 'glamor';
import * as styles from './articlepage.style';
import HeaderSection from '../../components/common/header/header';
import HeaderTabletSection from '../../components/common/header/header.tablet';
import FooterSection from '../../components/common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import * as navigation from '../../configs/navigation';
import Search from '../search/search';
import ArticlePageView from './ArticlePageView';

export default class ArticleShortPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            article: null,
            articleLoaded: false
        }

        httpProvider.get(window.env.fromShortBaseUrl + this.props.match.params.baseid, {data: null}).then(response => {
            if (response.status === 200)
                this.setState({ article: response.data, articleLoaded: true });
            else
                this.goToHomePage();
        }).catch(() => { });
    }

    goToHomePage() { window.location = '/'; }

    render() {
        return (
            <div>
                <HeaderTabletSection history={this.props.history} />
                <HeaderSection showSearch={this.showSearchComponent} history={this.props.history} />
                <NavBar items={navigation.navInfo} />
                {this.state.showSearch ? <Search homepage={false} /> : null}
                {this.state.articleLoaded ? <ArticlePageView article={this.state.article} /> : <div className="container-fluid" {...css(styles.articleContent)} />}
                <FooterSection />
            </div>
        );
    }
}
