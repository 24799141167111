import React from 'react';

class RedirectToEbank extends React.Component {
    constructor(props) {
        super(props);
        
        this.props.history.push('/articlelist/Ebank');
    }

    render() {
        return (
            <div>
            </div>
        )
    }
}

export default RedirectToEbank;