import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './termofuse.style';
import CloseIcon from '../../assets/icons/close-small_darkblue.svg';
import Modal from 'react-modal';
import * as translate from '../../assets/translations/translations';


export default class TermOfUse extends Component {
    constructor(props) {
        super(props);

        this.closeTermOfUser = this.closeTermOfUser.bind(this);
        Modal.setAppElement('#root');
    }

    closeTermOfUser(status) {
        this.props.onTermOfUserRequestingClose(status);
    }


    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalTermOfUserIsOpen}
                    onRequestClose={this.closeTermOfUser}
                    contentLabel="Terms of use of website "
                    shouldCloseOnOverlayClick={true}
                    overlayClassName="Overlay"
                    className="Modal TermsOfUseModal"
                    tabindex="-1"
                >
                    <div style={{ zIndex: '9999', position: 'relative' }} >
                        <header >
                            {/* <h2 {...css(styles.H2) }>{translate.strings.TR77}</h2> */}
                            <h1 {...css(styles.H2)}>Terms of use</h1>
                            <div>

                            </div>
                            <a onClick={this.closeTermOfUser} className="button-close close-reveal-modal" tabindex="-1">
                                <img src={CloseIcon} {...css(styles.closeIcon)} alt='close' />
                            </a>
                        </header>
                        <div>
                            <div {...css(styles.divParah)} tabindex="0">
                                <p {...css(styles.paragraph)}>
                                    Please read these terms of use carefully. The use of this website and subsites (“the site”) and any materials contained herein is permissible only in accordance with the following terms of use.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    The terms below have been prepared by the Danske Bank Group for use in respect of Danske Bank A/S web pages. The term “Danske Bank Group” includes the parent company, Danske Bank A/S, and all other divisions and units of the Danske Bank Group.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    The content has been prepared in accordance with Danish law and the information provided may not satisfy the laws of any other country.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    <p {...css(styles.subParagraph)}>For customers resident in the US, UK or other countries</p>
                                    Any information or opinions on the site are not intended for distribution to, or use by, any person in any jurisdiction or country where such distribution or use would be unlawful.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    Thus the information on the site is not directed at or intended for “US Persons” within the meaning of the United States Securities Act of 1933, as amended, and the United States Securities Exchange Act of 1934, as amended.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    Information on this site is intended for investment professionals only and is not intended for private investors in the UK.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    <p {...css(styles.subParagraph)}>Information on the website and liability</p>
                                    The content of the site is for information purposes only. The information does not constitute or form part of, and should not be construed as, an offer to sell or issue or the solicitation of an offer to buy or acquire products or services of the Danske Bank Group.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    The Danske Bank Group has taken reasonable care to ensure that the information and functions contained on the site are complete and correct but does not warrant this to be the case and accepts no liability for any errors, spelling mistakes or omissions. Neither is the Danske Bank Group responsible for the suitability of information on or content of the site.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    Any information or opinions on the site were issued by the Danske Bank Group on the basis of information obtained from sources believed to be reliable but not verified independently.

                                </p>
                                <p {...css(styles.paragraph)}>
                                    The information, calculations, assessments and estimations on the site shall not be construed as investment advice or as requests to engage in investment operations. Neither should it be construed as recommendations or advice on legal or regulatory aspects, accounting treatments or tax consequences of any individual arrangements. The Danske Bank Group is not liable for loss or damage resulting from customers’ action or failure to act on the information mentioned above.

                                </p>
                                <p {...css(styles.paragraph)}>
                                    Customers are always responsible for the financial results of their own investment activities. Any return estimates, past stock-market or fund performance is no guarantee of future profits.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    <p {...css(styles.subParagraph)}>Price information</p>
                                    Prices, rates and values published on the site are provided for information purposes only and are indicative unless otherwise stated. Such prices, rates and values do not necessarily represent the terms that would apply to new transactions or existing positions. You should always check prices, rates and values before completing transactions.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    <p {...css(styles.subParagraph)}>Disclosure of interest</p>
                                    The Danske Bank Group, including its branches, subsidiaries, divisions and staff, may, to the extent permissible by law, perform services for, solicit business from, hold long or short positions in, or otherwise be interested in the investments, including derivatives, of any issuer mentioned on the site. The Danske Bank Group’s research analysts are not permitted to invest in securities related to their own research sectors.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    <p {...css(styles.subParagraph)}>Links to other websites</p>
                                    The site offers access to other sites of the Danske Bank Group, which are subject to separate Terms of Use as published thereon. The site may also offer access to third-party websites through relevant links or offer or convey products, services and information provided by a third party. The Danske Bank Group has not reviewed and is not responsible or liable for the contents of websites provided by other parties than the Danske Bank Group, including products or services provided by such parties.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    <p {...css(styles.subParagraph)}>Website security</p>
                                    <p>Although we believe the site to be free from viruses, malware and other errors that might affect your IT systems, you access and use the site at your own risk. The Danske Bank Group assumes no responsibility for any loss or damage arising from the use of the site. Furthermore, the Danske Bank Group accepts no responsibility for the availability of the site.</p>
                                </p>

                                <p {...css(styles.paragraph)}>Neither the Danske Bank Group nor any of its officers and employees accept any liability for any damage or direct, indirect or consequential loss arising from the availability or use of the site, including any harm or defect caused to your computer as a result of accessing and using the IT systems that makes the site available.</p>

                                <p {...css(styles.paragraph)}>Data entered in enquiry forms, including contact forms, submitted via the site are protected by SSL encryption and is not disclosed to third parties. Enquires submitted through the Danske Bank Group’s eBanking solutions are protected by the type of SSL encryption that protects eBanking transactions.</p>

                                <p {...css(styles.paragraph)}>E-mails sent to us through the (open) Internet may not be encrypted. Therefore, we recommend that you do not include personal information in your e-mails.</p>

                                <p {...css(styles.paragraph)}>
                                    <p {...css(styles.subParagraph)}>Ownership and intellectual property rights</p>
                                    The Danske Bank Group is the author of material published on the site. The Danske Bank Group and its units reserve all rights to ownership, copyright, trademarks and all other intellectual property rights relating to the site and to products and services mentioned on the site – unless stated that such rights are the property of a third party.
                                </p>
                                <p {...css(styles.paragraph)}>
                                    As a user of this site, you may view and print the contents mentioned above for personal, non-commercial use only. You may not otherwise copy, save or otherwise reproduce, adapt, modify, transfer, assign, use or exploit the contents or parts thereof without our prior written consent.
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}