import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './popupmenu.style';
import { Link } from 'react-router-dom';
import * as translate from '../../assets/translations/translations';
import externalLink from '../../assets/icons/externalLinkIcon.svg';
import expandIconHighlight from '../../assets/icons/expand-small_darkblue_highlight.svg';
import PropTypes from 'prop-types';

export default class PopupMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        };

        this.setShowState = this.setShowState.bind(this);        
        this.hidePopup = this.hidePopup.bind(this);        
    }

    setShowState(value){
        this.setState({ show: value }) 
    }

    generateSubmenuBlock = (submenus) => {
        if(submenus){
            var menu = submenus.map(this.generateSubmenu);
            return(
                <div {...css(styles.submenuContainer)}> 
                    <div {...css(styles.submenuInnerContainer)} className="mb-5"> {menu} </div>
                    {
                        !this.isSeeAllExcludedSection(this.props.parent.text) && (
                        <div {...css(styles.submenuNavigateParent)} className="text-center">
                            <Link key={this.props.parent} {...css(styles.submenuItems)} className="highlight-link" to={`/category/${this.fixUrl(this.props.parent.url)}`}>
                            { this.format(translate.strings.TR226, this.props.parent.text) }
                            <img {...css(styles.imgRotate90)} src={expandIconHighlight}/>
                            </Link> 
                        </div>)
                    }
                </div>);
        }
    };

    isSeeAllExcludedSection(section){
        return section === translate.strings.TR83 || section === translate.strings.TR82 || section === translate.strings.TR74 || section === translate.strings.TR222
            || section === translate.strings.TR250;
    }

    format(value, ...args)
    {
       var content = value;
       for (var i=0; i < args.length; i++)
       {
            var replacement = '{' + i + '}';
            content = content.replace(replacement, args[i]);  
       }
       return content;
    };

    generateSubmenu = (submenu, index) => {
        if(submenu){
            var items = submenu.items.map((items, i) => this.generateSubmenuItems(items, i));
            return <ul {...css(styles.listcontainer)} className={this.marginExceptLastItem(index, this.props.submenu.length)} >
                        <li {...css(styles.submenuItemsTitle)}>{submenu.title}</li>
                        {items}
                    </ul>
        }
    };
    
    marginExceptLastItem(currentIndex, length){
        return (length - 1) !== currentIndex ? "mr-5" : null;
    }

    generateSubmenuItems = (item, i) => {
        var url = this.fixUrl(item.url);
        return <li {...css(styles.submenuItems)} key={i}>
            {(item.text ==  translate.strings.TR13 || item.text == translate.strings.TR84 || item.text == translate.strings.TR85) ?
            <Link key={i} {...css(styles.submenuItems)} to={`/${item.url}`}> {item.text}</Link> :
            item.url == 'Other Comments' ?
            <a target="blank" href="https://danskebank.com/-/media/pdf/danske-research/other-comments.pdf" {...css(styles.externalSubmenuItems)} >{item.text} <img src={externalLink} alt={"External Link"} /></a> :
            item.url == 'Danske Equities Log In' ?
            <a target="blank" href="https://equities.danskemarkets.com/pub/logon/logon.aspx?ss=EP" {...css(styles.externalSubmenuItems)} >{item.text} <img src={externalLink} alt={"External Link"}/></a> :
            item.url == translate.strings.TR188 ? <Link key={i} {...css(styles.submenuItems)} to={'/commissionedresearch'}> {item.text}</Link> :
            <Link key={i} {...css(styles.submenuItems)} to={this.props.commr ? `/articlelist/${url}/commr` : `/articlelist/${url}`}> {item.text}</Link>}
        </li>
    }

    fixUrl(currentUrl) {
        return currentUrl.replace(/\s+/g, '-');
    }

    render() {
        var submenu = this.generateSubmenuBlock(this.props.submenu);
        return ( 
            this.state.show && 
                <div {...css(styles.hidePopupOnSmallSize)}>
                     <div {...css(styles.navOverlay)} onClick={() => this.hidePopup()}></div>
                     {submenu}
                </div>
        );
    };
    
    hidePopup = () => {
        this.setShowState(false)
    }
}

PopupMenu.propTypes = {
    submenu: PropTypes.array
};