export const searchContainer = {
    padding: '70px 0',
    '@media(min-width: 0px) and (max-width:767px)': {
        padding: '40px 0'
    } 
};
export const searchContainerDropdown = {
    padding: '25px 0',
    '@media(min-width: 0px) and (max-width:767px)': {
        padding: '20px 0'
    } 
};
export const searchH2 = {
    textAlign: 'center',    
    color: '#003755',
    font: '500 2.75em Danske Human',
    '@media(min-width: 0px) and (max-width:767px)': {
        font: '500 2.25em Danske Human'
    }     
};
export const searchInputContiner = {
    margin: '20px auto 0',    
    width: '35%',
    '@media(min-width: 0px) and (max-width:767px)': {
        width: '75%'
    }     
};
export const searchInput = {       
    width: '85%',
    height: '50px',
    borderRadius:'40px 0 0 40px',
    border: '1px solid #EBEBEB',
    borderRight:'0',
    outline: 'none',
    padding:'0 3%',
    float: 'left',
    '&:focus':{
        transition: 'box-shadow 0.5s, border-color 0.25s ease-in-out',
        border: '1px solid #009FDA'
    }
};
export const searchIconSection = {      
    width: '15%',
    float: 'left',
    backgroundColor: '#00A3DE',
    borderRadius: '0 40px 40px 0',
    height:'50px'
};
export const searchIcon = {      
    padding: '14px 28%',
    '@media(min-width: 768px) and (max-width:1024px)': {
        padding: '14px 16%',
    }   
};