export const mainContainer = {
    margin: '25px auto 0',
    '@media(max-width: 1023px)': {
        width: '100%'
    },
    '@media(min-width: 1024px)': {
        width: '100%'
    }
}
export const localeTitle = {
    color: '#003755',
    fontSize: '1em',
    display: 'block',
    fontWeight: 'bold'
}
export const image = {
    maxWidth: '100%',
    margin: '25px 0',
    padding: '0 15px 0 0'
}
export const summaryText = {
    fontSize: '.875em',
    lineHeight: '1.275em',
    margin: '10px 0 20px',
    color: '#003755',
    padding: '0 15px 0 0'
}
export const padding0 = {
    padding: '0 !important'
}