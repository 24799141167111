import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import PodcastCard from '../../components/podcastcard/podcastcard';
import * as styles from './podcastchannelselection.style';
import * as translate from '../../assets/translations/translations';
import { css } from 'glamor';
import externalLink from '../../assets/icons/externalLinkIcon.svg';
import podcastInfos from '../../configs/podcastsconfig.json';

export default class PodcastChannelSelection extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpened: false };
    }

    renderPodcasts(podcasts) {
        var agreementAccepted = localStorage.getItem('agreementAccepted');

        return podcasts.filter(podcast => agreementAccepted == 'true' || !podcast.Exclusive).map((podcast, key) => {
            return (
                <div key={key} className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" >
                    <PodcastCard
                        thumbnail={podcast.Thumbnail}
                        language={podcast.Language}
                        title={podcast.Alias}
                        links={podcast.Links}
                    />
                </div>
            )
        })
    }

    open = () => {
        this.setState({ isOpened: true });
    }

    close = () => {
        this.setState({ isOpened: false });
    }

    togglePodcastsVisibility = () => {
        this.setState({ isOpened: !this.state.isOpened })
    }

    render() {
        return (
            <div aria-hidden="true" style={{ display: 'none' }}>
                <Collapse isOpened={this.state.isOpened} {...css(styles.podcastsContainer)}>
                    <div>
                        <button type="button" className="close" onClick={this.close} aria-label="Close" {...css(styles.close)}><span aria-hidden="true">&times;</span></button>
                        <div className="container-fluid" {...css(styles.section)}>
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                    <div {...css(styles.H2)}>{translate.strings.TR231}</div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center row">
                                {this.renderPodcasts(podcastInfos.PodcastsInfo)}
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }
}