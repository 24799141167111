import React from 'react';
import { css } from 'glamor';
import * as styles from './exclusivecontentwarning.style';
import Disclaimer from '../disclaimer'
import * as translate from '../../assets/translations/translations';

export default class ExclusiveContentWarning extends React.Component {

    constructor(props) {
        super(props);
        this.state = 
        { 
            disclaimerModalIsOpen: false 
        };
        this.handleDisclaimerClose = this.handleDisclaimerClose.bind(this);
        this.handleDisclaimerOpen = this.handleDisclaimerOpen.bind(this);
    }

    handleDisclaimerClose() {
        this.setState({ disclaimerModalIsOpen: false });
    }

    handleDisclaimerOpen() {
        this.setState({ disclaimerModalIsOpen: true });
    }

    openDisclaimer() {
        this.setState({ disclaimerModalIsOpen: true });
    }

    render() {
        return (
            <div>
                <div className="container-fluid" {...css(styles.articleContent)}>
                    <section>
                        <div>
                            <p {...css(styles.paragraph)}>{translate.strings.TR236}</p>
                            <div {...css(styles.textCenter)}>
                                <button {...css(styles.disclaimerButton)} type='button' onClick={() => this.openDisclaimer()}>{translate.strings.TR237}</button>
                            </div>
                        </div>
                    </section>
                </div>
                <Disclaimer modalIsOpen={this.state.disclaimerModalIsOpen} onDisclaimerRequestingClose={this.handleDisclaimerClose} onDisclaimerAgreementSigned={() => {}} />
            </div>
        );
    }

}