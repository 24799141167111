export const card = {
    marginTop: '40px',
    border: 'none !important',
    height: '90%',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover > img': {
        transform: 'scale(1.1)'
    },
    '&:hover a > img': {
        '-ms-transform': 'translateX(.3em)',
        transform: 'translateX(.3em)'
    }
};
export const cardImage = {
    borderRadius: '5px',
    height: '190px',
    transition: 'transform 1s cubic-bezier(.215,.61,.355,1)'

};
export const cardAnchor = {
    // color: '#009FDA',
    color: '#007BC7',
    fontSize: '1em',
    display: 'inline-block',
    textDecoration: 'none !important',
    '&:hover': {
        color: '#009FDA'
    },
}
export const cardBody = {
    padding: '30px 8%',
    position: 'relative'
}
export const cardTitle = {
    fontWeight: '600',
    fontSize: '1.25em',
    lineHeight: '1.275em',
    margin: '6px 0 5px',
    color: '#003755',
}
export const cardText = {
    fontSize: '.875em',
    lineHeight: '1.275em',
    margin: '10px 0 30px',
    color: '#003755',
    maxHeight: '100px'
}
export const cardDate = {
    fontSize: '.875em',
    lineHeight: '1.275em',
    margin: '2px 0',
    // color: '#7F9BAA'
    color: '#556D7E'
}
export const shareContainer = {
    bottom: '15px',
    position: 'absolute',
    left: '0',
    right: '0',
    padding: '0 7%',
    marginBottom: '5px'
}
export const rightArrow = {
    verticalAlign: 'middle',
    height: '16px',
    transition: 'all .12s cubic-bezier(0,.3,.7,1)',
    '@media(min-width: 768px) and (max-width:1024px)': {
        display: 'inline-block !important',
        width: 'auto !important'
    },
    '@media(min-width: 0px) and (max-width:767px)': {
        display: 'inline-block !important',
        width: 'auto !important'
    }
}
export const readMore = {
    float: 'right',
    fontSize: '14px',//'.875em',
    marginTop: '7px',
    // color: '#003755',
    color: '#007BC7',
    cursor: 'pointer',
    '&:hover': {
        // color: '#003755'
        color: '#007BC7'
    },
    minWidth: '44px',
    minHeight: '44px',
}
export const focusTag = {
    position: 'absolute',
    top: '-15px',
    // backgroundColor: '#009FDA',
    backgroundColor: '#007BC7',
    color: '#FFF',
    borderRadius: '2px',
    fontSize: '.75em',
    padding: '10px'

}