export const faqList = [
    {
        "question":"What is Danske Bank’s research website?",
        "answer":"Danske Bank’s research website contains research from all Danske Bank’s research departments: Macroeconomics, Fixed Income, Foreign Exchange, Commodities, Debt Capital Markets (DCM) and Equities.",
    },
    {
        "question": "Who is Danske Bank’s research website aimed at?",
        "answer": 'The research website is primarily aimed at professional investors, though a considerable amount of our research is freely available to all readers who have an interest in the financial markets and economics. Answering the question of whether or not you are a professional investor ensures you only have access to the research that is appropriate for you .Research on the website is aimed only at investors domiciled in the European Economic Area (EEA)'
    },
    {
        "question": "Why do you have to confirm you are a ‘professional investor’ to access parts of Danske Bank’s research website?",
        "answer": 'In accordance with Denmark’s Executive Order on Investor Protection in connection with Securities Trading, customers are divided into retail investors and professional investors. A considerable number of the research reports on Danske Bank’s research website require specialised knowledge and expertise to read and are therefore solely aimed at professional investors. We therefore require our users to have stated and acknowledged that they are a professional investor before this research is made accessible on the website.'
    },
    {
        "question": "What are the criteria for being a professional investor’?",
        "answer": `The definition of a professional investor derives from MiFID II (Market in Financial Instruments Directive 2014/65)<br/>
        A professional investor is one who possesses the necessary experience, knowledge and expertise to make their own investment decisions and to assess correctly the associated risks )<br/>
        Professional investors must be:<br/> 
        1.Entities that require authorisation or are subject to regulation when doing business on the financial markets. The list below should be understood as encompassing: all entities that have received authorisation and engage in activities characteristic of the named entities; entities that have received authorisation from a member state in accordance with a directive; entities that have received authorisation from or are subject to regulation in a member state without a legal basis in a directive; and entities that have received authorisation from or are subject to regulation in a third country <br/>
             a) Credit institutions <br/>
             b) Investment companies <br/>
             c) Other financial institutions that have received authorisation or are subject to regulation <br/>
             d) Insurance companies  <br/>
             e) Collective investment schemes and companies that administer such schemes   <br/>
             f) Pension funds and their administration companies  <br/>
             g) Commodity and commodity derivative traders   <br/>
             h) Local firms <br/>
             i) Other institutional investors <br/>
        2.	Large companies that meet two of the following requirements regarding size at company level: <br/>
            -----balance sheet total: at least EUR20,000,000 <br/>
            -----net turnover: at least EUR40,000,000 <br/>
            -----own funds: at least EUR2,000,000<br/>
        3.	National and regional governments, including public organs that manage government debt at national or regional level, central banks, international and supranational institutions, such as the World Bank, IMF, ECB, EIB and similar international organisations <br/>
        4.	Other institutional investors who mainly invest in financial instruments, including units that deal with securitisation of assets or other financial transactions<br/>
        If you are a customer of Danske Bank A/S, a further prerequisite is that you have not requested to be treated as a retail investor.`
    },
    {
        "question": "What happens if I am not a professional investor and therefore cannot confirm this part of the disclaimer on the website?",
        "answer": `If you do not accept the ‘Professional Investor’ disclaimer on the website, you will be able to see the selection of Danske Bank research that is aimed at a more general readership`
    },
    {
        "question": "How do I select the language of the website?",
        "answer": `At the top of the website under ‘Edition’ you can choose between ’All editions’, which displays all research in all languages or, respectively, English, Danish, Swedish, Norwegian or Finnish.`
    },
    {
        "question": "Can I get research papers that appear on the website sent by email?",
        "answer": `Yes, you can subscribe to a number of our publications and receive a link to the research via email when it is posted on the research website. <br/>
        In the menu ‘Subscription Service’ you can subscribe to a number of publications by filling in the web form. Professional investors who are customers of Danske Bank can also subscribe to research in the grey fields. This is done by contacting your usual advisor at Danske Bank`
    },
    {
        "question": "Can I get an email subscription to the research in the grey fields in the Subscription Service menu?",
        "answer": 'Yes, professional investors who are customers of Danske Bank can subscribe to research in the grey fields. This is done by contacting your usual advisor at Danske Bank'
    },
    {
        "question": "Can I receive the credit research that appears on the website by email?",
        "answer": 'Yes, professional investors who are customers of Danske Bank can subscribe to DCM Research at sector level or, respectively, Investment Grade or High Yield level. This is done by contacting your usual advisor at Danske Bank'
    },
    {
        "question": "Where do I log in to the website?",
        "answer": `Danske Bank’s research website does not require a login. To gain access to the full contents of Danske Bank’s research website you have to confirm you are a professional investor the first time you visit the site – see definition above. The confirmation is saved in the user’s cookies until these are deleted, after which you will have to confirm again <br/>
        However, please note that the equity research database does require a login. A login to the equity database is given only to institutional investors who are customers of Danske Bank and have signed an agreement to make a direct payment for access.`
    },
    {
        "question": "How do I access equity research?",
        "answer": `Danske Bank’s equity research database is only available to institutional customers of Danske Bank who have signed an agreement to make a direct payment for access. As a consequence of MiFID II, customers must pay for access to equity research from 1 January 2018. <br/>
        Danske Bank’s equity research is offered to non-institutional customers through Danske Bank eBanking in the module ‘Udvidet investering’. Udvidet investering is an option in eBanking for which a charge is made.`
    }
];

export const faqListDK = [
    {
        "question":"Hvad er Danske Banks Research-website?",
        "answer":"Danske Banks Research website indeholder analyser fra alle Danske Banks analyseafdelinger: Makroøkonomi, Renter, Valuta, Råvarer, DCM og Aktier.",
    },
    {
        "question": "Hvem henvender Danske Banks Research website sig til?",
        "answer": `Danske Banks Research website henvender sig primært til professionelle investorer, men en væsentlig del af vores analyser er frit tilgængelige for alle slags læsere med en interesse for de finansielle markeder og økonomi. Ved at besvare spørgsmålet om, hvorvidt du er en professionel investor sikres det, at du kun har adgang til de analyser, der er egnede.Analyser på Danske Banks Research website henvender sig kun til investorer domicileret i EØS.`
    },
    {
        "question": "Hvorfor skal man bekræfte at være en ”Professionel Investor” for at åbne dele af Danske Bank Research websitet?",
        "answer": 'I overensstemmelse med bekendtgørelse om investorbeskyttelse ved værdipapirhandel inddeles kunder i kategorierne detailinvestorer eller professionelle investorer. En væsentlig del af analyserne på Danske Banks Research website kræver særlig viden og ekspertise at læse og er derfor udelukkende rettet mod professionelle investorer. Vi beder derfor vores brugere om at have tilkendegivet og accepteret, at vedkommende er en Professionel Investor, før analyserne bliver tilgængelige på websitet.'
    },
    {
        "question": "Hvad er kriteriet for at være en Professionel Investor?",
        "answer": `Definitionen af professionel investor følger af MiFID II (Market in Financial Instruments Directive 2014/65).<br/>
        En professionel investor er en investor, der besidder den fornødne erfaring, viden og ekspertise til at træffe sine egne investeringsbeslutninger og foretage en korrekt vurdering af risiciene i forbindelse hermed. <br/>
        For at være Professionel Investor skal man være:<br/> 
        1.	Foretagender, der skal have tilladelse eller underkastes regulering for at drive virksomhed på de finansielle markeder. Nedenstående liste skal forstås som omfattende alle foretagender, der har fået tilladelse, og som udøver de for de nævnte foretagender kendetegnende aktiviteter: foretagender, der har fået tilladelse af en medlemsstat i henhold til et direktiv, foretagender, der har fået tilladelse af eller er underkastet regulering i en medlemsstat uden hjemmel i et direktiv, samt foretagender, der har fået tilladelse af eller er underkastet regulering i et tredjeland:<br/>
             a) Kreditinstitutter <br/>
             b) Investeringsselskaber  <br/>
             c) Andre finansieringsinstitutter, der har fået tilladelse eller er underkastet regulering <br/>
             d) Forsikringsselskaber  <br/>
             e) Kollektive investeringsordninger og selskaber, der administrer sådanne ordninger<br/>
             f) Pensionskasser og disses administrationsselskaber  <br/>
             g) Råvare- og råvarederivathandlere  <br/>
             h) Lokale firmaer  <br/>
             i) Andre institutionelle investorer <br/>
        2.	Store virksomheder, der opfylder to af følgende krav til størrelse på virksomhedsplan: <br/>
            -----balancesum: 20 000 000 EUR<br/>
            -----nettoomsætning: 40 000 000 EUR <br/>
            -----kapitalgrundlag: 2 000 000 EUR <br/>
        3.	Nationale og regionale regeringer, herunder offentlige organer, der forvalter den offentlige gæld på nationalt eller regionalt plan, centralbanker, internationale og overnationale institutioner som f.eks. Verdensbanken, IMF, ECB, EIB og andre lignende internationale organisationer <br/>
        4.	4.	Andre institutionelle investorer, der primært investerer i finansielle instrumenter, herunder enheder, der beskæftiger sig med securitisation aktiver eller andre finansielle transaktioner<br/>
        Hvis man er kunde i Danske Bank A/S, er det desuden en forudsætning, at man ikke har bedt om at blive behandlet som detailinvestor.`
    },
    {
        "question": "Hvad sker der, hvis jeg ikke er professionel investor og derfor ikke kan bekræfte denne del af disclaimeren på websitet?",
        "answer": `Hvis ikke du accepterer Professionel Investor-disclaimeren på websitet, vil du kunne se det udvalg af Danske Banks analyser, som henvender sig til alle slags læsere.`
    },
    {
        "question": "Hvordan vælger man sprog på websitet?",
        "answer": `I toppen af websitet under ”Edition” kan man vælge mellem ’All editions’, som viser alle analyser på alle sprog, eller henholdsvis engelsk, dansk, svensk, norsk og finsk.`
    },
    {
        "question": "Kan man få de analyser, som findes på websitet, tilsendt på mail?",
        "answer": `Ja, det er muligt at abonnere på en række af vores analyser, så du modtager et link til analysen på mail, når den lægges op på Research-websitet.<br/>
        I menuen ”Subscription Service” kan du abonnere på en række analyser ved at udfylde webformularen. Professionelle Investorer, som er kunder i Danske Bank, kan endvidere abonnere på analyserne i de grå felter. Dette sker ved henvendelse til den sædvanlige kunderådgiver i Danske Bank.`
    },
    {
        "question": "Kan man få et mailabonnement på analyserne i de grå felter i Subscription Service menuen?",
        "answer": 'Ja, Professionelle Investorer, som er kunder i Danske Bank, kan abonnere på analyserne i de grå felter. Dette sker ved henvendelse til den sædvanlige kunderådgiver i Danske Bank.'
    },
    {
        "question": "Kan man få de Kreditanalyser, som findes på websitet, på mail?",
        "answer": 'Ja, Professionelle Investorer, som er kunder i Danske Bank, kan abonnere på DCM Research på sektorniveau og henholdsvis Investment Grade- og High Yield-niveau.  Dette sker ved henvendelse til den sædvanlige rådgiver i Danske Bank.'
    },
    {
        "question": "Hvor logger man på websitet?",
        "answer": `Danske Banks Research website kræver ikke et login. For at få adgang til det fulde indhold af Danske Banks Research website skal man, første gang man besøger websitet, bekræfte at være en Professionel Investor – se definition her på siden <br/>
        Bekræftelsen vil blive gemt i brugerens cookies, indtil disse slettes, hvorefter det igen vil være nødvendigt at bekræfte.<br/>
        Bemærk dog at aktieanalysedatabasen kræver et login. Login til aktieanalysedatabasen gives udelukkende til institutionelle investorer, som er kunder i Danske Bank og har indgået en aftale om en direkte betaling for adgangen. `
    },
    {
        "question": "Hvordan får man adgang til aktieanalyser?",
        "answer": `Danske Banks aktieanalysedatabase er udelukkende tilgængelig for institutionelle kunder i Danske Bank som har indgået en aftale om en direkte betaling for adgangen. Som en konsekvens af MiFID II skal kunden fra 1. januar 2018 betale for adgang til aktieanalyser.  <br/>
        For ikke-institutionelle kunder tilbydes Danske Banks aktieanalyser gennem Danske Bank netbank i modulet ”Udvidet Investering”. Udvidet Investering er et tilvalg mod betaling i netbanken.`
    }
];


export const faqListNO = [
    {
        "question":"Hva er Danske Bank Researchs nettsted?",
        "answer":"Danske Bank Researchs nettsted inneholder analyser fra alle Danske Banks analyseavdelinger: Makroøkonomi, Fixed Income, Valuta, Råvarer, DCM og Aksjer.",
    },
    {
        "question": "Hvem henvender Danske Bank Researchs nettsted seg til?",
        "answer": `Danske Bank Researchs nettsted henvender seg primært til profesjonelle investorer, men en vesentlig del av våre analyser er fritt tilgjengelige for alle lesere med en interesse for finansmarkedene og økonomi. Ved å svare på spørsmålet om du er en profesjonell investor eller ikke, sikrer du at du bare har tilgang til de analysene som er relevante for deg <br/>
                   Analyser på Danske Bank Researchs nettsted henvender seg kun til investorer med domisil i EØS`
    },
    {
        "question": "Hvor må du bekrefte at du er en «Profesjonell Investor» for å få tilgang til deler av Danske Bank Researchs nettsted?",
        "answer": 'I samsvar med den danske «Bekendtgørelse om investorbeskyttelse ved værdipapirhandel» deles kundene inn i detaljinvestorer og profesjonelle investorer. Det krever spesialkunnskap og ekspertise å lese en vesentlig del av analysene på Danske Bank Researchs nettsted, og derfor er de utelukkende rettet mot profesjonelle investorer. Vi ber derfor våre brukere om å erklære og bekrefte at de er en Profesjonell Investor før analysene gjøres tilgjengelige på nettstedet.'
    },
    {
        "question": "Hva er kriteriene for å være en Profesjonell Investor?",
        "answer": `Definisjonen på en profesjonell investor følger av MiFID II (Europaparlaments- og rådsdirektiv 2014/65/EU av 15. mai 2014 om markeder for finansielle instrumenter).<br/>
        En profesjonell investor er en investor som har den nødvendige erfaring, kunnskap og ekspertise til å treffe egne investeringsbeslutninger og foreta en korrekt vurdering av de tilknyttede risikoene.<br/>
        Profesjonelle investorer er:<br/> 
        1.	Foretak som skal ha autorisasjon eller er underlagt lovregulering når de gjør forretninger i finansmarkedene. Listen under skal forstås som omfattende: alle foretak som har fått autorisasjon og utøver aktiviteter som er kjennetegnede for de nevnte foretak, foretak som har fått autorisasjon av et medlemsland i henhold til et direktiv, foretak som har fått autorisasjon av eller er underlagt regulering i et medlemsland uten lovhjemmel i et direktiv, samt foretak som har fått autorisasjon av eller underlagt regulering i et tredjeland: <br/>
             a) kredittinstitusjoner <br/>
             b)) investeringsselskaper  <br/>
             c) andre finansielle institusjoner som har fått autorisasjon eller er underlagt regulering  <br/>
             d) forsikringsselskaper <br/>
             e) kollektive investeringsordninger eller selskaper som administrerer slike ordninger  <br/>
             f) pensjonskasser og deres administrasjonsselskaper  <br/>
             g) råvare- og råvarederivathandlere  <br/>
             h) lokale firmaer  <br/>
             i) andre institusjonelle investorer  <br/>
        2.	Store selskaper som oppfyller to av følgende krav til størrelse på bedriftsnivå: <br/>
            -----balansesum: minst EUR 20 000 000  <br/>
            -----nettoomsetning: minst EUR 40 000 000 <br/>
            -----egenkapital: minst EUR 2 000 000<br/>
        3.	Nasjonale og regionale regjeringer, herunder offentlige organer som forvalter offentlig gjeld på nasjonalt eller regionalt plan, sentralbanker, internasjonale og overnasjonale institusjoner som f.eks. Verdensbanken, IMF, ECB, EIB og lignende internasjonale organisasjoner <br/>
        4.	4.	Andre institusjonelle investorer som primært investerer i finansielle instrumenter, herunder foretak som beskjeftiger seg med verdipapirisering av eiendeler eller andre finansielle transaksjoner<br/>
        Hvis du er kunde i Danske Bank A/S, er det videre en forutsetning at du ikke har bedt om å bli behandlet som detaljinvestor.`
    },
    {
        "question": "Hva skjer hvis jeg ikke er en profesjonell investor og derfor ikke kan bekrefte denne delen av ansvarsfraskrivelsen på nettstedet?",
        "answer": `Hvis du ikke godkjenner Profesjonell Investor-ansvarsfraskrivelsen på nettstedet, vil du kunne se det utvalget av Danske Banks analyser som er rettet mot en mer generell leserkrets.`
    },
    {
        "question": "Hvordan velger jeg språk på nettstedet?",
        "answer": `I toppen av nettsiden under «Edition» kan du velge mellom «All editions», som viser alle analyser på alle språk, eller henholdsvis engelsk, dansk, svensk, norsk eller finsk.`
    },
    {
        "question": "Kan jeg få analysene som finnes på nettstedet, tilsendt via e-post?",
        "answer": `Ja, det er mulig å abonnere på en rekke av våre analyser og få tilsendt en link til en analyse via e-post når den legges ut på Danske Bank Researchs nettsted. <br/>
        I menyen «Subscription Service» kan du abonnere på en rekke analyser ved å fylle ut nettskjemaet. Profesjonelle investorer som er kunder i Danske Bank, kan også abonnere på analysene i de grå feltene. Dette skjer ved henvendelse til din vanlige kundeansvarlige i Danske Bank.`
    },
    {
        "question": "Kan jeg få et nettabonnement på analysene i de grå feltene i Subscription Service-menyen?",
        "answer": 'Yes, professional investors who are customers of Danske Bank can subscribe to research in the grey fields. This is done by contacting your usual advisor at Danske Bank'
    },
    {
        "question": "Kan jeg få de kredittanalysene som finnes på nettstedet, tilsendt via e-post?",
        "answer": `Ja, profesjonelle investorer som er kunder i Danske Bank, kan abonnere på DCM Research på sektornivå eller henholdsvis Investment grade- eller High yield-nivå. Dette skjer ved henvendelse til din vanlige kundeansvarlige i Danske Bank.`
    },
    {
        "question": "Hvor logger jeg på nettstedet?",
        "answer": `Danske Bank Researchs nettsted krever ikke innlogging. For å få tilgang til alt innhold på Danske Bank Researchs nettsted må du, første gang du besøker nettstedet, bekrefte at du er en Profesjonell Investor – se definisjonen ovenfor. Bekreftelsen vil bli lagret i brukerens informasjonskapsler inntil disse slettes. Deretter vil det være nødvendig å bekrefte igjen. <br/>
        Merk at aksjeanalysedatabasen krever innlogging. Tilgang til aksjedatabasen gis utelukkende til institusjonelle investorer som er kunder i Danske Bank og har underskrevet en avtale om direkte betaling for tilgang.`
    },
    {
        "question": "Hvordan får jeg tilgang til aksjeanalyser?",
        "answer": `Kun institusjonelle investorer som er kunder i Danske Bank og har underskrevet en avtale om direkte betaling for tilgang, har tilgang til Danske Banks aksjeanalysedatabase. Som følge av MiFID II må kundene fra 1. januar 2018 betale for å få tilgang til aksjeanalysene. <br/>
        Ikke-institusjonelle kunder tilbys tilgang til Danske Banks aksjeanalyser gjennom Danske Banks nettbank i modulen «Utvidet investering». Utvidet investering er en tilvalgsmulighet mot betaling i nettbanken.`
    }
];


export const faqListSE = [
    {
        "question":"Vad är Danske Banks analyswebbplats?",
        "answer":"Danske Banks analyswebbplats innehåller analyser från samtliga analysavdelningar på Danske Bank: Makroekonomi, Räntemarknad, Valuta, Råvaror, Kreditmarknad (DCM) och Aktier.",
    },
    {
        "question": "Till vem riktar sig Danske Banks analyswebbplats?",
        "answer": `Analyswebbplatsen riktar sig primärt till professionella investerare, men en betydande andel av våra analyser är fritt tillgängliga för alla läsare som är intresserade av finansiella marknader och ekonomi. Genom att besvara frågan om du är en professionell investerare säkerställer du att du endast får tillgång till analyser som är avsedda för dig.<br/>
                 Analyserna på webbplatsen riktar sig endast till investerare med hemvist i Europeiska ekonomiska samarbetsområdet (EES).`
    },
    {
        "question": "Varför måste du bekräfta att du är en ”professionell investerare” för att få tillträde till delar av Danske Banks analyswebbplats?",
        "answer": 'Enligt den danska förordningen om skydd för investerarna i samband med värdepappershandel delas kunderna in i icke-professionella investerare och professionella investerare. Ett betydande antal av analyserna på Danske banks analyswebbplats kräver specialkunskaper och expertis för att förstå och är därför uteslutande riktade till professionella investerare. Vi kräver därför att våra användare uttryckligen har bekräftat att de är professionella investerare innan sådana analyser görs tillgängliga på webbplatsen.'
    },
    {
        "question": "Vilka kriterier utmärker en professionell investerare?",
        "answer": `Definitionen av en professionell investerare härrör från MiFID II (direktiv 2014/65 om marknader för finansiella instrument).<br/>
        En professionell investerare är en investerare som har nödvändig erfarenhet, kunskap och expertis för att fatta egna investeringsbeslut och förstå riskerna med dessa.<br/>
        Professionella investerare är:<br/> 
        1.	Kunder som omfattas av auktorisationskrav eller regleringar när de gör affärer på finansiella marknader. Nedanstående förteckning ska förstås så att den omfattar alla företag som är auktoriserade och ägnar sig åt verksamhet som är karaktäristisk för de uppräknade företagen, företag som auktoriserats av någon medlemsstat enligt ett direktiv, företag som auktoriserats av eller omfattas av regleringar i en medlemsstat utan rättslig grund i ett direktiv, samt företag som auktoriserats av eller omfattas av regleringar i tredje land. <br/>
             a) Kreditinstitut <br/>
             b) Investmentföretag <br/>
             c) Andra finansiella institutioner som är auktoriserade eller reglerade  <br/>
             d) Försäkringsföretag  <br/>
             e) Investeringsfonder och företag som förvaltar sådana fonder  <br/>
             f) Pensionsfonder och deras förvaltningsföretag   <br/>
             g) Handlare i råvaror och råvaruderivat  <br/>
             h) Lokala företag <br/>
             i) Övriga institutionella investerare  <br/>
        2.	Företag som på företagsnivå uppfyller minst två av följande tre storlekskrav: <br/>
            -----balansomslutning: lägst EUR 20 000 000 <br/>
            -----nettoomsättning: lägst EUR 40 000 000<br/>
            -----eget kapital: lägst EUR 2 000 000<br/>
        3.	Statliga och regionala myndigheter, bland annat offentliga organ som hanterar statlig upplåning på nationell eller regional nivå, centralbanker, internationella och överstatliga institutioner, t.ex. Världsbanken, IMF, ECB, EIB och likande internationella organisationer. <br/>
        4.	Övriga institutionella investerare som huvudsakligen investerar i finansiella instrument, ägnar sig åt värdepapperisering av tillgångar eller andra finansiella transaktioner.<br/>
        Om du är kund i Danske Bank A/S är det en ytterligare förutsättning att du inte själv har begärt att bli kategoriserad som icke-professionell investerare.`
    },
    {
        "question": "Vad händer om jag inte är en professionell investerare och därför inte kan underteckna denna del av ansvarsfriskrivningen på webbplatsen?",
        "answer": `Om du inte bekräftar ansvarsfriskrivningen för ”professionella investerare” på webbplatsen kommer du att kunna se ett urval av Danske Banks analyser som riktar sig till en bredare publik.`
    },
    {
        "question": "Hur väljer jag språk på webbplatsen?",
        "answer": `Högst upp på webbsidan kan du under ”Utgåvor” välja mellan ”Samtliga språk”, som visar alla analyser på alla språk, eller Engelska, Danska, Svenska, Norska respektive Finska.`
    },
    {
        "question": "Kan jag få analyser från webbplatsen skickade med epost?",
        "answer": `Ja, du kan prenumerera på många av våra publikationer och få länkar till analyser med epost när de läggs upp på webbplatsen. <br/>
        I menyn ”Prenumerationstjänster” kan du prenumerera på många publikationer genom att fylla i webbformuläret. Professionella investerare som är kunder i Danske Bank kan även prenumerera på analyserna i de gråa fälten. Detta gör du genom att kontakta din vanliga rådgivare på Danske Bank.`
    },
    {
        "question": "Kan jag få en epostprenumeration på analyserna i de grå fälten i menyn Prenumerationstjänster?",
        "answer": 'Ja, Professionella investerare som är kunder hos Danske Bank kan även prenumerera på analyserna i de gråa fälten. Detta gör du genom att kontakta din vanliga rådgivare på Danske Bank.'
    },
    {
        "question": "Kan jag få kreditmarknadsanalyser från webbplatsen skickade med epost?",
        "answer": 'Ja, Professionella investerare som är kunder hos Danske Bank kan även prenumerera på DCM-analyser på branschnivå eller på nivåerna Investment Grade respektive High Yield. Detta gör du genom att kontakta din vanliga rådgivare på Danske Bank.'
    },
    {
        "question": "Var loggar jag in på webbplatsen?",
        "answer": `Danske Banks analyswebbplats kräver inte någon inloggning. För att få tillgång till hela innehållet på Danske Banks analyswebbplats måste du bekräfta att du är en professionell investerare när du besöker webbplatsen första gången – se definitionen ovan. Bekräftelsen sparas i besökarens cookies tills de raderas, varefter du måste bekräfta din status på nytt. <br/>
        Observera att databasen med aktieanalyser kräver inloggning. Inloggningen till aktiedatabasen är endast öppen för institutionella investerare som är kunder i Danske Bank och har undertecknat ett avtal om direkt betalning för att få tillgång till denna.`
    },
    {
        "question": "Hur får jag tillgång till aktieanalyser?",
        "answer": `Databasen med aktieanalyser är endast tillgänglig för institutionella kunder i Danske Bank som har undertecknat ett avtal om direkt betalning för att få tillgång till denna. Till följd av MiFID II måste kunder betala för att få tillgång till aktieanalyser från 1 januari 2018. <br/>
        Danske Banks aktieanalyser erbjuds till icke-institutionella investerare genom Danske Banks eBanking i modulen ”Utvidet investering”. Udvidet investering är ett avgiftsbelagt tillägg till eBanking.`
    }
];


export const faqListFI = [
    {
        "question":"Mitä Danske Bankin tutkimussivusto sisältää?",
        "answer":"Danske Bankin tutkimussivusto sisältää Danske Bankin eri tutkimusosastojen tuottamaa tutkimustietoa: makrotaloustiede, korkotuotteet, valuutat, hyödykkeet, pääomamarkkinat ja osakkeet.",
    },
    {
        "question": "Kenelle Danske Bankin tutkimussivusto on tarkoitettu?",
        "answer": `Tutkimustietoa sisältävä verkkosivusto on tarkoitettu ensisijaisesti ammattimaisille sijoittajille, mutta huomattava osa tarjoamastamme tutkimustiedosta on vapaasti kaikkien rahoitusmarkkinoista ja taloudesta kiinnostuneiden lukijoiden saatavilla. Vastaamalla kysymykseen siitä, oletko ammattimainen sijoittaja vai et, autat varmistamaan, että näet vain sinun kannaltasi tarkoituksenmukaiset tutkimustiedot. <br/>
                  Verkkosivustolla oleva tutkimustieto on tarkoitettu vain Euroopan talousalueella kotipaikkaansa pitäville sijoittajille.`
    },
    {
        "question": "Miksi minun on kerrottava, olenko ammattimainen sijoittaja ennen kuin pääsen tietyille Danske Bankin tutkimustietoa sisältäville verkkosivuille? ",
        "answer": 'Tanskan arvopaperikauppaa koskevan sijoittajansuoja-asetuksen mukaan asiakkaat luokitellaan ei-ammattimaisiin ja ammattimaisiin sijoittajiin. Huomattava osa Danske Bankin tutkimussivuston sisältämistä tutkimusraporteista edellyttää lukijaltaan erityistä tietämystä ja asiantuntemusta, ja ne on tästä syystä tarkoitettu vain ammattimaisille sijoittajille. Tämän vuoksi edellytämme, että käyttäjä ilmaisee ja vahvistaa olevansa ammattimainen sijoittaja ennen kuin hänelle myönnetään pääsy sivustolle.'
    },
    {
        "question": "Kuka on ammattimainen sijoittaja?",
        "answer": `Ammattimaisen sijoittajan määritelmä perustuu rahoitusvälineiden markkinoista annettuun direktiiviin (MiFID II, Euroopan parlamentin ja neuvoston direktiivi 2014/65).<br/>
        Ammattimainen sijoittaja on sijoittaja, jolla on tarvittava kokemus, tietämys ja asiantuntemus, joiden perusteella hän voi tehdä sijoituspäätöksiä ja arvioida oikein niihin liittyviä riskejä.<br/>
        Ammattimaisiksi sijoittajiksi on katsottava seuraavat::<br/> 
        1. Yhteisöt, joilla on oltava toimilupa tai joita on säänneltävä, jotta ne voivat toimia rahoitusmarkkinoilla. Jäljempänä olevan luettelon on katsottava olevan kaikenkattava: kaikki yhteisöt, joilla on toimilupa harjoittaa mainituille yhteisöille ominaista toimintaa, yhteisöt, joille jäsenvaltio on myöntänyt toimiluvan jonkin direktiivin nojalla, yhteisöt, joille jäsenvaltio on myöntänyt toimiluvan tai joita se sääntelee muuten kuin jonkin direktiivin nojalla, ja yhteisöt, joille toimiluvan on myöntänyt tai joita sääntelee jokin kolmas maa:<br/>
             a) luottolaitokset <br/>
             b) sijoituspalveluyritykset  <br/>
             c) muut toimiluvan saaneet tai säännellyt rahoituslaitokset <br/>
             d) vakuutusyritykset  <br/>
             e) yhteissijoitusjärjestelyt ja niiden rahastoyhtiöt <br/>
             f) eläkerahastot ja niiden rahastoyhtiöt <br/>
             g) hyödykkeiden ja hyödykejohdannaisten välittäjät  <br/>
             h) paikalliset yritykset  <br/>
             i) muut yhteisösijoittajat  <br/>
        2.	2.	Suuryritykset, jotka täyttävät kaksi seuraavista kokovaatimuksista yhtiötasolla: <br/>
            -----taseen loppusumma: vähintään 20 000 000 euroa  <br/>
            -----nettoliikevaihto: vähintään 40 000 000 euroa <br/>
            -----omat varat: vähintään 2 000 000 euroa<br/>
        3.	Kansalliset ja alueelliset hallitukset, mukaan lukien valtionvelkaa kansallisella tai alueellisella tasolla hoitavat julkiset elimet, keskuspankit, kansainväliset ja monikansalliset yhteisöt, kuten Maailmanpankki, Kansainvälinen valuuttarahasto, Euroopan keskuspankki, Euroopan investointipankki ja muut vastaavat kansainväliset organisaatiot  <br/>
        4.	Muut yhteisösijoittajat, joiden pääasiallisena tarkoituksena on rahoitusvälineisiin sijoittaminen, mukaan lukien varojen arvopaperistamiseen tai muihin rahoitustoimiin erikoistuneet yhteisöt.<br/>
        Jos olet Danske Bank A/S:n asiakas, edellytyksenä on lisäksi se, että et ole pyytänyt tulla kohdelluksi ei-ammattimaisena sijoittajana.`
    },
    {
        "question": "Mitä tapahtuu, jos en ole ammattimainen sijoittaja enkä sen vuoksi voi vahvistaa verkkosivuston tätä asiaa koskevaa vastuuvapauslauseketta?",
        "answer": `Jos et hyväksy verkkosivuston Ammattimainen sijoittaja -vastuuvapauslauseketta, sinulla on pääsy yleisemmälle lukijakunnalle tarkoitettuun Danske Bankin tutkimustietoon.`
    },
    {
        "question": "Kuinka voin valita verkkosivuston kielen?",
        "answer": `Verkkosivuston yläreunassa on kohta ”Kieli”. Valitse ”Kaikki kielet” nähdäksesi kaiken tutkimustiedon kaikilla kielillä, tai valitse kieleksi englanti, tanska, ruotsi, norja tai suomi.`
    },
    {
        "question": "Voinko saada verkkosivuilta löytyvät tutkimukset sähköpostiini?",
        "answer": `Kyllä, voit tilata monia julkaisujamme, jolloin sinulle lähetetään sähköpostitse linkki tutkimukseen, kun se julkaistaan verkkosivustolla.<br/>
        Tilauspalvelu-valikon kautta pääset täyttämään lomakkeen, jolla voit tilata julkaisuja. Danske Bankin asiakkaina olevat ammattimaiset sijoittajat voivat tilata myös harmaalla merkittyjä tutkimuksia. Tee tilaus Danske Bankin yhteyshenkilösi kautta.`
    },
    {
        "question": "Voinko tilata sähköpostiini Tilauspalvelu-valikossa harmaalla merkittyjä tutkimuksia?",
        "answer": 'Kyllä, Danske Bankin asiakkaina olevat ammattimaiset sijoittajat voivat tilata myös harmaalla merkittyjä tutkimuksia. Tee tilaus Danske Bankin yhteyshenkilösi kautta.'
    },
    {
        "question": "Voinko saada verkkosivuilta löytyvät luottomarkkinoihin liittyvät tutkimukset sähköpostiini?",
        "answer": 'Kyllä, Danske Bankin asiakkaina olevat ammattimaiset sijoittajat voivat tilata pääomamarkkinatutkimuksia joko toimialoittain tai investointiluokka- tai high yield -tasolla. Tee tilaus Danske Bankin yhteyshenkilösi kautta.'
    },
    {
        "question": "Mistä pääsen kirjautumaan verkkosivustolle?",
        "answer": `Danske Bankin tutkimussivustolle ei tarvitse kirjautua. Pääset näkemään Danske Bankin tutkimussivuston koko sisällön, kun ensimmäistä kertaa sivustolla vieraillessasi vahvistat olevasi ammattimainen sijoittaja (katso yllä oleva määrittely). Tieto vahvistuksesta tallennetaan käyttäjän evästeisiin, jolloin vahvistus on annettava uudestaan, mikäli evästeet poistetaan. <br/>
        Osaketutkimustietokantaan pääsy edellyttää kuitenkin kirjautumista. Osaketutkimustietokantaan myönnetään pääsy vain Danske Bankin yhteisösijoittaja-asiakkaille, jotka ovat allekirjoittaneet palvelun käyttöä koskevan maksullisen sopimuksen.`
    },
    {
        "question": "Miten pääsen lukemaan osaketutkimuksia?",
        "answer": `Danske Bankin osaketutkimustietokantaan myönnetään pääsy vain Danske Bankin yhteisöasiakkaille, jotka ovat allekirjoittaneet palvelun käyttöä koskevan maksullisen sopimuksen. Rahoitusvälineiden markkinat -direktiivin (MiFID II) seurauksena asiakkaiden on 1.1.2018 alkaen maksettava pääsystä osaketutkimustietoon.<br/>
        Danske Bankin osaketutkimustietoa tarjotaan muille kuin yhteisöasiakkaille Danske Bankin verkkopankin osiossa ”Udvidet investering”. Udvidet investering on verkkopankin maksullinen palvelu.`
    }
];

export const faqListUK = [
    {
        "question":"What is Danske Bank’s research website?",
        "answer":"Danske Bank’s research website contains research from all Danske Bank’s research departments: Macroeconomics, Fixed Income, Foreign Exchange, Commodities, Debt Capital Markets (DCM) and Equities.",
    },
    {
        "question": "Who is Danske Bank’s research website aimed at?",
        "answer": 'The research website is primarily aimed at professional investors, though a considerable amount of our research is freely available to all readers who have an interest in the financial markets and economics. Answering the question of whether or not you are a professional investor ensures you only have access to the research that is appropriate for you .Research on the website is aimed only at investors domiciled in the European Economic Area (EEA)'
    },
    {
        "question": "Why do you have to confirm you are a ‘professional investor’ to access parts of Danske Bank’s research website?",
        "answer": 'In accordance with Denmark’s Executive Order on Investor Protection in connection with Securities Trading, customers are divided into retail investors and professional investors. A considerable number of the research reports on Danske Bank’s research website require specialised knowledge and expertise to read and are therefore solely aimed at professional investors. We therefore require our users to have stated and acknowledged that they are a professional investor before this research is made accessible on the website.'
    },
    {
        "question": "What are the criteria for being a professional investor’?",
        "answer": `The definition of a professional investor derives from MiFID II (Market in Financial Instruments Directive 2014/65)<br/>
        A professional investor is one who possesses the necessary experience, knowledge and expertise to make their own investment decisions and to assess correctly the associated risks )<br/>
        Professional investors must be:<br/> 
        1.Entities that require authorisation or are subject to regulation when doing business on the financial markets. The list below should be understood as encompassing: all entities that have received authorisation and engage in activities characteristic of the named entities; entities that have received authorisation from a member state in accordance with a directive; entities that have received authorisation from or are subject to regulation in a member state without a legal basis in a directive; and entities that have received authorisation from or are subject to regulation in a third country <br/>
             a) Credit institutions <br/>
             b) Investment companies <br/>
             c) Other financial institutions that have received authorisation or are subject to regulation <br/>
             d) Insurance companies  <br/>
             e) Collective investment schemes and companies that administer such schemes   <br/>
             f) Pension funds and their administration companies  <br/>
             g) Commodity and commodity derivative traders   <br/>
             h) Local firms <br/>
             i) Other institutional investors <br/>
        2.	Large companies that meet two of the following requirements regarding size at company level: <br/>
            -----balance sheet total: at least EUR20,000,000 <br/>
            -----net turnover: at least EUR40,000,000 <br/>
            -----own funds: at least EUR2,000,000<br/>
        3.	National and regional governments, including public organs that manage government debt at national or regional level, central banks, international and supranational institutions, such as the World Bank, IMF, ECB, EIB and similar international organisations <br/>
        4.	Other institutional investors who mainly invest in financial instruments, including units that deal with securitisation of assets or other financial transactions<br/>
        If you are a customer of Danske Bank A/S, a further prerequisite is that you have not requested to be treated as a retail investor.`
    },
    {
        "question": "What happens if I am not a professional investor and therefore cannot confirm this part of the disclaimer on the website?",
        "answer": `If you do not accept the ‘Professional Investor’ disclaimer on the website, you will be able to see the selection of Danske Bank research that is aimed at a more general readership`
    },
    {
        "question": "How do I select the language of the website?",
        "answer": `At the top of the website under ‘Edition’ you can choose between ’All editions’, which displays all research in all languages or, respectively, English, Danish, Swedish, Norwegian or Finnish.`
    },
    {
        "question": "Can I get research papers that appear on the website sent by email?",
        "answer": `Yes, you can subscribe to a number of our publications and receive a link to the research via email when it is posted on the research website. <br/>
        In the menu ‘Subscription Service’ you can subscribe to a number of publications by filling in the web form. Professional investors who are customers of Danske Bank can also subscribe to research in the grey fields. This is done by contacting your usual advisor at Danske Bank`
    },
    {
        "question": "Can I get an email subscription to the research in the grey fields in the Subscription Service menu?",
        "answer": 'Yes, professional investors who are customers of Danske Bank can subscribe to research in the grey fields. This is done by contacting your usual advisor at Danske Bank'
    },
    {
        "question": "Can I receive the credit research that appears on the website by email?",
        "answer": 'Yes, professional investors who are customers of Danske Bank can subscribe to DCM Research at sector level or, respectively, Investment Grade or High Yield level. This is done by contacting your usual advisor at Danske Bank'
    },
    {
        "question": "Where do I log in to the website?",
        "answer": `Danske Bank’s research ebsite does not require a login. To gain access to the full contents of Danske Bank’s research website you have to confirm you are a professional investor the first time you visit the site – see definition above. The confirmation is saved in the user’s cookies until these are deleted, after which you will have to confirm again <br/>
        However, please note that the equity research database does require a login. A login to the equity database is only given to institutional investors who are customers of Danske Bank and have signed an agreement to make a direct payment for access.`
    },
    {
        "question": "How do I access equity research?",
        "answer": `Danske Bank’s equity research database is only available to institutional customers of Danske Bank who have signed an agreement to make a direct payment for access. As a consequence of MiFID II, customers must pay for access to equity research from 1 January 2018. <br/>
        Danske Bank’s equity research is offered to non-institutional customers through Danske Bank eBanking in the module ‘Udvidet investering’. Udvidet investering is an option in eBanking for which a charge is made..`
    }
];