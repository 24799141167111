import React, { Component }  from 'react';
import * as styles from './burgermenu.style';

export default class BurgerItem extends Component {
 
constructor(props) {
    super(props);
    this.state = {              
    };
}

 render () {
    var submenu =  this.props.item.submenu;      
    return (
       <div>
          {submenu.map(item => 
              <div>{item.title}</div>                    
          )}   
      </div>         
    );
 }
}