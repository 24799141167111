import * as styles from '../../search/search.style';
import React, { Component } from 'react';
import { css } from 'glamor';
import searchIcon from '../../../assets/icons/search_white.svg';
import { withRouter } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import * as navigation from '../../../configs/navigation';
import './styles/autosuggest.css';

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value) {
  const escapedValue = escapeRegexCharacters(value.trim());

  const regex = new RegExp('^' + escapedValue, 'i');

  let suggestions = navigation.companyList.filter(comp => regex.test(comp.text));
  return suggestions;
}

function getSuggestionValue(suggestion) {
  return suggestion.text;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.text}</span>
  );
}

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: [],
      noSuggestions: false
    };
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const suggestions = getSuggestions(value);
    const isInputBlank = value.trim() === '';
    const noSuggestions = !isInputBlank && suggestions.length === 0;

    if (noSuggestions) this.setState({ value: value.substring(0, value.length - 1) })

    this.setState({
      suggestions,
      noSuggestions
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.props.history.push(`/articlelist/${suggestionValue}/commr`);
  }
  componentDidMount() {
    var element = document.querySelector('[role="combobox"]');
    element.setAttribute('aria-label', 'search')
  }

  render() {
    const { value, suggestions, noSuggestions } = this.state;
    const inputProps = {
      placeholder: "Search",
      value,
      onChange: this.onChange,
      id: "Search",
      label: "Search",
      name: "Search",
      // ariaLabel: "Search",
      'aria-label': "Search",
      'aria-labelledby': 'Search'
    };

    return (
      <div>
        <section {...css(styles.searchContainer)}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12 col-12" role="region" aria-label="Danske Bank Commissioned Research">
                {/* <h2 {...css(styles.searchH2)}>Danske Bank Commissioned Research</h2> */}
                <h1 {...css(styles.searchH2)}>Danske Bank Commissioned Research</h1>
                <div {...css(styles.searchInputContiner)}>
                  <div>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionSelected={this.onSuggestionSelected}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      shouldRenderSuggestions={() => true}
                      inputProps={inputProps} />
                  </div>
                </div>
              </div>
              {
                noSuggestions &&
                <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                  <div className="no-suggestions">
                    No suggestions
                  </div>
                </div>
              }
            </div>
          </div>
        </section>
      </div>

    );
  }
}

export default withRouter(SearchBar)