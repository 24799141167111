export const date = {
    fontSize: '.875em',
    lineHeight: '1.275em',
    margin: '0 0 5px',
    color: '#003755'
}
export const imageTextContainer = {
    borderTop: '1px solid #ccc',
    padding: '25px 0',
    width: '90%',
    cursor: 'pointer',
    marginTop: '3px',
    position: 'relative',
    transition: 'box-shadow .2s cubic-bezier(.215,.61,.355,1),transform .2s cubic-bezier(.215,.61,.355,1)',
    '&:hover': {
        boxShadow: '0 10px 40px rgba(0,0,0,.1)',
        transform: 'translateY(-4px)'
    },
    '&:after': {
        height: '4px',
        position: 'absolute',
        display: 'block',
        content: ' ',
        backgroundColor: '#fff',
        width: '100%',
        left: '0',
        bottom: '-4px',
        transition: 'height .2s cubic-bezier(.215,.61,.355,1)'
    }
};

export const imageContainer = {

};
export const thumbnail = {
    maxWidth: '220px',
    height: '110px',
    display: 'block',
    margin: '0 auto',
    width: '100%',
    '@media(min-width:0px) and (max-width:767px)': {
        maxWidth: '100%',
        marginBottom: '10px',
        height: '140px'
    }
};
export const H3 = {
    color: '#003755',
    fontSize: '1.5em',
    fontWeight: '600'
}
export const textContent = {
    padding: '0 0 0 1.5%'
}
export const textParagraph = {
    color: '#003755',
    fontSize: '1em',
    // lineHeight: '1.125em',
    marginTop: '6px',
    maxHeight: '45px',
    overflow: 'hidden'
}
export const rightArrow = {
    marginLeft: '40%',
    marginTop: '22%',
    '@media(min-width:0px) and (max-width:767px)': {
        float: 'right',
        marginTop: '0',
        marginLeft: '0'
    }
}
export const readMore = {
    display: 'none',
    '@media(min-width:0px) and (max-width:767px)': {
        display: 'block',
        float: 'right',
        color: '#003755',
        fontSize: '1em'
    }
}