import axios from 'axios';
import React from 'react';
import Config from '../app.config';

class CommonService{
    constructor(){
        this.videoId = "";
        this.videoUrl = "";
        this.videoTitle = "";
        this.videoUrlWithPlaylist = "";
    }

    loadYoutubePropsfromGoogleApis(){
        return axios.get(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PLYn5NInkiHycEc0GbqkxvK0YCedTCiqEu&key=AIzaSyDkTjsbBp8LTmtnHZji-awxniUxdra35mo`)
        .then(res => {              
                  const item = res.data.items[0];

                  this.videoId =   'https://www.youtube.com/embed/' +  item.snippet.resourceId.videoId + '?modestbranding=1&autohide=1&showinfo=0&controls=1&rel=0' ;
                  this.videoUrl=   'https://www.youtube.com/watch?v=' +  item.snippet.resourceId.videoId;
                  this.videoUrlWithPlaylist = this.videoUrl + '&list=' + item.snippet.playlistId;
                  this.videoTitle =  item.snippet.title;

                  return {
                    videoId:this.videoId, 
                    videoUrl: this.videoUrl, 
                    videoTitle:this.videoTitle,
                    videoUrlWithPlaylist : this.videoUrlWithPlaylist
                  }
        });
    }

    getYoutubeUri()
    {
        return this.videoUrl;
    }

    getYoutubeProps(){
        return { 
            videoId:this.videoId, 
            videoUrl: this.videoUrl, 
            videoTitle:this.videoTitle
        };
    }
}

export default new CommonService();