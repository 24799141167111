import React, { Component }  from 'react';
import * as styles from './tabmenu.style';
import * as navigation from '../../configs/navigation';
import { Link } from 'react-router-dom';

export default class TabItems extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    render() {
        var items = navigation.navInfo;
        return (
            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                {items.map(item =>
                    <li>
                        <Link to={`/articlelist/${item.url}`}> {item.title}</Link>
                    </li>
                )}
            </div>
        );
    }
};