export const podcastsContainer = {
    backgroundColor: '#003755',
    'margin-bottom': '10px'
}

export const H2 = {
    textAlign: 'center',    
    color: '#ffffff',
    font: '500 2.25em Danske Human',
    'padding-top': '50px'
}

export const section = { 
    'padding-bottom': '50px',
    'padding-left': '50px',
    'padding-right': '50px'
}

export const close = {
    position: 'relative',
    display: 'inline-block',
    width: '50px',
    height: '50px',
    overflow: 'hidden',
    color: '#ffffff',
    '&:hover': {
        color: '#ffffff'
    },
    '&:focus': {
        boder: 'none',
        'outline-style': 'none'
    }
}