export const subscriptionContent = {
    minHeight: 'calc(100vh - 450px)',    
    margin:'0 auto'
}
export const H2 = {
    textAlign: 'center',    
    color: '#003F63',
    font: '500 2.75em Danske Human',
    margin: '105px 0 15px 0'

};
export const subTitle = {
    color: '#003755',
    fontSize: '1em',
    textAlign: 'center'
}
export const Button = {
    border: '1px solid #37B0DE',
    borderRadius: '40px',
    background: 'linear-gradient(0deg, #0097CF 0%, #00AAE8 100%)',
    padding: '15px 50px',
    textAlign: 'center',
    color: '#FFF',
    textDecoration: 'none',
    display : 'table',
    margin:'50px auto 0',
    '&:hover':{
        color: '#FFF',
        textDecoration: 'none',
    }
};
export const collapsibleContainer = {
    marginTop: '50px',
    cursor: 'pointer'
}
export const subCategory = {
    color: '#003755',
    fontSize: '1em'
}
export const subCategoryCountry = {
    color: '#7F9BAA',
    fontSize: '.875em'
}
export const headingRow = {
    padding: '10px 0 5px 0',
    borderBottom: '1px solid rgba(0,55,85,0.2)'
}
export const categoryDetails = {
    color: '#003755',
    fontSize: '1em',
    padding: '30px 0'
}
export const noPadding = {
    padding: '0 !important'
}