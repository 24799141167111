export const headerContainer = {
    width: '100%',
    backgroundColor: '#FAFBFB',        
    '@media(max-width: 767px)': {
        display : 'none'
    }
}
export const tabletheaderContainer = {
    width: '100%',
    backgroundColor: '#FAFBFB',        
    '@media(min-width: 768px)': {
        display : 'none'
    }
}
export const headerContent = {
    width: '100%',
    backgroundColor: '#FFF',
    margin: '0 auto',
    padding: '25px 7% 0 7% !important'
}
export const logo = {
    '@media(min-width: 0px) and (max-width:767px)': {
        width: '125px',
        paddingTop: '5px',
        marginBottom: '15px'
    },
    '@media(min-width: 768px) and (max-width:1024px)': {
       width: '150px'
    }
}
export const countryList = {    
    listStyleType: 'none',  
    marginTop: '5px',
    position: 'relative',
    float: 'right',    
    width: '100%',
    marginBottom: '5px'
}
export const countryListItems = {
    float: 'left',
    color: '#003755',    
    fontWeight: 'bold',    
    margin:'0 0 0 8%',
    cursor: 'pointer',    
    paddingBottom:'30px',
    float: 'right',
    '@media(min-width: 768px) and (max-width:1024px)': {
        fontSize: '.8em',
        padding: '0 0 20px 2%',
    }
}
export const logoPaddingBottom = {    
    paddingBottom:'60px'
}
export const flagIcons = {
    height: '20px',
    width: '20px',
    margin: '0 10px 3px 0',
    cursor: 'pointer',
    position: 'absolute',
    left: '0'
}
export const flagIconsSelected = {
    height: '18px',
    width: '18px',
    margin: '0 6px 3px 0',
}
export const contactUs = {    
    color: '#003755',
    fontSize: '16px',
    marginTop: '5px',
    fontWeight: 'bold',
    textDecoration: 'none !important',   
    display: 'block',
    '&:hover': {
        color: '#003755'
    },
}
export const localeTitle = {
    color: '#003755',
    fontSize: '1em',
    display: 'block',
    position: 'relative',
    padding: '0 0 0 35px'
}
export const localeSubTitle = {
    color: '#7F9BAA',
    fontSize: '.875em',
    display: 'block',
    padding: '0 0 0 35px'    
}
export const localeDropdown = {
    backgroundColor: '#FFF',
    boxShadow:'0 0 10px rgba(81, 81, 81, 0.5)',
    position: 'absolute',
    top: '40px',
    right:'-20.5%',
    zIndex: '999',
    padding: '5px 0',
    borderRadius: '2px',
    '@media(min-width: 768px) and (max-width:1024px)': {
        right:'-11.5%'
    }
}
export const locale = {
    padding: '10px 20px 4px'
}
export const burgermenu = {    
    height : '100%',    
    padding: '0 !important'
}
export const researchImage = {
    width:'68%',
    marginTop: '50px'
}
export const signupButton = {
    border: '1px solid #37B0DE',
    borderRadius: '40px',
    background: 'linear-gradient(0deg, #0097CF 0%, #00AAE8 100%)',
    padding: '10px 30px',
    textAlign: 'center',
    color: '#FFF',
    textDecoration: 'none',
    display : 'inline-block',
    
    '&:hover':{
        color: '#FFF',
        textDecoration: 'none',
    }
}
export const burger = {
    width: '20px',
    height: '2px',
    backgroundColor: '#003755',
    margin: '5px 3px 0 6px'
}
export const burgerbutton = {
    padding:'0',
    float:'left'
}
export const closeIcon = {
    width: '100%',
    height: '100%'
};
export const directionIcon = {
    marginTop: '-2px',
    verticalAlign: 'middle'
}
export const menuText = {
    fontSize: '.875em',
    color: '#003755'
}
export const headerBorder = {
    borderBottom: '2px solid rgb(239, 240, 240)'
}
export const overlay = {   
    zIndex: '998',
    height: '1660vh',
    opacity: '0.7',
    width: '100%',
    background: 'rgba(18, 64, 97, 0.8)',
    position: 'absolute',
    top: '0'
}
export const overlayContainer =  {
    position: 'relative'
}
