import React, { Component } from 'react';
import { css } from 'glamor';
import NavBarLink from './navbarlink';
import * as styles from './navbaritem.style';
import ReactDOM from 'react-dom';

export default class NavBarItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            highlightLink: false
        };

        this.showPopupMenu = this.showPopupMenu.bind(this);
        this.self = this;
    }
    
    generateLink = () => {       
          return <NavBarLink text={this.props.text} key={this.props.text} commr={this.props.commr}/>;
    };

    generateContent = () => {
        var content = [this.generateLink()];       
        return content;
    };

    handleClick = (e) => {
        if (ReactDOM.findDOMNode(this.self).contains(e.target)) {
            if (this.props.text == 'Podcasts')
            {
                this.props.togglePodcastsVisibility();
                this.showPopupMenu(false);
            }
            else
            {
                this.showPopupMenu(!this.state.showPopup);
            }
        }
        else { 
            this.showPopupMenu(false);
        }        
    };

    componentWillMount = () => {        
        window.addEventListener('click', this.handleClick, false);
    };

    componentWillUnmount = () => {        
        window.removeEventListener('click', this.handleClick, false);
    };
  
    render() {
        var content = this.generateContent();
        return (
            <li {...css(styles.navlink)} className={this.state.highlightLink && 'highlight-link'}>
                {content}
            </li>
        ); 
    };

    showPopupMenu(shouldShow){
        this.state.showPopup = shouldShow;
        this.props.toggleNav(this, this.state.showPopup)
    }
}