import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { css } from 'glamor';
import NavBarItem from './navbaritem';
import PopupMenu from './popupmenu';
import * as styles from './navbar.style';
import twitter from '../../assets/icons/Twitter.svg';
import youtube from '../../assets/icons/Youtube.svg';
import CommonService from '../../services/common.service';

const IsEeaCitizenKey = 'disclaimer_isEaaCitizen';

export default class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            youtubeUrl: '',
            toPodcasts: false,
            isEeaCitizen: true
        }
    }

    componentDidMount() {
        // CommonService.loadYoutubePropsfromGoogleApis().then(res =>{
        //     this.setState({youtubeUrl:res.videoUrlWithPlaylist})
        // });
    }

    showPodcastsSection = () => {
        this.setState({ toPodcasts: true });
    }

    populateNavigation = (item) => {
        let showState = null;

        if (!item.nonEeaAccessible && !this.state.isEeaCitizen) {
            return {
                popupMenu: null,
                navBarItem: null
            };
        }
        return {
            popupMenu: <PopupMenu submenu={item.submenu} parent={item} ref={(self) => { if (self) showState = self.setShowState }} commr={this.props.commr} />,
            navBarItem: <NavBarItem commr={this.props.commr} togglePodcastsVisibility={typeof this.props.togglePodcastsVisibility == 'undefined' ? this.showPodcastsSection : this.props.togglePodcastsVisibility} text={item.text} toggleNav={(self, shouldShow) => {
                self.setState({ show: shouldShow })
                self.setState({ highlightLink: shouldShow })
                showState(shouldShow);
            }} />,
        }
    };

    render() {
        if (this.state.toPodcasts === true) {
            return <Redirect to={{ pathname: '/home', state: { showPodcasts: true } }} />
        }

        this.state.isEeaCitizen = (localStorage.getItem(IsEeaCitizenKey) == 'true');
        var items = this.props.items.map(this.populateNavigation);
        return (
            <div>
                <div className="container-fluid" {...css(styles.headerContainer)}>
                    <div className="row" {...css(styles.underline)}>
                        <div className="col-11" /*role="tablist"*/ {...css(styles.column)} role="region" aria-label="Menu">
                            {
                                <ul  {...css(styles.menubar)} aria-label="button links">
                                    {items.map(x => x.navBarItem)}
                                </ul>
                            }
                        </div>
                        <div className="col-1" {...css(styles.column)}>
                            <ul>
                                <li title="Twitter34" {...css(styles.externalContent)}><a href="https://twitter.com/danske_research?lang=en" target="blank"><img src={twitter} alt={"Twitter"} /></a></li>
                                {/*<li title="Youtube" {...css(styles.externalContent)}><a href={this.state.youtubeUrl} target="blank"><img src={youtube} /></a></li>                            */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" {...css(styles.popupMenuContainer)}>
                    <div className="col-12" {...css(styles.popupMenuContainer)}>
                        {items.map(x => x.popupMenu)}
                    </div>
                </div>
            </div>
        );
    };
}
