import React,{ Component } from 'react';
import { css } from 'glamor';
//import Tweet from 'react-tweet'
import * as styles from './twitterwidget.style';
import { Timeline } from 'react-twitter-widgets'

export default class TwitterWidget extends React.Component {
    constructor() {
        super();
        //this.state = { items: [], loaded:false};
    }

    componentDidMount() {
        // axios.get(`http://localhost:4000/feed/danske_research`)
        // .then(res => {              
        //           const items = res.data.map(tweet => {
        //             const {id_str,  user: {name, screen_name, profile_image_url}, 
        //             text, created_at, favorite_count, retweet_count, entities} = tweet

        //             return {id_str,  user: {name, screen_name, profile_image_url}, 
        //             text, created_at, favorite_count, retweet_count, entities}
        //           });
        //           this.setState({items});
        //           this.setState({loaded:true});
        // }).then(this.render(this.state.items));
    }

    render(){
        const linkProps = {target: '_blank', rel: 'noreferrer'}
        
        return(
            <div>     
                <Timeline
                dataSource={{
                sourceType: 'profile',
                screenName: 'danske_research'
                }}
                options={{
                username: 'danske_research',
                height: '560',
                width: '500px',
                chrome: 'noheader, nofooter'
                }}
            />
            </div> 
        )
    }
}