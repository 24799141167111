import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './newsection.style';
import ImageText from '../../components/imagetext/imagetext';
import * as translate from '../../assets/translations/translations';
import danskeDailyThumbnail from '../../assets/images/danskedaily.jpg';
import danskeWeeklyThumbnail from '../../assets/images/danskeweekly.jpg';
import httpProvider from '../../services/httpProvider.service';

export default class NewSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            weeklyDate: ""
        };
    }

    buildLatestUrl(Url, offset, limit, access, option, search) {
        return Url + 'offset=' + offset + '&limit=' + limit + '&access=' + access + '&option=' + option + '&search=' + search;
    }

    formatNumber = (num) => {
        return num < 10 ? '0' + num : num;
    }

    formatDate = (date) => {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return this.formatNumber(day) + '/' + this.formatNumber(month) + '/' + year;
    }

    componentDidMount() {
        let agreementAccepted = localStorage.getItem('agreementAccepted') == 'true';
        let restriction = (agreementAccepted ? "unrestricted" : "restricted");

        httpProvider.get(this.buildLatestUrl(window.env.ApiUrl,
            0, 1, restriction, "subcategory", translate.strings.getString("TR21", "EN")), { data: null })
            .then(res => {
                if (res.data.count >= 1) {
                    let weekly = new Date(res.data.articles[0].published_date);
                    weekly = this.formatDate(weekly);

                    this.setState({
                        weeklyDate: weekly
                    });
                }
            });
    }

    render() {


        let today = new Date();
        today = this.formatDate(today);

        return (
            <section className="popular-section" {...css(styles.infocusContainer)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-12" role="region" aria-label={translate.strings.TR19}>
                            <h2 {...css(styles.H2)}>{translate.strings.TR19}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-6 col-sm-12 col-12">
                            <ImageText date={today} aria-label="danske Daily" image={danskeDailyThumbnail} title={translate.strings.TR20} desc={translate.strings.TR75} url="Danske Daily" />
                        </div>
                        <div className="col-md-12 col-lg-6 col-sm-12 col-12">
                            <ImageText date={this.state.weeklyDate} aria-label="Danske Weekly" image={danskeWeeklyThumbnail} title={translate.strings.TR21} desc={translate.strings.TR76} url="Weekly Focus" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}