export const infocusContainer = {
    backgroundColor: '#E0EEF4',
    padding: '50px 7%'
}
export const H2 = {
    textAlign: 'center',    
    color: '#003755',
    font: '500 2.25em Danske Human'
}
export const carousalTablet = {        
    '@media(max-width: 767px)' : {
        display : 'none'
    },
    '@media(min-width: 1024px)': {
        display : 'none'
    }
}
export const carousalSite = {        
    '@media(max-width: 1023px)': {
        display : 'none'
    }
}
export const carousalMobile = {        
    '@media(min-width: 768px)': {
        display : 'none'
    }
}
export const card = {
    margin:'10', 
    padding:'10',
    '@media(min-width: 768px) and (max-width:1024px)': {
        minHeight:'500px'
    }    
}