import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'glamor';
import * as styles from './articlepage.style';
import axios from 'axios';
import * as navigation from '../../configs/navigation';
import HeaderSection from '../../components/common/header/header';
import HeaderTabletSection from '../../components/common/header/header.tablet';
import FooterSection from '../../components/common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import * as languages from '../../configs/languages';
import Config from '../../app.config';
import Search from '../search/search';
import ExclusiveContentWarning from './ExclusiveContentWarning';
import * as translate from '../../assets/translations/translations';

const IsProfessionalKey = 'disclaimer_isProfessional';

export default class ArticlePreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            articlePath: "",
            article: {},
            publishedUrl: null,
            showSearch: false,
            navigationOpened: false,
            numPages: 10,
            pageNumber: 1,
            page: null,
            articleLoaded: false,
            articleNotFound: false
        };
        this.showSearchComponent = this.showSearchComponent.bind(this);
        this.navigationOpenEvent = this.navigationOpenEvent.bind(this);
    }

    showSearchComponent = (value) => {
        this.setState({ showSearch: value });
        setTimeout(function () { if (document.getElementById("searchText") != null) { document.getElementById("searchText").focus(); } }, 300);
    }

    navigationOpenEvent = (value) => {
        if (this.detectIE() != false) {
            this.setState({ navigationOpened: value });
        }
    }

    detectIE = () => {
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        let trident = ua.indexOf('Trident/');
        if (trident > 0) {
            let rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        let edge = ua.indexOf('Edge/');
        if (edge > 0) {
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
        return false;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let language = this.props.match.params.langauge;
        let selectedLanguageLocale = languages.Languages.filter((edition) => edition.edition == language)
        this.setState({ articleId: this.props.match.params.articleid })
        axios({
            url: window.env.ArticleUrl + this.props.match.params.articleid,
            method: 'get',
            data: null,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': selectedLanguageLocale[0].langaugeLocale
            }
        })
            .then(res => {
                if (res.data == 'article not found') {
                    this.setState({ articleNotFound: true });
                }
                else {
                    this.setState({ article: res.data });
                    this.setState({ articleLoaded: true });
                    window.articleLoaded(res.data);
                } 
            })
            .catch(err => {
                this.setState({ articleNotFound: true });
            });
    }

    render() {
        const { pageNumber, numPages } = this.state;
        let pagination = null;

        if (this.state.pages) {
            pagination = this.renderPagination(this.state.page, this.state.pages);
        }
        let pdfWidth = 1240;
        if (window.innerWidth >= 1440) {
            pdfWidth = 1240;
        }
        if (window.innerWidth > 1024 && window.innerWidth < 1440) {
            let reductionWidth = 1440 - window.innerWidth;
            pdfWidth = pdfWidth - reductionWidth;
        }
        var articleShown = !this.state.article.exclusive || (localStorage.getItem(IsProfessionalKey) == 'true');

        return (
            <div>
                <div>
                    <HeaderTabletSection history={this.props.history} />
                    <HeaderSection showSearch={this.showSearchComponent} navEvent={this.navigationOpenEvent} history={this.props.history} />
                    <NavBar items={this.props.match.params.commr ? navigation.commrNavInfo : navigation.navInfo} navEvent={this.navigationOpenEvent} />
                    {this.state.showSearch ? <Search homepage={false} /> : null}
                </div>
                {this.state.articleLoaded ?
                    articleShown ?
                        <div id="pdfviewer" {...css(styles.articlePreviewContainer)} className={this.state.navigationOpened ? 'navigationOpened' : ''}>
                            <object {...css(styles.pdfViewerStyle)} data={this.state.article.published_url} style={{ '-webkit-overflow-scrolling': 'touch' }} type="application/pdf" width="100%" height="800px">
                                <embed src={this.state.article.published_url} type="application/pdf" />
                            </object>
                        </div>
                        : <ExclusiveContentWarning />
                    : this.state.articleNotFound ? <div className="container-fluid" {...css(styles.articleContent)}> <section>
                        <div> <p {...css(styles.paragraph)}>{translate.strings.TR239}</p> </div>
                    </section></div> : <div className="container-fluid" {...css(styles.articleContent)} />
                }
                <FooterSection commr={this.props.match.params.commr ? true : false}/>
            </div>
        )
    }
}