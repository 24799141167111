import { overFlow } from "../twitterwidget/twitterwidget.style";

export const H2 = {
    textAlign: 'left',
    color: '#003755',
    font: '500 2.25em Danske Human',
    marginBottom: '5px'
};
export const closeIcon = {
    width: '100%',
    height: '100%'
};
export const restrictedLabel = {
    width: '95%',
    float: 'left',
    margin: '-5px 0 0 15px'
}
export const agreementBlock = {
    clear: 'both',
    marginTop: '10px',
    display: 'flex'
}
export const flagIcons = {
    height: '20px',
    width: '20px',
    margin: '0 10px 3px 0',
    cursor: 'pointer'
}
export const flagsBlock = {
    display: 'inline-block'
}
export const underline = {
    height: '2px',
    width: 'calc(100%)',
    background: '#003755',
    display: 'block',
    width: '70%'
}
export const agreementRadioBlock = {
    marginTop: '25px'
}
export const paragraph = {
    marginTop: '0',
    marginBottom: '20px',
    color: '#003755'
}
export const link = {
    // color: '#009fda !important'
    color: '#007BC7 !important'
}
export const tosText = {
    height: "calc(100vh - 500px)",
    minHeight: "100px",
    overflowY: "auto",
}

export const LinkFix = {
    textDecoration: 'underline !important',
    overFlow: 'initial !important'
}