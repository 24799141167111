import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './header.style';
import logo from '../../../assets/icons/logo.svg';
import { Link } from 'react-router-dom';
import * as translate from '../../../assets/translations/translations';
import BurgerMenu from '../../../components/burgermenu/burgermenu';
import FontAwesome from 'react-fontawesome';
import CloseIcon from '../../../assets/icons/close-small_darkblue.svg';

export default class HeaderTabletSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showHomeLink: (this.props.showHomeLink === undefined ? true : this.props.showHomeLink)
    };
  }

  changeLanguage(language) {
    translate.strings.setLanguage(language);
    this.setState({});
  }

  setShow() {
    this.state.show ? this.setState({ show: false }) : this.setState({ show: true })
  }

  componentWillReceiveProps(newProps) {
    this.setState({ show: newProps.hideNav })
  }

  render() {
    return (
      <header {...css(styles.tabletheaderContainer)}>
        <div className="container-fluid"  {...css(styles.headerContent)}>
          <div className="row" {...css(styles.headerBorder)}>
            <div className="col-md-3 col-lg-3 col-sm-3 col-6" >
              {this.state.showHomeLink ?
                <Link to="/home">
                  <img src={logo} {...css(styles.logo)} />
                </Link> :
                <img src={logo} {...css(styles.logo)} />
              }
            </div>
            <div className="col-md-7 col-lg-7 col-sm-7 col-4">
            </div>
            <div className="col-md-1 col-lg-1 col-sm-1 col-1" {...css(styles.burgerbutton)} onClick={() => this.setShow()}>

              <div id="nav-icon3" className={this.state.show ? 'open' : ''}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div {...css(styles.menuText)}>Menu</div>

            </div>
          </div>
          <br />
          {this.state.show ?
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12 col-12" {...css(styles.burgermenu)}>
                <BurgerMenu history={this.props.history} togglePodcastsVisibility={this.props.togglePodcastsVisibility} />
              </div>
            </div> : null}
        </div>

        {this.state.show ?
          <div {...css(styles.overlayContainer)}>
            <div {...css(styles.overlay)}></div>
          </div> : null}
      </header>
    );
  }
}