import * as translate from '../assets/translations/translations';
let language = localStorage.getItem('language') || 'EN';
translate.strings.setLanguage(language);

export const subscriptions = [
  {
    "category": "Macroeconomics",
    "exclusive": false,
    "subcategory": [
      {
        "categoryname": "Danish economy (in Danish)",
        "language": "Danish",
        "description": ["Alle vores makroanalyser om dansk økonomi. Flash comments, Danske Banks Modelfamilie, Danske Banks Eksportindeks, homes indeks og vores ugentlige podcast, Markedsguiden. Analyserne udkommer på dansk."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Denmark (in English)",
        "language": "English",
        "description": ["All our macro research on Denmark"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Sweden",
        "language": "English",
        "description": ["All our macro research on Sweden"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Norway",
        "language": "English",
        "description": ["All our macro research on Norway"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Finland",
        "language": "English",
        "description": ["All our macro research on Finland"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "UK",
        "language": "English",
        "description": ["All our macro research on the UK"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Euro Area",
        "language": "English",
        "description": ["All our macro research on the Euro Area"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "US",
        "language": "English",
        "description": ["All our macro research on the US"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Japan",
        "language": "English",
        "description": ["All our macro research on Japan"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "China",
        "language": "English",
        "description": ["All our macro research on China"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Emerging markets",
        "language": "English",
        "description": ["All our macro research on Emerging Markets"],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Macro strategy",
        "language": "English",
        "description": ["Macro Strategy is a weekly publication (released every Friday) where we write about current market themes and our cross-assets views on macroeconomics, foreign exchange rates, fixed income and equities. Macro Strategy is targeted clients who want a short and precise overview of what happens in the markets and where we think markets are heading. The publication is usually 2-3 pages including an overview table at the end. This subscription also includes the Sunday one-pager Harr’s view."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Aktienyt",
        "language": "Danish",
        "description": ["Hver morgen modtager du information om udviklingen på det nordiske aktiemarked, når du tilmelder dig dette abonnement. Informationen består af de seneste nyheder og tendenser på aktiemarkederne i Danmark, Sverige, Norge og Finland. Publikationen er på dansk."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Danske Morning Mail",
        "language": "English",
        "description": ["Our morning report Danske Morning Mail includes a brief overnight summary and a list of the coming day’s most important events and key data on the equity, money, foreign exchange and bond markets. In-depth comments on FI and FX markets.  For a shorter version in Danish, please subscribe for Markedsnyt."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Weekly Focus",
        "language": "English",
        "description": ["The Weekly Focus is published on Fridays and includes a review of the week as well as a preview of next week’s indicators and economic events. The report also contains Danske Bank's latest economic and financial forecast as well as our Global Macro views."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Makro ja Markkinat Viikkokatsaus",
        "language": "English",
        "description": ["TViikkokatsaus julkaistaan perjantaisin, ja siinä käydään läpi kuluneen viikon tärkeimmät taloustapahtumat, ennakoidaan tulevan viikon näkymiä sekä nostetaan esiin Danske Bankin relevantit makro- ja markkinaennusteet."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Market Movers",
        "language": "English",
        "description": ["Market Movers is a weekly calendar published Fridays and contains the most important events with significance for the global financial markets. The calendar is relevant to both institutional and private investors."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Markedpladsen Podcast",
        "language": "Danish",
        "description": ["I vores podcast Markedspladsen giver vi hver uge en kort version af, hvordan vi ser på økonomien og markederne, og hvad vi holder øje med af vigtige begivenheder i ugen, der kommer. Udkommer kun på dansk."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Nordic Outlook",
        "language": "English",
        "description": ["Nordic Outlook is Danske Bank’s quarterly review of the Nordic economies. It also includes our outlook for global economy and long-term financial estimates. Nordic Outlook is in English. "],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Geopolitical Radar",
        "language": "English",
        "description": ["Geopolitical radar is published on a monthly basis and gives a run-down of recent events and put it into a broader perspective. From time to time it contains updates on developments around de-globalisation, de-coupling and potential changes to global structures such as possible de-dollarisation, new institutions, BRICS expansion etc. In English."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Spending Monitor",
        "language": "English",
        "description": ["The Spending Monitor data is based on transactions online and offline, both domestically and abroad, with cards in stores for around 1m Danske Bank Danish personal customers with active accounts. All data is anonymised and non-referable. The spending data is used as a proxy for private consumption. In English."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      }
    ]
  },
  {
    "category": "Foreign Exchange",
    "exclusive": false,
    "subcategory": [
      {
        "categoryname": "All FX Research ",
        "language": "English",
        "description": ["All FX Research in English. Please subscribe separately to Danske Technical Update and the Market Guide."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Markedsguiden (DK version)",
        "language": "Danish",
        "description": ["Markedsguiden er målrettet danske erhvervsvirksomheder med det formål at styrke virksomhedens beslutningsprocesser i relation til finansielle overvejelser. Markedsguiden udkommer en gang månedligt, og her finder du et kortfattet overblik over udviklingen i de finansielle markeder samt Danske Banks aktuelle anbefalinger på rente- og valutaafdækning. ", "", "Abonnerer du på Markedsguiden, vil du også modtage vores valuta-podcast Valutakrydsild."],
        "exclusive": false,
        "disclaimer": ""
      },
      {
        "categoryname": "Market Guide (UK version)",
        "language": "English",
        "description": ["The Market Guide is designed for euro-based companies and aims to strengthen corporate processes in relation to financial decision-making. The Market Guide is published monthly and presents a brief overview of recent developments in - and our outlook for - the financial markets as well as our recommendations on interest rate and FX hedging.  "],
        "exclusive": false,
        "disclaimer": ""
      },
      {
        "categoryname": "Marknadsguiden (SE version)",
        "language": "Swedish",
        "description": ["Marknadsguiden är en månatlig publikation från Danske Bank som ger en översikt över den senaste utvecklingen på de finansiella marknaderna och presenterar bankens valuta- och ränteprognoser. I publikationen ger vi rekommendationer i valutasäkring åt företag med exponering mot utländska valutor, förslag på räntesäkringsstrategier samt behandlar olika finansiella teman med relevans för det finansiella beslutsfattandet."],
        "exclusive": false,
        "disclaimer": ""
      },
      {
        "categoryname": "Markedsguiden (NO version)",
        "language": "Norwegian",
        "description": ["Markedsguiden er rettet mot norske bedrifter med sikte på å styrke bedriftenes beslutningsprosesser knyttet til finansiell risiko. Markedsguiden publiseres en gang månedlig, og her finner du en kort oversikt over utviklingen i de finansielle markedene samt Danske Bank' aktuelle anbefalinger på rente- og valutasikring."],
        "exclusive": false,
        "disclaimer": ""
      },
      // {
      //   "categoryname": "Risk Manager (FI version)",
      //   "language": "Finnish",
      //   "description": ["Risk Manager julkaistaan kuukausittain ja se esittää lyhyesti katsauksen rahoitusmarkkinoiden viimeaikaisesta kehityksestä sekä tulevaisuudennäkymistä. Katsaus sisältää myös Danske Bankin korko- ja valuuttasuojausnäkemyksen."],
      //   "exclusive": true,
      //   "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      // },
      {
        "categoryname": "FX Top Trades",
        "language": "English",
        "description": ["FX Top Trades is a yearly publication that looks at themes for FX markets and how professional investors should position for these. The publication is in English."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      // {
      //   "categoryname": "FX Hedging",
      //   "language": "English",
      //   "description": ["FX Hedging is an ad hoc supplement to the Market Guide providing FX hedging recommendations. FX Hedging is primarily targeted corporates."],
      //   "exclusive": true,
      //   "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      // },
      {
        "categoryname": "FX Strategy ",
        "language": "English",
        "description": ["FX Strategy is ad hoc research on FX markets. FX Strategy pieces contain FX recommendations targeted at professional investors. In English."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      // {
      //   "categoryname": "FX Edge",
      //   "language": "English",
      //   "description": ["FX Edge is ad hoc research covering a specific topic relating to currency markets. FX Edge includes more in-depth detail than FX Strategy and contains FX recommendations targeted at professional investors. In English."],
      //   "exclusive": true,
      //   "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      // },
      // {
      //   "categoryname": "FX Essentials",
      //   "language": "English",
      //   "description": ["Weekly FX publication (one-pager) containing key FX Research views on G4 and Scandi currencies in English with a heightened focus on shorter-term FX issues (both spot and options). "],
      //   "exclusive": true,
      //   "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      // },
      {
        "categoryname": "FX Trading Portfolio",
        "language": "English",
        "description": ["FX Trading Portfolio is ad hoc research that includes specific trading recommendations targeted at professional investors. In English."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "FX Forecast Update",
        "language": "English",
        "description": ["Provides Danske Bank's official FX forecast on a one-, three-, six- and 12-month horizon, for the G10 FX universe. An introduction presents the main FX market themes, followed by individual pages on the major FX crosses. Monthly, in English. "],
        "exclusive": false,
        "disclaimer": ""
      },
      {
        "categoryname": "Valuuttaennuste (FX Forecasts, in Finnish)",
        "language": "English",
        "description": ["FX Forecast Update sisältää Danske Bankin valuuttaennusteet yhden, kolmen, kuuden ja 12kk ajalle. Suomenkielinen tiivistelmä sisältää keskeisimmät teemat Yhdysvaltojen dollarin, Ruotsin ja Norjan kruunujen sekä Englannin punnan osalta. Englanninkielinen ennustedokumentti kattaa yksityiskohtaisemman läpikäynnin eri valuuttamarkkinoiden ajureista."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      // {
      //   "categoryname": "Valuuttaennuste (FI version of FX Forecasts)",
      //   "language": "English",
      //   "description": ["Valuuttaennuste julkaistaan kuukausittain ja se tarjoaa Danske Bankin valuuttaennusteen 1 kk, 3kk, 6kk ja 12 kk horisontille. Julkaisussa esitellään tärkeimmät ajankohtaiset valuuttamarkkinoiden teemat, sekä jäljempänä valuuttakohtaiset ennusteet."],
      //   "exclusive": true,
      //   "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      // },
      {
        "categoryname": "IMM Positioning Update",
        "language": "English",
        "description": ["Provides an overview of last week’s positioning in the FX and commodities market based on weekly CFTC data. The reader receives in-depth knowledge of flows and positioning in the FX and Commodities market. Published Monday morning."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      }
    ]
  },
  {
    "category": "Commodities",
    "exclusive": true,
    "subcategory": [
      {
        "categoryname": "Commodities Research",
        "language": "English",
        "description": ["Danske Bank’s commodities research. The reports deal with energy, base metals and grains, including our commodity forecasts. In English."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      }
    ]
  },
  {
    "category": "Fixed Income",
    "exclusive": false,
    "subcategory": [
      {
        "categoryname": "All Reading the Markets",
        "language": "English",
        "description": ["This subscription includes all our Reading the Markets publications in English."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Danish FI markets (in Danish)",
        "language": "Danish",
        "description": ["Strategier der dækker det danske realkredit-, stats- og derivatmarked. Listen indeholder desuden vores danske flagshipspublikation: Reading the Markets Denmark"],
        "exclusive": true,
        "disclaimer": "Kontakt din sædvanlige kunderådgiver i Danske Bank for at abonnere."
      },
      {
        "categoryname": "Danish FI markets (in English)",
        "language": "English",
        "description": ["Strategies that cover Danish mortgage bonds, Danish government bonds and derivatives. This list includes our flagship publication: Reading the Markets Denmark "],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Reading the Market EUR",
        "language": "English",
        "description": ["This publication targets institutional investors focusing on short-term interest rate developments, thematic drivers of EUR rates markets and bond supply. Bond supply is with focus on both EUR cash bonds and SSA bonds. Published weekly. This subscription includes ad hoc research on EUR rates markets as well."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Reading the Markets - Norway",
        "language": "English",
        "description": ["Reading the Markets Norway is our flagship publication on the Swedish market. The report focuses on short-term development on the Norwegian FI and FX markets. Published weekly. This subscription includes ad hoc research on the Norwegian FI market."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Reading the Markets - Sweden",
        "language": "English",
        "description": ["Reading the Markets Sweden is our flagship publication on the Swedish market. The report focuses on short-term development on the Swedish FI and FX markets. Published weekly. This subscription includes ad hoc research on the Swedish FI market. "],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Reading the Markets USD",
        "language": "English",
        "description": ["Reading the Markets USD. This publication targets large corporations and institutional investors with exposure to the US economy and the USD. Every week we aim to share insights and views on the US economy, the USD and the US STIR and bond markets."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Government Bonds Weekly",
        "language": "English",
        "description": ["Government Bonds Weekly focuses on the European and Scandinavian government bonds markets. Published weekly in English. FI Strategy on ad hoc basis covering international FI markets."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Yield Outlook",
        "language": "English",
        "description": ["Danske Bank’s monthly yield forecasts."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Renteudsigter",
        "language": "Danish",
        "description": ["Danske Banks månedlige renteprognoser."],
        "exclusive": false,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Danish Bond Key Figures (csv)",
        "language": "(.csv file) ",
        "description": ["Daily key figures for Danish Bonds based on Danske Bank's prices in a CSV file for the professional investor."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      },
      {
        "categoryname": "Danish Bond Key Figures  (excel)",
        "language": "(Excel file)",
        "description": ["Daily key figures for Danish Bonds based on Danske Bank's prices in an Excel file for the professional investor."],
        "exclusive": true,
        "disclaimer": "Please contact your account manager at Danske Bank to subscribe."
      }
    ]
  }

];
