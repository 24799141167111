export const paragraph = {
    marginTop: '0',
    marginBottom: '20px',
    color:'#003755',
    fontFamily:'Danske Text',
    '@media(min-width:0px) and (max-width:767px)': {
        marginLeft: '20px'
    }
}

export const bluehighlight = {
    color: '#5D9CE5'
}

export const H1Left = {
    textAlign: 'left',    
    color: '#003755',
    font: '500 2.75em Danske Human',
    '@media(min-width:0px) and (max-width:767px)': {
        font: '500 2.25em Danske Human',
        paddingLeft: '20px'
    } 
};

export const H1Center = {
    textAlign: 'Center',    
    color: '#003755',
    font: '500 2.75em Danske Human' 
};

export const H4Left = {
    textAlign: 'left',    
    color: '#003755',
    font: '600 1.50em Danske Human',
    lineHeight: '30px',
    fontSize:'22px'
};

export const standardColor = {
    color : '#003755'
}

export const nopadding = 
{
    padding : '0 !important',
    '@media(min-width:0px) and (max-width:767px)': {
        padding: '0 15px !important'
    }
}

export const borderline = {
    borderBottom: '2px solid #EFF0F0'
}

export const borderlineBlack = {
    borderTop: '2px solid black'
}

export const pointerCursor = {
    cursor: 'pointer'
}




