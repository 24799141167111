export const summaryContainer = {
    backgroundColor: '#E0EEF4',
    padding: '50px 7%'
}
export const summay = {
    textAlign: 'center',
    color: '#003755',
    font: '500 2.25em Danske Human'
}
export const articleHeading = {
    padding: '105px 0 25px'
}
export const articleContent = {
    minHeight: 'calc(100vh - 350px)',
    maxWidth: '600px',
    marginTop: '50px',
    '@media(min-width:0px) and (max-width:767px)': {
        margin: '50px 5% 0 3% !important'
    }
}
export const H1 = {
    textAlign: 'center',
    color: '#003755',
    font: '500 2.75em Danske Human'
}
export const focusTag = {
    position: 'absolute',
    top: '-15px',
    // backgroundColor: '#009FDA',
    // color: '#FFF',
    backgroundColor: '#007BC7',
    color: '#FFF',
    borderRadius: '2px',
    fontSize: '.75em',
    padding: '10px'

}
export const summaryAnchor = {
    // color: '#009FDA',
    color: '#007BC7',
    fontSize: '1em',
    display: 'block',
    marginTop: '5px',
    textDecoration: 'none !important',
    '&:hover': {
        // color: '#009FDA'
        color: '#007BC7',
    },
    float: 'left',
    paddingRight: '10px',
}
export const H2 = {
    color: '#003755',
    font: '500 2.5em Danske Human',
    margin: '15px 0 5px',
    cursor: 'pointer',
    textAlign: 'left !important',
    display: 'block',
    '&:hover': {
        color: '#009fda !important'
    }
}
export const H2WithoutUnderline = {
    color: '#003755',
    font: '500 2.5em Danske Human',
    margin: '15px 0 5px',
    textAlign: 'left !important',
    display: 'block',
    '&:hover': {
        color: '#003755',
    }
}
export const pubDate = {
    fontSize: '.875em',
    // color: '#7F9BAA',
    color: '#556D7E',
    padding: '0px',
    margin: '0'

}
export const localeTitle = {
    color: '#003755',
    fontSize: '1em',
    display: 'block',
    fontWeight: 'bold',
    paddingRight: '10px',
    float: 'left'
}
export const summaryText = {
    fontSize: '.875em',
    lineHeight: '1.275em',
    margin: '10px 0 20px',
    color: '#003755'
}
export const articleButton = {
    border: '1px solid #37B0DE',
    borderRadius: '40px',
    background: 'linear-gradient(0deg, #003755 0%, #003755 100%)',
    padding: '10px 50px',
    textAlign: 'center',
    color: '#FFF !important',
    textDecoration: 'none',
    display: 'inline-block',
    cursor: 'pointer',
    margin: '0',
    '&:hover': {
        color: '#FFF !important',
        textDecoration: 'none',
    }
}
export const rightArrow = {
    verticalAlign: 'middle',
    height: '16px',
    marginLeft: '15px'
}
export const downloadIcon = {
    verticalAlign: 'middle',
    height: '25px',
    marginLeft: '10px'
}
export const articlePreviewContainer = {
    width: '85%',
    margin: '75px auto 25px',
    overflow: 'visible !important',
    overflowScrolling: "touch",
    WebkitOverflowScrolling: "touch",
    transition: 'margin 400ms',
    minHeight: 'calc(100vh - 350px)'
}
export const padding0 = {
    padding: '0 !important'
}
export const pdfViewerStyle = {
    overflow: 'scroll !important',
    overflowY: 'scroll !important',
    overflowX: 'scroll !important',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
}
export const siteUrl = {
    '@media(max-width: 1024px)': {
        display: 'none'
    }
}
export const tabletUrl = {
    '@media(min-width: 1025px)': {
        display: 'none'
    }
}
export const canvasContainer = {
    width: '100%'
}
export const paragraph = {
    textAlign: 'center',
    color: '#003755',
    font: '600 1em Danske Text',
    marginTop: '25px'
}