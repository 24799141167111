export const blueBackground = {
    backgroundColor: '#d7e9f1',
    padding: '25px 0'
}

export const searchColumn = {
    backgroundColor: '#FFF',
    width: '60% !important',
    padding: '0',
    marginLeft: '5%'
}

export const searchInput = {
    height: '45px',
    width: '85%',
    border: 'none',
    outline: 'none'
}
export const padding0 = {
    padding: '0 !important'
}

export const countryList = {    
    listStyleType: 'none',  
    marginTop: '10px',
    position: 'relative',
    float: 'right',    
    width: '100%',
    marginBottom: '5px'
}
export const countryListItems = {
    float: 'left',
    color: '#003755',    
    fontWeight: 'bold',    
    margin:'0 0 0 8%',
    cursor: 'pointer',
    float: 'right',
    '@media(min-width: 768px) and (max-width:1024px)': {
        fontSize: '.8em',
        padding: '0 0 20px 2%',
    }
}
export const directionIcon = {
    marginTop: '-2px',
    verticalAlign: 'top'
}
export const localeTitle = {
    color: '#003755',
    fontSize: '1em',
    display: 'block'
}
export const localeSubTitle = {
    color: '#7F9BAA',
    fontSize: '.875em',
    display: 'block',
    marginTop: '-6px'
}
export const localeDropdown = {
    backgroundColor: '#FFF',
    boxShadow:'0 0 10px rgba(81, 81, 81, 0.5)',
    position: 'absolute',
    top: '40px',
    right:'-20.5%',
    zIndex: '999',
    padding: '5px 0',
    borderRadius: '2px',
    '@media(min-width: 768px) and (max-width:1024px)': {
        right:'-11.5%'
    }
}
export const locale = {
    padding: '10px 25px 4px'
}