import React, { PropTypes, Component } from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from 'react-router-dom';
import ArticlePage from './components/article/ArticlePage';
import ArticlePreview from './components/article/articlePreview';
import HomePage from './components/home/HomePage';
import CommissionedResearch from './components/commissionedresearch/CommissionedResearch';
import CommrAboutPage from './components/commissionedresearch/parts/CommrAboutPage';
import ArticleShortPage from './components/article/ArticleShortPage';
import ArticleListPage from './components/articlelist/ArticleListPage';
import { BrowserRouter } from 'react-router-dom';
import SubscriptionPage from './components/subscription/subscription';
import SubscriptionCategory from './components/subscription/subscriptioncategory';
import SubscriptionConfirm from './components/subscription/subscirptionconfirm';
import ContactUs from './components/about/ContactUs';
import AboutDanskeBankResearch from './components/about/AboutDanskeBankResearch';
import RedirectToEbank from './components/Ebank/RedirectToEbank';
import Faq from './components/about/Faq';
import { createStore } from 'redux';
import { Provider, connect } from 'react-redux';
import rootReducer from './store/rootreducer';
import { HashRouter } from 'react-router-dom';
import * as translate from './assets/translations/translations';
import { css } from 'glamor';
import * as styles from './components/home/homepage.style';
import DbCookies from './components/dbcookies/dbcookies';

const store = createStore(rootReducer)

class App extends Component {

  componentWillMount = () => {
    let language = localStorage.getItem('language') || 'ALL';
    localStorage.setItem('language', language);
    translate.strings.setLanguage(language);
  };

  render() {
    return (
      <div {...css(styles.mainContainer)}>
        <DbCookies />
        <HashRouter basename="/Research/">
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/home" component={HomePage} />
            <Route path="/article/:articleid/:langauge/:commr?" component={ArticlePage} />
            <Route path="/articlepreview/:articleid/:langauge/:commr?" component={ArticlePreview} />
            <Route path="/articlelist/:search/:commr?" component={ArticleListPage} />
            <Route path="/category/:search" component={ArticleListPage} />
            <Route path="/articlesearch/:search/:searchByLatest?" component={ArticleListPage} />
            <Route path="/keywords/:search" component={ArticleListPage} />
            <Route path="/latestarticle" component={ArticleListPage} />
            <Route path="/subscription" component={SubscriptionPage} />
            <Route path="/confirm" component={SubscriptionConfirm} />
            <Route path="/subscriptioncategories/:category" component={SubscriptionCategory} />
            <Route path="/subscriptionconfirm" component={SubscriptionConfirm} />
            <Route path="/contactus" component={ContactUs} />
            <Route path="/about" component={AboutDanskeBankResearch} />
            <Route path="/faq" component={Faq} />
            <Route path="/ebank" component={RedirectToEbank}/>
            <Route path="/commissionedresearch" component={CommissionedResearch} />
            <Route path="/commrAboutPage" component={CommrAboutPage} />
          </Switch>
        </HashRouter>
        <HashRouter >
          <Switch>
            <Route path="/a/:baseid" component={ArticleShortPage} />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

export default App;
