export const mainContainer = {
    margin: '0 auto',
    width: '100%',    
    '@media(max-width: 1023px)': {
        width: '100%'
    },
    '@media(min-width: 1440px)': {
        maxWidth: '1440px'
    }
};
export const articleListHeading = {
    padding: '105px 0 25px',
    '@media(min-width:0px) and (max-width:767px)': {
        padding: '50px 0 5px'
    }
};
export const H1 = {
    textAlign: 'center',    
    color: '#003755',
    font: '500 2.75em Danske Human' 
};
export const paragraph = {
    textAlign: 'center',    
    color: '#003755',
    font: '600 1em Danske Text',
    marginTop: '25px' 
};
export const H5 = {
    textAlign: 'center',    
    color: '#003755',
    font: '500 1.375em Danske Human',
    margin: '50px 0 25px'
};
export const loadingText = {
    color: '#7F9BAA',
    fontFamily: "Danske Text v2 !important",
    fontSize: '14px',
    textAlign: 'center',
    padding: '50px 0'
}
export const articleListContent = {
    minHeight: 'calc(100vh - 350px)'
}
export const infiniteScroll = {
    width: '100%',
    padding: '10px 0'
}
export const spinner = {
    '-webkit-animation':'spin 3s linear infinite',
    '-moz-animation':'spin 3s linear infinite',
    'animation':'spin 3s linear infinite',
    margin: '0 auto'  ,
    display:'block'

}