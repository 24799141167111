import axios from 'axios';
import * as languages from '../configs/languages'; 

const httpProvider = axios.create({
    baseURL: window.env.ServerUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept-Language': getLanguageLocale()
    }
});

export default httpProvider;

function getLanguageLocale(){
    let language = localStorage.getItem('language') || 'EN'
    let maybeLocale = languages.Languages.find((edition) => edition.edition == language) 
    return maybeLocale ? maybeLocale.langaugeLocale : languages.DefaultLanguage.edition;
}