import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './disclaimer.style';
import CloseIcon from '../../assets/icons/close-small_darkblue.svg';
import DKFlag from '../../assets/icons/DK.svg';
import UKFlag from '../../assets/icons/GB.svg';
import SEFlag from '../../assets/icons/SE.svg';
import NOFlag from '../../assets/icons/NO.svg';
import FIFlag from '../../assets/icons/FIN.svg';
import Modal from 'react-modal';
import * as translate from '../../assets/translations/translations';
import { Link } from 'react-router-dom';

const IsProfessionalKey = 'disclaimer_isProfessional';
const IsEeaCitizenKey = 'disclaimer_isEaaCitizen';
const DescribeProfessionalInvestorKey = 'DescribeProfessionalInvestor';
let IsEeaCitizen = '';

export default class Disclaimer extends Component {
  constructor(props) {
    super(props);

    // language is mixed together with Edition feature. When language is 'ALL' (meaning user selecter All Editions) we should set disclaimer language to EN.
    var lang = localStorage.getItem('language');
    if (lang == 'ALL' || lang == null) {
      lang = 'EN';
    }
    this.state = {
      currentLanguage: lang,
      professional: localStorage.getItem(IsProfessionalKey) || (this.props.commr ? 'false' : null),
      EEACitizen: localStorage.getItem(IsEeaCitizenKey) || null
    }
    IsEeaCitizen = this.state.EEACitizen;

    translate.strings.setLanguage(lang);
    this.closeModalAndDescribeProfessionalInvestor = this.closeModalAndDescribeProfessionalInvestor.bind(this);
    this.closeDisclaimer = this.props.onDisclaimerRequestingClose.bind(this);
    this.signDisclaimer = this.props.onDisclaimerAgreementSigned.bind(this);
    Modal.setAppElement('#root');
  }

  updateDisclaimerLanguage(language) {
    this.setState({ currentLanguage: language });
    translate.strings.setLanguage(language);
  }

  closeModalAndDescribeProfessionalInvestor() {
    localStorage.setItem(DescribeProfessionalInvestorKey, "true");
    this.closeDisclaimer();
  }

  setProfessionInfo(event) {
    this.setState({ professional: event.target.value })
  }

  setEEAStatus(event) {
    this.setState({ EEACitizen: event.target.value })
  }

  agreeButton() {
    !this.props.commr ? localStorage.setItem(IsProfessionalKey, this.state.professional) : null;
    localStorage.setItem(IsEeaCitizenKey, this.state.EEACitizen);

    if (this.state.professional != null && this.state.EEACitizen != null) {
      if (this.state.professional == 'true' && this.state.EEACitizen == 'true') {
        localStorage.setItem('agreementAccepted', true);
      }
      else {
        localStorage.setItem('agreementAccepted', false);
      }
      localStorage.setItem('language', this.state.currentLanguage == 'EN' ? 'ALL' : this.state.currentLanguage);

      !this.props.commr ? this.signDisclaimer() : null;
      window.location.reload(); //Workaround for refreshing article list
    }
  }

  render() {
    let modalIsOpen = this.props.modalIsOpen;
    if (this.props.commr && IsEeaCitizen)//in commissioned research parts we don't have to be a professional to read (enough to be EEA resident)
      modalIsOpen = false;

    return (
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeDisclaimer}
          contentLabel="Disclaimer"
          shouldCloseOnOverlayClick={true}
          overlayClassName="Overlay"
          className="Modal"
          tabindex="-1"
        >
          <div style={{ zIndex: '9999', position: 'relative' }} >
            <header >
              {/* <h2 {...css(styles.H2) }>{translate.strings.TR77}</h2> */}
              <h1 {...css(styles.H2)}>{translate.strings.TR77}</h1>
              <div>
                <div {...css(styles.flagsBlock)}>
                  <img {...css(styles.flagIcons)} src={DKFlag} onClick={() => this.updateDisclaimerLanguage('DK')} alt='DK' />
                  {this.state.currentLanguage == 'DK' ? <span {...css(styles.underline)}></span> : null}
                </div>
                <div {...css(styles.flagsBlock)}>
                  <img {...css(styles.flagIcons)} src={SEFlag} onClick={() => this.updateDisclaimerLanguage('SE')} alt='SE' />
                  {this.state.currentLanguage == 'SE' ? <span {...css(styles.underline)}></span> : null}
                </div>
                <div {...css(styles.flagsBlock)}>
                  <img {...css(styles.flagIcons)} src={NOFlag} onClick={() => this.updateDisclaimerLanguage('NO')} alt='NO' />
                  {this.state.currentLanguage == 'NO' ? <span {...css(styles.underline)}></span> : null}
                </div>
                <div {...css(styles.flagsBlock)}>
                  <img {...css(styles.flagIcons)} src={FIFlag} onClick={() => this.updateDisclaimerLanguage('FI')} alt='FI' />
                  {this.state.currentLanguage == 'FI' ? <span {...css(styles.underline)}></span> : null}
                </div>
                <div {...css(styles.flagsBlock)}>
                  <img {...css(styles.flagIcons)} src={UKFlag} onClick={() => this.updateDisclaimerLanguage('EN')} alt='EN' />
                  {this.state.currentLanguage == 'EN' ? <span {...css(styles.underline)}></span> : null}
                </div>
              </div>
              <a onClick={this.closeDisclaimer} className="button-close close-reveal-modal" tabindex="-1">
                <img src={CloseIcon} {...css(styles.closeIcon)} alt='close' />
              </a>
            </header>
            <div>
              <div {...css(styles.tosText, { marginTop: "15px" })} tabindex="0">
                <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p1}</p>
                <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p2}</p>
                <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p3}</p>
                <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p4}</p>
                <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p5_1} <Link tabindex="-1" {...css(styles.LinkFix)} style={{ color: '#007BC7' }} to="/faq" onClick={this.closeModalAndDescribeProfessionalInvestor}>{translate.strings.disclaimer.tos_p5_2}</Link> {translate.strings.disclaimer.tos_p5_3}</p>
                {!this.props.commr && <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p6}</p>}
                <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p7}</p>
                <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p8}</p>
                <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p9}</p>
                <p {...css(styles.paragraph)}>{translate.strings.disclaimer.tos_p10}</p>
              </div>
              <div {...css({ marginTop: "15px" })}>
                {this.renderForm()}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  renderForm() {
    return (
      <form type="post" action="#">
        <fieldset>
          {
            !this.props.commr &&
            <div>
              <div {...css(styles.agreementBlock)} role="group" tabindex="-1">
                <input type="radio" value="true" name="professional" id="yesPro" checked={this.state.professional == 'true'} tabindex="-1" onChange={(e) => this.setProfessionInfo(e)} />
                <label {...css(styles.restrictedLabel)} htmlFor='yesPro'>{translate.strings.disclaimer.form_label_yesPro1} <Link tabindex="-1" to="/faq" {...css(styles.link)} onClick={this.closeModalAndDescribeProfessionalInvestor}>{translate.strings.disclaimer.form_label_yesPro2}</Link> {translate.strings.disclaimer.form_label_yesPro3}</label>
              </div>
              <div  {...css(styles.agreementBlock)} role="group">
                <input type="radio" value="false" name="professional" id="noPro" checked={this.state.professional == 'false'} tabindex="-1" onChange={(e) => this.setProfessionInfo(e)} />
                <label {...css(styles.restrictedLabel)} htmlFor="noPro">{translate.strings.disclaimer.form_label_noPro1} <Link tabindex="-1" to="/faq" {...css(styles.link)} onClick={this.closeModalAndDescribeProfessionalInvestor}>{translate.strings.disclaimer.form_label_noPro2}</Link></label>
              </div>
            </div>
          }

          <div {...css(styles.agreementRadioBlock)}>
            <div {...css(styles.agreementBlock)} role="group" tabindex="-1">
              <input type="radio" value="true" name="EEACitizen" id="yesEea" checked={this.state.EEACitizen == 'true'} tabindex="-1" onChange={(e) => this.setEEAStatus(e)} />
              <label {...css(styles.restrictedLabel)} htmlFor="yesEea" tabindex="-1">{translate.strings.disclaimer.form_label_yesEea}</label>
            </div>
            <div  {...css(styles.agreementBlock)} role="group">
              <input type="radio" value="false" name="EEACitizen" id="noEea" checked={this.state.EEACitizen == 'false'} onChange={(e) => this.setEEAStatus(e)} tabindex="-1" />
              <label {...css(styles.restrictedLabel)} htmlFor="noEea" tabindex="-1">{translate.strings.disclaimer.form_label_noEaa}</label>
            </div>
          </div>
          <a className={this.state.professional != null && this.state.EEACitizen != null ? 'button button-accept border-light-blue enabled' : 'button button-accept border-light-blue'} onClick={() => this.agreeButton()} style={{ overflow: 'initial' }} tabindex="-1">Agree</a>
        </fieldset>
      </form>
    );
  }
}