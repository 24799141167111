export const UPDATESUBSCRIPTION = 'updatesubscription';
export const RETRIVESUBSCRIPTION = 'retrivesubscription';

export const updateSubscription = data => ({
    type: UPDATESUBSCRIPTION,
    payload: {
      data
    }
});

export const retriveSubscription = data => ({
    type: RETRIVESUBSCRIPTION,
    payload: {
      data
    }
});