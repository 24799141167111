import LocalizedStrings from 'react-localization';

export const companyDescriptions = [
    {
        company: "Aktia",
        description: "Aktia with roots dating back to 1825 operates in banking, asset management and life insurance in Finland. Aktia operates mainly in coastal areas, the Helsinki region and inland growth areas. The modern Aktia was born in 1991 with the merger of eight savings banks. Aktia has approximately 310,000 customers who are served at over 30 branch offices and via comprehensive digital services."
    },
    {
        company: "Consti", 
        description: "Consti is one of the leading companies in Finland focusing on renovation and technical services. Consti’s service offering covers technical building services, pipeline renovation, renovation contracting, building facade repair and maintenance, and other renovation and technical services for residential, commercial as well as public buildings. Customer base includes housing corporations, real estate companies, public institutions and industrial players. Geographically, Consti’s activities are concentrated on major cities in Finland with the largest building renovation and improvement need as well as the strongest demographic drivers."},
    {
        company: "Digia", 
        description: "Digia is a Finnish software and IT service ccompany with ca. 1,200 employees. The company has three main business areas, description: consulting services (22% of 2019 sales), products (15% of sales£pv£ own ERP software and third-party products) and maintenance services (63% of sales). In 2016, Digia spun-off the Qt Group and has since then fully focused on Finnish IT services. The company targets 10% sales growth and 10% EBITA margin in 2020-2022 strategy period."},
    {
        company: "Glaston", 
        description: "Glaston is a manufacturer of glass processing machines. The main customer industries are architectural (c.80% of sales) and automotive (15%). The main product categories are heat treatment machines (used to make the glass stronger) and insulation machines. Services represented 32% of 2018 pro forma sales and new equipment 68%. Glaston made a transformational acquisition of Bystronic in early 2019, which doubled the company's size in terms of revenues. Glaston sells its products globally and is a leading player in its industry."},
    {
        company: "GomSpace", 
        description: "GomSpace is a ‘new space’ company that was founded in 2007 by Ph.D. students at the Aalborg University in Denmark. Since, GomSpace has developed into a leading nanosatellite manufacturer with a global reach and c.130 employees. GomSpace was listed on Nasdaq First North in 2016."},
    {
        company: "Greater Than", 
        description: "Greater Than is a Swedish tech company active in insuretech. With an extensive database of actual driving conditions the company has developed an artificial intelligence to assess the risk profile of drivers. The risk score is the basis for assessing risks and pricing usage based insurance. With the risk scoring as basis Greater Than offers complete solutions for insurance companies to digitalise the process for automotive insurance. Key benefits for insurance companies are real time monitoring of the insurance fleet, reduced claims costs and support to digitalise the process."},
    {
        company: "Iconovo", 
        description: "Iconovo AB develops, manufactures, and supplies inhalation products. The firm offers inhalers for use in the treatment of asthma and respiratory disorders. The company was founded in 2013 and is headquartered in Lund, Sweden."},
    {
        company: "Senzime", 
        description: "Senzime develops unique patient-oriented monitoring systems that make it possible to assess patients’ biochemical and physiological processes before, during and after surgery. The portfolio of technologies includes bedside systems that enable automated and continuous monitoring of life-critical substances such as glucose and lactate in both blood and tissues, as well as systems to monitor patients’ neuromuscular function perioperatively and in the intensive care medicine setting."},
    {
        company: "Sparekassen Sjælland Fyn", 
        description: "Sparekassen Sjælland-Fyn A/S provides banking products and services in Denmark. Its products and services include net banking, commercial insurance, mobile banking, rental of commercial lease, financing, insurance and pension."},
    {
        company: "Sprint Bioscience", 
        description: "Sprint Bioscience is a Sweden-based drug development company. It is engaged in the development of oncology therapeutics, focusing on cancer metabolism and immune-oncology. The company bases its research on its fragment based drug discovery (FBDD) platform, which enables rapid identification of molecules with properties suitable for drug development. Sprint Bioscience pipeline includes projects within acute myeloid leukaemia (AML) outlicensed to Petra Pharma, NASH out-licensed to LG Chem and two internal projects within immuno-oncology, currently unsigned. The company’s strategy involves out-licensing or collaborating at an early stage of drug discovery."},
    {
        company: "SyntheticMR", 
        description: "SyntheticMR provides software for MRI (magnetic resonance imaging) visualisation and analysis, and has agreements with some of the largest global OEMs for marketing and selling its product. SyntheticMR was founded in 2007 by Dr. Marcel Warntjes and was owned by life science group Accelerator AB until 2013, when it was listed on AktieTorget"},
    {
        company: "Taaleri", 
        description: "Taaleri is a Finnish financial services company. It offers wealth management services and guaranty insurance through its subsidiary Garantia. Taaleri group consists of three divisions: Wealth Management, Financing and Energia. At the end of 2019, Taaleri had 5,500 wealth management customers and assets under management totalling EUR7.1bn."},
    {
        company: "Talenom", 
        description: "Talenom is an accountancy company operating in Finland and since 2019 also in Sweden. Talenom offers a wide range of accountancy and other services including tax and legal advisory and financial management tools for SMEs. Talenom has ca. 700 employees and 40 locations. Talenom has ca. 5% market share in the fragmented Finnish market. In June 2015, Talenom was listed on the Helsinki First North Growth Market and in June 2017 to the main list of Nasdaq Helsinki."},
    {
        company: "Tikkurila", 
        description: "Tikkurila is a Finnish paint manufacturer. Decorative paints for consumers and professionals represent 82% of sales and industrial coatings 18%. The company holds a no. 1 market position in Finland, Sweden, Russia, and no. 4 position in Poland. The largest markets in 2018 were Russia (24% of sales), Sweden (23%) and Finland (17%). Tikkurila had around 2,700 employees and production facilities in six countries at the end of 2018. The company was founded in 1862 and listed on Nasdaq Helsinki in 2010."},
    {
        company: "Zenicor", 
        description: "Zenicor offers a complete system including a thumb EKG and a software backbone used for spontaneous and systematic screening for atrial fibrillation, the single most significant indicator for stroke. Zenicor's thumb EKG has been validated by several large scientific studies. These studies show that systematic screening using Zenicor's product is more efficient than the prevailing method, Holter EKG. Several countries are considering national screening programmes for atrial fibrillation but are awaiting additional scientific support. Conclusions from the large Swedish Strokestop study could provide such scientific support in the next couple of years."},
    {
        company: "Zutec", 
        description: "Zutec was founded in 1999 and is a software company that provides software for project and data management that targets the building and contruction sector."
    },
    {
        company: "Pharma Equity Group", 
        description: "Pharma Equity Group is a listed company on the Nasdaq Copenhagen stock exchange, it is fully dedicated to advancing the medical projects of its subsidiary, Reponex Pharmaceuticals A/S. With a focus on healthcare, Pharma Equity Group’s primary objective is to bring significant value to Reponex Pharmaceuticals’ medical projects."
    }
]
export const strings = new LocalizedStrings({
    EN: {
        TR1: "All Editions",
        TR2: "All research available",
        TR3: "UK Edition",
        TR4: "All research available in English",
        TR5: "Denmark Edition",
        TR6: "All research available in Danish and English",
        TR7: "Norway Edition",
        TR8: "All research available in Norwegian and English",
        TR9: "Sweden Edition",
        TR10: "All research available in Swedish and English",
        TR11: "Finland Edition",
        TR12: "All research available Finnish and English",
        TR13: "Contact Us",
        TR14: "Danske Bank Research",
        TR15: "Highlights",
        TR16: "Search for articles",
        TR17: "date format",
        TR18: "Read more",
        TR19: "What's new",
        TR20: "Danske Morning Comment",
        TR21: "Weekly Focus",
        TR22: "Choose Language",
        TR23: "Latest Research",
        TR24: "Show All",
        TR25: "Most Popular",
        TR26: "Latest Tweets",
        TR27: "Latest Podcast",
        TR28: "Latest Youtube videos",
        TR29: "Research Newsletter",
        TR30: "When a new research is published, it will be sent directly to your mailbox. Press the subscribe button below, and you will receive the newsletter.",
        TR31: "Sign Up",
        TR32: "Danske Bank footer + disclaimer? Which one to use for Research website",
        TR33: "This research is available for qualified professional investors* only. Please confirm below if you are a qualified professional investor in order to gain access.",
        TR34: "I hereby confirm that I am a qualified professional investor.",
        TR35: "Confirm",
        TR36: "Cancel",
        TR37: "Choose one or more newsletters",
        TR38: "For DCM Research, please click here to subscribe",
        TR39: "Continue",
        TR40: "Thank you for subscribing!",
        TR41: "You will receive an email shortly with a subscription confirmation",
        TR42: "Confirm Subscription",
        TR43: "Name",
        TR44: "Enter your name",
        TR45: "Email",
        TR46: "Enter your email address",
        TR47: "Company",
        TR48: "Enter the name of your company",
        TR49: "Your Account Manager",
        TR50: "Enter the name of your account manager",
        TR51: "By clicking on the checkbox you agree to the applicable",
        TR52: "Subscribe",
        TR53: "Choose a Subscription category",
        TR54: "Macroeconomics",
        TR55: "Fixed Income",
        TR56: "Foreign Exchange",
        TR57: "Commodities",
        TR58: "click here to subscribe.",
        TR59: "For DCM Research,",
        TR60: "Please note that DCM Research is for certified professional investors only.",
        TR61: "Terms and Conditions.",
        TR62: "Nordics",
        TR63: "International",
        TR64: "Periodicals",
        TR65: "Strategies",
        TR66: "Reading the markets",
        TR67: "Corporate Hedging",
        TR68: "Handbooks",
        TR69: "Views",
        TR70: "Credit",
        TR71: "Company Research (Corporates)",
        TR72: "Company Research (Financials)",
        TR73: "Covered bonds",
        TR74: "Forecasts",
        TR75: "Danske Bank's daily morning report",
        TR76: "Weekly Focus is our weekly report that looks at important economic events in the week to come",
        TR77: "Disclaimer",
        TR78: "This research category is not available to retail investors",
        TR79: "Suggestions",
        TR80: "Try different keywords in the search bar.",
        TR81: "Try other subcatagory in the navigation bar. ",
        TR82: "Equities",
        TR83: "About",
        TR84: "About Danske Bank Research",
        TR85: "Frequently Asked Questions(FAQ)",
        TR86: "Choose one or more categories and select relevant research.",
        TR87: "Ok",
        TR88: "Latest Video",
        TR89: "Macroeconomics",
        TR90: "Nordics",
        TR91: "Denmark",
        TR92: "Sweden",
        TR93: "Norway",
        TR94: "Finland",
        TR95: "Nordic Outlook",
        TR96: "International",
        TR97: "Euro area",
        TR98: "US",
        TR99: "UK",
        TR100: "China",
        TR101: "Japan",
        TR102: "Emerging Markets",
        TR103: "The Big Picture",
        TR104: "Periodicals",
        TR105: "Danske Morning Comment",
        TR106: "Weekly Focus",
        TR107: "Market Movers",
        TR108: "Scandi Markets Ahead",
        TR109: "Strategy",
        TR110: "",
        TR111: "Periodicals",
        TR112: "Government Bonds Weekly",
        TR113: "Danish Mortgage Bonds",
        TR114: "EUR Covered Bonds Weekly",
        TR115: "Yield Outlook",
        TR116: "Scandi Markets Ahead",
        TR117: "Danish Mortgage Bonds key figures",
        TR119: "Strategies",
        TR120: "Fixed Income Strategy",
        TR121: "Reading the Markets",
        TR122: "RtM Denmark",
        TR123: "RtM Norway",
        TR124: "RtM Sweden",
        TR125: "Corporate Hedging",
        TR126: "Market Guide",
        TR127: "Rates Hedging",
        TR128: "Handbooks",
        TR129: "Danish Covered Bond Handbook",
        TR130: "Nordic Covered Bond Handbook",
        TR131: "",
        TR132: "Periodicals",
        TR133: "FX Forecast Update",
        TR134: "IMM Positioning Update",
        TR136: "Danske Technical Update",
        TR137: "FX Top Trades",
        TR138: "",
        TR139: "FX Strategy",
        TR140: "FX Edge",
        TR141: "FX Trading Portfolio",
        TR142: "Reading the Markets",
        TR143: "RtM Denmark",
        TR144: "RtM Norway",
        TR145: "RtM Sweden",
        TR146: "Corporate Hedging",
        TR147: "Market Guide",
        TR148: "FX Hedging",
        TR149: "Credit",
        TR150: "Periodicals",
        TR151: "Handbook",
        TR152: "Credit Weekly",
        TR153: "Company Research (Corporates)",
        TR155: "Consumer goods",
        TR156: "Energy",
        TR157: "Industrials",
        TR158: "Healthcare",
        TR159: "Materials",
        TR160: "Real estate",
        TR161: "Services",
        TR162: "Shipping & Transportation",
        TR163: "TMT",
        TR165: "Utilities",
        TR166: "Company Research (Financials)",
        TR168: "Financials",
        TR169: "Covered Bond Update",
        TR170: "Covered bonds",
        TR171: "Forecasts",
        TR172: "Macroeconomics",
        TR173: "Macroeconomic forecast",
        TR174: "Economic data calendar",
        TR175: "Financial Forecasts",
        TR176: "Vs EUR",
        TR177: "Vs SEK",
        TR178: "Vs DKK",
        TR179: "Vs NOK",
        TR180: "FX tables",
        TR181: "EUR",
        TR182: "USD",
        TR183: "DKK",
        TR184: "SEK",
        TR185: "NOK",
        TR186: "Equities",
        TR187: "New Coverage",
        TR188: "Commissioned Research",
        TR189: "",
        TR190: "Log in to Danske Bank Equities",
        TR191: "Please accept all the agreement conditions",
        TR192: "DCM Research",
        TR193: "For DCM Research, please contact your account manager at Danske Bank to subscribe.",
        TR194: "Return",
        TR195: "Watch on YouTube",
        TR196: "Previous",
        TR197: "Go to Article",
        TR198: "About Danske Bank Research",
        TR199: "Danske Bank Research is Danske Bank’s research department. We have dedicated teams that cover a broad range of markets and economies.",
        TR200: "Macroeconomics and Strategy",
        TR201: "Economic research, market views and forecasts covering the Nordic countries as well as Europe, the US, Asia and emerging markets.",
        TR202: "Fixed Income",
        TR203: "Research that covers Scandinavian and eurozone government bonds and the Nordic covered bond markets with in-depth focus on Danish mortgage bonds. The department also covers global rates and derivatives markets.",
        TR204: "Foreign Exchange",
        TR205: "Professional research, forecasts and trading recommendations on global FX markets with a special focus on G10 and Scandi currencies.",
        TR206: "Commodities",
        TR207: "In-depth research on and forecasts for commodities",
        TR208: "Credit",
        TR209: "Updates and research with special focus on Scandinavia. Our periodical research offers a top-down perspective on the credit markets, while our company research includes issuer profiles and updates on Scandinavian issuers, including coverage and recommendations on cash bonds and credit default swaps.",
        TR210: "Equities",
        TR211: "Research on a wide range of Nordic listed companies, with a mix of broad local knowledge, market experience and fundamental expertise.",
        TR212: "Commissioned Research",
        TR213: "is company research on smaller listed companies that are not included in the Danske Bank Equity Research universe due to size and liquidity. This research has been commissioned by the subject companies and does not offer recommendations or target prices. All commissioned research is open to the public.",
        TR214: "Danske Bank Equity Research ",
        TR215: "is a platform offering all equity research produced in Danske Bank, including company notes, sector comments and strategy updates, as well as access to our extensive database with fundamental data and valuation metrics. Access to the platform requires MiFID 2 customer approval. Please contact your account manager at Danske Bank for more information.",
        TR216: "Publications",
        TR217: "You can read our macroeconomic research as well as our various forecasts on this website. We also produce research that is targeted at professional investors only.",
        TR218: "For any questions and concerns, contact Danske Bank customer support at",
        TR219: "or send an email to",
        TR220: "Latest Articles",
        TR221: "Today",
        TR222: "Presentations",
        TR223: "All Presentations",
        TR224: "Other Periodicals",
        TR225: "Other",
        TR226: "See all {0}",
        TR227: "Inflation forecast",
        TR228: "By clicking “subscribe” below you consent to receiving our research emails and our tracking of your opening of the mails and the attached documents for the purpose of obtaining updated subscribers’ information as well as measuring interest on the subjects of our research. You may always withdraw your consent by unsubscribing to our research emails or by writing to us to researchsubscriptions@danskebank.com. We keep this information for the period of your subscription. You may see more on how we process your personal data by pressing the link below.",
        TR229: "Scandi Vol Monitor",
        TR230: "GomSpace",
        TR231: "Podcasts",
        TR232: "Listen on SoundCloud",
        TR233: "Listen on iTunes",
        TR234: "Listen",
        TR235: "Zutec",
        TR236: "The research you are trying to view is only available to institutional and professional investors as defined in MiFID II, domiciled and residing in an EEA member state.",
        TR237: "Click here to go to the website Disclaimer where you can review your declared investor status",
        TR238: "Outlooks",
        TR239: "This research rapport is no longer available",
        TR240: "GBP",
        TR241: "Tikkurila",
        TR242: "Senzime",
        TR244: "Consti",
        TR245: "Aktia",
        TR246: "Glaston",
        TR247: "Taaleri",
        TR248: "Talenom",
        TR249: "Danske Bank Commissioned Research",
        TR250: "Companies",
        TR251: "Other Danske Bank Research",
        TR252: "Home",
        TR253: "All Macroeconomics ESG/Climate",
        TR254: "All Fixed Income ESG/Climate",
        TR255: "All Credit ESG/Climate",
        TR256: "ESG/Climate",
        TR257: "Non-Nordic Credit Research",
        TR259: "Other Comments",
        TR260: "War in Ukraine",
        TR261: "Updates",
        TR262: "Loader",
        disclaimer: {
            tos_p1: "Research material available on this website is intended for information purposes only. It is not an offer or solicitation to purchase or sell any financial instrument. The research material does not represent investment advice in the form of a personal recommendation to the reader.",
            tos_p2: "Whilst reasonable care has been taken to ensure that its contents are not untrue or misleading, no representation is made as to its correctness, accuracy or completeness and no liability is accepted for any direct or consequential loss arising from transactions made or rejected in reliance on the research material.",
            tos_p3: "Danske Bank, its affiliates or staff may perform services for or conclude business with or solicit business from, and may hold long or short positions in, or otherwise be interested in the investments (including derivatives) of, any issuer mentioned in the research material available on this website.",
            tos_p4: "These documents are based upon information that we consider reliable, but Danske Bank A/S has not independently verified the contents hereof. The opinions, estimates, and projections expressed in them are as of the date hereof and are subject to change without notice. No representation or warranty, express or implied, is made as to and no reliance should be placed on the fairness, accuracy, completeness or reasonableness of the information, opinions and projections contained in this document, and Danske Bank A/S accepts no liability whatsoever for any loss howsoever arising from any use of the documents on this website or their contents or otherwise arising in connection therewith.",
            tos_p5_1: "Much of the research material on this website is only suitable for and directed at institutional and professional clients",
            tos_p5_2: "(‘Professional Investors’)",
            tos_p5_3: "and is not suitable for or intended for retail investors.",
            tos_p6: "Any person accessing the research material on this website by clicking ”Yes” (confirming) that the person is a Professional Investor, will be deemed to have represented and agreed that he or she is a Professional Investor as defined in MiFID II (Directive 2014/65/EU of The European Parliament and of the Council on markets in financial instruments), or in the UK onshored MiFID II.",
            tos_p7: "This research is not intended for, and may not be redistributed to, persons outside the European Economic Area (EEA), or UK. ",
            tos_p8: "Any person who accesses the research material on this website by clicking ”Yes” (confirming) that the person is domiciled in and a resident of an EEA member state, or the UK will be deemed to have represented and agreed that he or she is domiciled in and a resident of an EEA member state, or the UK.",
            tos_p9: "Danske Bank A/S will rely on the truth and accuracy of the response. Any person who is not a Professional Investor should not act or omit to act in reliance of this research material or any of its contents.",
            tos_p10: "Research material from Danske Bank A/S is the property of Danske Bank A/S and may not be reproduced or distributed, in whole or in part, without Danske Bank A/S’s prior written consent.",
            form_label_yesPro1: "YES, I confirm that I am a",
            form_label_yesPro2: "Professional Investor",
            form_label_yesPro3: "as defined in MiFID II.",
            form_label_noPro1: "NO, I am not a",
            form_label_noPro2: "Professional Investor.",
            form_label_yesEea: "YES, I confirm that I am domiciled in and a resident of an EEA member state, or the UK.",
            form_label_noEaa: "NO, I am not domiciled in or a resident of an EEA member state, or the UK.",
        },
        cookieconsent: {
            bannerText: "Danske Bank Group uses its own cookies as well as cookies from third parties. Cookies are used to remember your settings, for traffic measurement and to show you targeted ads. By clicking OK, you consent to this use of cookies.",
            rejectText: "If you do not wish to accept the use of cookies, click here.",
            readMoreText: "Read more about our use of cookies.",
            acceptText: "OK",
            tos: {
                heading: "Processing of personal data and cookies",
                subheading: "Processing of personal data and use of cookies on Danske Bank Group’s websites and in Danske Bank Group’s electronic self-service solutions",
                h_1: "How we process your data when you use Danske Bank Group’s websites and digital solutions.",
                p_1: "If you ask to be contacted, send us an email or ask to be sent a brochure or the like via our websites, you provide your name and address, email address, telephone number and other details, including your civil registration number. We use this data to process and respond to your enquiry. We do not store the data in cookies.",
                h_2: "Cookies",
                p_2: "Cookies are passive files which are stored on your computer or other IT equipment. They enable us to recognise your computer and obtain information about the pages visited and functions accessed from your computer/equipment. Cookies do not contain details about, for example, who you are or where you live. Cookies cannot spread viruses or other harmful programs.",
                h_3: "Purpose",
                p_3: "We use cookie data in connection with our ordinary activities and the different solutions we offer. We also use cookies for the compilation of statistics which, for example, can be used to improve our websites and tailor the experience to your needs. We also use some cookies in our marketing.",
                p_4: "Cookies cannot be used to identify you as a person, but we can use the information about the websites visited from your computer together with the other data held about you. We obviously only use the data in accordance with the applicable legislation.",
                p_5: "When you log on to our self-service solutions such as Danske eBanking, we save information about where and when you visited our websites. We use this information to make our advisory services and marketing to you as relevant as possible. We only save the information if you have also consented to the use of cookies. However, please note that session, functionality and operational cookies are placed in any circumstances, as you would otherwise not be able to use our self-service solutions.",
                p_6: "You can read more about the various cookies we use in the section ‘We use various cookies’.",
                h_4: "Consent to the use of cookies, revocation of consent and refusal of cookies",
                p_7: "The first time you use one of our websites or apps, a message will be shown about the use of cookies. You consent to the use of cookies by clicking ‘OK’ or ‘Yes, I accept’. If you do not click ‘OK’ or if you click ‘No thank you’, no consent is given, and no cookies are placed except for those necessary for our websites to function.",
                p_8: "However, we store a cookie to remember your choice, and, in connection with your next visit to the website, no other cookies will be used if you have not chosen ‘OK’ or if you have chosen ‘No thank you’.",
                p_9: "If you wish to revoke your consent or completely avoid cookies, you must disable cookies in your browser. However, you should be aware that disabling cookies means that you cannot log on to our self-service solutions or use other functions which require that the web page in question can remember your choices.",
                h_5: "We use various cookies",
                p_10: "Description of the various types of cookies we use:",
                h_6: "1. Type",
                p_11_1: "Session cookies:",
                p_11_2: "These cookies have a life from the moment you open your browser and until you close it again. When you close your browser, these cookies are automatically erased.",
                p_12_1: "Persistent cookies:",
                p_12_2: "These cookies have a predefined lifetime and remain in your browser until they expire or you delete them.",
                h_7: "2. Scope",
                p_13_1: "Global cookies:",
                p_13_2: "A global cookie is a cookie which can be placed on any page on our websites.",
                p_14_1: "Local cookies:",
                p_14_2: "A local cookie is a cookie which can only be placed on selected pages.",
                h_8: "3. First-party or third-party cookies",
                p_15_1: "First-party cookies:",
                p_15_2: "These are our own cookies.",
                p_16_1: "Third-party cookies:",
                p_16_2: "These are cookies placed by third parties by agreement with us.",
                h_9: "4. Purpose",
                p_17_1: "Statistics:",
                p_17_2: "Adobe Analytics and Celebrus: Danske Bank Group uses Adobe Analytics and Celebrus, which are both tools that can be used for, among other applications, the performance of statistical analyses and profiling.",
                p_18: "Read Adobe Analytics’ privacy statement",
                p_19: "Read Celebrus’ privacy statement",
                p_20_1: "User survey – Qualtrics (QST – third-party cookie):",
                p_20_2: "Danske Bank Group uses the user survey tools Research Suite and Site Intercept from Qualtrics.",
                p_20_3: "Read Qualtrics’ privacy statement",
                p_21_1: "Marketing (third-party cookies):",
                p_21_2: "We use these cookies to track use across several web pages and websites. These cookies can be used to create visit profiles showing the searches carried out or the pages visited. We only share anonymous data with a third party unless otherwise expressly accepted by you.",
                p_22_1: "Content improvement:",
                p_22_2: "We use these cookies to be able to display the content which is most relevant to our visitors.",
                p_23_1: "Chat and help (MV9VSWQx and RUc4MTU3MjcxMl9TSWQx – third-party cookies):",
                p_23_2: "These cookies are placed to enable chats with visitors and to offer relevant help in relation to the pages visited.",
                p_24_1: "Video statistics – Adobe Analytics (demdex – third-party cookie):",
                p_24_2: "We use Adobe Analytics, which is a tool for performing statistical analyses.",
                p_24_3: "Read Adobe Analytics’ privacy statement",
                p_25_1: "Web monitoring:",
                p_25_2: "We use these cookies to ensure that our IT services have a high quality.",
                p_26_1: "E-magazine (ARPT_STATS – third-party cookie):",
                p_26_2: "We use ZMAGS to show interactive PDFs.",
                p_26_3: "Read Zmags’ Cookie Policy",
                p_27: "Facebook, Twitter, LinkedIn (third-party cookies)",
                p_28: "On some of our websites you can link to/use the ‘Like’ button of Facebook, Twitter and LinkedIn. You should be aware that if you do so, Facebook, Twitter and LinkedIn will receive technical data about your browser, IP address and the pages you visit.",
                p_29: "Danske Bank Group uses Facebook re-targeting/pixel, which is, for example, used to perform statistical analyses as well as profiling.",
                p_30: "When you use these services, their cookie rules apply. You can read more about these cookie rules here:",
                h_10: "5. Classification",
                p_31_1: "Cat. 1 – functional:",
                p_31_2: "These cookies are so-called functional cookies which ensure that a given function or a given website can function wholly or partly. They are, for example, used in connection with chat and help as well as our questionnaires.",
                p_32_1: "Cat. 2 – operational:",
                p_32_2: "These cookies are so-called operational cookies, which are placed to ensure the operation of our systems. They are, for example, used to make a website more relevant to the user based on statistics and content improvement.",
                p_33: "These cookies are not covered by your cookies consent, but will be placed regardless of whether you have refused cookies, because they are necessary for the functions of the websites.",
                h_11: "Erasing cookies",
                p_34: "We cannot erase our cookies ourselves once they have been placed in your browser.",
                p_35: "However, all browsers allow you to erase cookies individually or all at once.",
                p_36_1: "The procedure for",
                p_36_2: "erasing cookies",
                p_36_3: "depends on the browser you use, but you can always find help on how to erase cookies in your browser. Remember to erase cookies in all browsers if you use more than one browser.",
            }
        },
    },
    DK: {
        TR1: "All Editions",
        TR2: "All research available",
        TR3: "UK Edition",
        TR4: "All research available in English",
        TR5: "Denmark Edition",
        TR6: "All research available in Danish and English",
        TR7: "Norway Edition",
        TR8: "All research available in Norwegian and English",
        TR9: "Sweden Edition",
        TR10: "All research available in Swedish and English",
        TR11: "Finland Edition",
        TR12: "All research available Finnish and English",
        TR13: "Kontakt os",
        TR14: "Danske Bank Research",
        TR15: "Hovedpunkter",
        TR16: "Søg artikler",
        TR17: "datoformat",
        TR18: "Læs mere",
        TR19: "Nyheder",
        TR20: "Markedsnyt",
        TR21: "Ugefokus",
        TR22: "Vælg sprog",
        TR23: "Seneste analyser",
        TR24: "Vis alle",
        TR25: "Mest læste",
        TR26: "Seneste tweets",
        TR27: "Seneste podcast",
        TR28: "Seneste Youtube-videoer",
        TR29: "Analyse",
        TR30: "Når vi publicerer en ny analyse, modtager du et link direkte på mail. Tryk på abonner nedenfor for at tilmelde dig.",
        TR31: "Abonner på analyser",
        TR32: "DK:Danske Bank footer + disclaimer? Which one to use for Research website",
        TR33: "Denne analyse er kun tilgængelig for kvalificerede investorer*. Bekræft venligst at du er en kvalificeret investor for at få adgang.",
        TR34: "Jeg bekræfter herved, at jeg er en professionel investor",
        TR35: "Bekræft",
        TR36: "Fortryd",
        TR37: "Vælg en eller flere analyser",
        TR38: "Klik her for at abonnere på Kredit/Covered Bonds-analyser",
        TR39: "Fortsæt",
        TR40: "Tak for din bestilling",
        TR41: "Du modtager snart en bekræftelsesmail",
        TR42: "Bekræft abonnement",
        TR43: "Navn",
        TR44: "Oplys navn",
        TR45: "Mailadresse",
        TR46: "Oplys mailadresse",
        TR47: "Selskab",
        TR48: "Oplys selskab",
        TR49: "Din kontakt i Danske Bank",
        TR50: "Oplys kontaktperson i Danske Bank",
        TR51: "Når du sætter flueben i boksen, accepterer ",
        TR52: "Abonner",
        TR53: "Vælg en nyhedskategori",
        TR54: "Makroøkonomi",
        TR55: "Renter",
        TR56: "Valuta",
        TR57: "Råvarer",
        TR58: "click here to subscribe.",
        TR59: "For DCM Research,",
        TR60: "Please note that DCM Research is for certified professional investors only.",
        TR61: "du vores Betingelser",
        TR62: "Nordiske",
        TR63: "International",
        TR64: "Faste publikationer",
        TR65: "Strategi",
        TR66: "Reading the markets",
        TR67: "Afdækning",
        TR68: "Håndbøger",
        TR69: "Syn",
        TR70: "Kredit",
        TR71: "Kreditanalyse (Corporates)",
        TR72: "Kreditanalyse (Finans)",
        TR73: "Covered bonds",
        TR74: "Prognoser",
        TR75: "Danske Daily er vores daglige morgenrapport",
        TR76: "Ugefokus er vores ugentlige rapport, hvor vi gennemgår de vigtige økonomiske begivenheder i den kommende uge",
        // TR77: "Bekræftelse",
        TR77: "Disclaimer",
        TR78: "Analyserne i denne kategori er ikke tilgængelig for retail-investorer",
        TR79: "Forslag:",
        TR80: "Prøv at skrive et andet søgeord i søgefeltet.",
        TR81: "Prøv en anden underkategori i menuen.",
        TR82: "Aktier",
        TR83: "Om",
        TR84: "Om Danske Bank Research",
        TR85: "Ofte stillede spørgsmål (FAQ)",
        TR86: "Vælg en eller flere kategorier og vælg relevant analyse.",
        TR87: "Ok",
        TR88: "Youtube video",
        TR89: "Makroøkonomi",
        TR90: "Nordisk",
        TR91: "Danmark",
        TR92: "Sverige",
        TR93: "Norge",
        TR94: "Finland",
        TR95: "Nordic Outlook",
        TR96: "International",
        TR97: "Euroområdet",
        TR98: "USA",
        TR99: "Storbritannien",
        TR100: "Kina",
        TR101: "Japan",
        TR102: "Emerging Markets",
        TR103: "The Big Picture",
        TR104: "Faste publikationer",
        TR105: "Markedsnyt",
        TR106: "Ugefokus",
        TR107: "Nøgletalskalender",
        TR108: "Scandi Markets Ahead",
        TR109: "Strategi",
        TR110: "Renter",
        TR111: "Faste publikationer",
        TR112: "Government Bonds Weekly",
        TR113: "Realkredit",
        TR114: "EUR Covered Bonds Weekly",
        TR115: "Renteudsigter",
        TR116: "Scandi Markets Ahead",
        TR117: "Danish Mortgage Bonds key figures",
        TR119: "Strategi",
        TR120: "Rentestrategi",
        TR121: "Reading the Markets",
        TR122: "RtM Danmark",
        TR123: "RtM Norway",
        TR124: "RtM Sweden",
        TR125: "Corporate Hedging",
        TR126: "Markedsguiden",
        TR127: "Renteafdækning",
        TR128: "Håndbøger",
        TR129: "Danish Covered Bond Handbook",
        TR130: "Nordic Covered Bond Handbook",
        TR131: "Valuta",
        TR132: "Faste publikationer",
        TR133: "FX Forecast Update",
        TR134: "IMM Positioning Update",
        TR136: "Danske Technical Update",
        TR137: "FX Top Trades",
        TR138: "Syn",
        TR139: "FX Strategy",
        TR140: "FX Edge",
        TR141: "FX Trading Portfolio",
        TR142: "Reading the Markets",
        TR143: "RtM Danmark",
        TR144: "RtM Norway",
        TR145: "RtM Sweden",
        TR146: "Corporate Hedging",
        TR147: "Markedsguiden",
        TR148: "Valutaafdækning",
        TR149: "Kredit",
        TR150: "Faste publikationer",
        TR151: "Håndbøger",
        TR152: "Credit Weekly",
        TR153: "Kreditanalyse (Corporates)",
        TR155: "Consumer goods",
        TR156: "Energy",
        TR157: "Industrials",
        TR158: "Healthcare",
        TR159: "Materials",
        TR160: "Real estate",
        TR161: "Services",
        TR162: "Shipping & Transportation",
        TR163: "TMT",
        TR165: "Utilities",
        TR166: "Kreditanalyse (Finans)",
        TR168: "Financials",
        TR169: "Covered Bond Update",
        TR170: "Covered bonds",
        TR171: "Prognoser",
        TR172: "Makroøkonomi",
        TR173: "Makroprognose",
        TR174: "Kalender",
        TR175: "Finansprognose",
        TR176: "mod EUR",
        TR177: "mod SEK",
        TR178: "mod DKK",
        TR179: "mod NOK",
        TR180: "Valutaprognose",
        TR181: "EUR",
        TR182: "USD",
        TR183: "DKK",
        TR184: "SEK",
        TR185: "NOK",
        TR186: "Aktier ",
        TR187: "Ny analysedækning",
        TR188: "Bestilte analyser (Commissioned Research)",
        TR189: "Syn",
        TR190: "Log in to Danske Bank Equities",
        TR191: "Accepter venligst alle vilkår og betingelser",
        TR192: "DCM Research",
        TR193: "Kontakt din kunderådgiver i Danske Bank for at abonnere på DCM Research",
        TR194: "Tilbage",
        TR195: "Se på YouTube",
        TR196: "Forrige",
        TR197: "Gå til analyse",
        TR198: "Om Danske Bank Research",
        TR199: "Danske Bank Research er Danske Banks analyseafdeling. Vi har dedikerede teams, som dækker en lang række markeder og økonomier:",
        TR200: "Makroøkonomi og strategi",
        TR201: "økonomiske analyser, markedsundersøgelser og -prognoser, som dækker Norden og Europa, USA, Asien og vækstmarkederne.",
        TR202: "Rentemarkederne",
        TR203: "Analyser, som dækker skandinaviske og euroområdets statsobligationer samt det nordiske marked for særligt dækkede obligationer med indgående fokus på danske realkreditobligationer. Afdelingen dækker også globale renter og derivatmarkederne.",
        TR204: "Valuta",
        TR205: "professionelle analyser, prognoser og handelsanbefalinger på de globale valutamarkeder med særligt fokus på G10-landenes og skandinaviske valutaer.",
        TR206: "Råvarer",
        TR207: "Dybdegående analyser om og prognoser for råvarer, der handles på børser.",
        TR208: "Kredit",
        TR209: "Opdateringer og analyser med særligt fokus på Skandinavien. Vores periodiske analyser dækker et bredt perspektiv på kreditmarkederne, og vores selskabsanalyser omfatter udstederprofiler og opdateringer om skandinaviske udstedere, herunder dækning og anbefalinger om virksomhedsobligationer og credit default swaps.",
        TR210: "Aktier",
        TR211: "Analyser på en bred vifte af børsnoterede nordiske virksomheder med en blanding af omfattende lokal viden, markedserfaring og grundlæggende ekspertise. ",
        TR212: "Bestilte analyser",
        TR213: "Er selskabsanalyser på mindre børsnoterede virksomheder, som på grund af virksomhedens størrelse og likviditet ikke indgår i Danske Banks aktieanalyseunivers. Sådanne analyser er bestilt af det pågældende selskab og indeholder ingen anbefalinger eller targetkurser. Alle bestilte analyser er offentligt tilgængelige.",
        TR214: "Danske Bank Equity Research ",
        TR215: "Er en platform indeholdende alle de aktieanalyser, der produceres af Danske Bank, herunder selskabsnotater, sektorkommentarer og strateginotater samt adgang til vores omfattende database med grundlæggende data og værdiansættelsestal. Adgang til hjemmesiden kræver status som betalende kunde. Kontakt din kunderådgiver hos Danske Bank for yderligere oplysninger.",
        TR216: "Publikationer",
        TR217: "På denne hjemmeside kan du læse vores makroøkonomiske analyser samt vores forskellige prognoser. Vi udarbejder også analyser rettet udelukkende mod professionelle investorer.",
        TR218: "Hvis du har spørgsmål eller kommentarer, kontakt venligst Danske Bank Kundesupport på",
        TR219: "eller send en mail til",
        TR220: "Seneste analyser",
        TR221: "I dag",
        TR222: "Præsentationer",
        TR223: "Alle præsentationer",
        TR224: "Faste publikationer",
        TR225: "Other",
        TR226: "Se alt {0}",
        TR227: "Inflationsprognose",
        TR228: "By clicking “subscribe” below you consent to receiving our research emails and our tracking of your opening of the mails and the attached documents for the purpose of obtaining updated subscribers’ information as well as measuring interest on the subjects of our research. You may always withdraw your consent by unsubscribing to our research emails or by writing to us to researchsubscriptions@danskebank.com. We keep this information for the period of your subscription. You may see more on how we process your personal data by pressing the link below.",
        TR229: "Scandi Vol Monitor",
        TR230: "GomSpace",
        TR231: "Podcasts",
        TR232: "Lyt på SoundCloud",
        TR233: "Lyt på iTunes",
        TR234: "Lyt",
        TR235: "Zutec",
        TR236: "Denne analyse er udelukkende egnet for og rettet mod institutionelle og professionelle brugere som defineret i MiFID II, som er hjemmehørende og bosiddende i et EØS medlemsland.",
        TR237: "Klik her for at komme til website-disclaimeren, hvor du kan se din tidligere erklærede investorstatus.",
        TR238: "Outlooks",
        TR239: "Denne analyse er ikke længere tilgængelig",
        TR240: "GBP",
        TR241: "Tikkurila",
        TR242: "Senzime",
        TR244: "Consti",
        TR245: "Aktia",
        TR246: "Glaston",
        TR247: "Taaleri",
        TR248: "Talenom",
        TR249: "Danske Bank Commissioned Research",
        TR250: "Companies",
        TR251: "Other Danske Bank Research",
        TR252: "Home",
        TR253: "Se alt Makroøkonomi ESG/Klima",
        TR254: "Se alt Renter ESG/Klima",
        TR255: "Se alt Kredit ESG/Klima",
        TR256: "ESG/Klima",
        TR257: "Ikke-nordisk kreditforskning",
        TR259: "Other Comments",
        TR260: "Krigen i Ukraine",
        TR261: "Updates",
        TR262: "læsser",
        disclaimer: {
            tos_p1: "Analyserne på denne internetside er alene til orientering. De udgør ikke et tilbud om eller en opfordring til at købe eller sælge finansielle instrumenter. Analyserne udgør ikke investeringsrådgivning i form af en personlig anbefaling til læseren.",
            tos_p2: "Selvom der er udvist rimelig omhu for at sikre, at indholdet ikke er usandt eller vildledende, fremsættes ingen erklæring om analysernes korrekthed, nøjagtighed eller fuldstændighed, og Danske Bank påtager sig intet ansvar for direkte eller indirekte tab i forbindelse med dispositioner foretaget eller undladt i tillid til analyserne.",
            tos_p3: "Danske Bank, dens tilknyttede virksomheder eller medarbejdere kan udføre services for eller indgå i forretninger med eller søge at indhente forretninger fra og kan have lange eller korte positioner i eller på anden måde have interesser i investeringer (herunder derivater) foretaget af nogen udsteder, der er nævnt i analyserne på denne internetside.",
            tos_p4: "Analyserne er baseret på oplysninger, som vi betragter som pålidelige, men Danske Bank A/S har ikke uafhængigt verificeret indholdet af dokumenterne. De meninger, skøn og forventninger, der udtrykkes i dokumenterne, gælder pr. datoen herfor og kan ændres uden varsel. Der afgives ingen erklæring eller garanti, hverken udtrykkeligt eller underforstået, om rimeligheden, nøjagtigheden, fuldstændigheden eller rigtigheden af de oplysninger, meninger og forventninger, der er indeholdt i dette dokument, og der bør ikke foretages dispositioner på baggrund deraf. Danske Bank A/S påtager sig ikke noget ansvar for tab, uanset hvordan det opstår, ved brug af dokumenterne på denne internetside eller indholdet heraf eller i øvrigt i forbindelse dermed.",
            tos_p5_1: "En del af analyserne på denne internetside er udelukkende egnet for og rettet mod institutionelle og professionelle brugere",
            tos_p5_2: "(“Professionelle Investorer”)",
            tos_p5_3: "og er ikke egnet for eller rettet mod retailinvestorer.",
            tos_p6: "Enhver person, som får tilgang til disse analyser på denne internetside ved at svare ”Ja” (bekræfte), at personen er en Professionel Investor, anses for at have tilkendegivet og accepteret, at vedkommende er en Professionel Investor som defineret i MiFID II (Europa-Parlamentets og Rådets Direktiv 2014/65/EU om markeder for finansielle instrumenter) eller i MiFiD II som onshored i Storbritannien.",
            tos_p7: "Disse analyser er ikke målrettet og må ikke videreformidles til personer uden for det Europæiske Økonomiske Samarbejdsområde (EØS) eller Storbritannien.",
            tos_p8: "Enhver person, som får tilgang til analyserne på denne internetside ved at svare ”Ja” (bekræfte), at denne er hjemmehørende og bosiddende i et EØS medlemsland eller i Storbritannien, anses for at have tilkendegivet og accepteret, at vedkommende er hjemmehørende og bosiddende i et EØS medlemsland eller i Storbritannien.",
            tos_p9: "Danske Bank A/S handler i tillid til, at svaret er sandt og nøjagtigt. Enhver person, der ikke er en Professionel Investor, må ikke disponere eller undlade at disponere ud fra eller i tillid til analyserne eller indholdet heraf.",
            tos_p10: "Analyser fra Danske Bank A/S tilhører Danske Bank A/S og må ikke gengives eller distribueres helt eller delvist uden mod Danske Bank A/S’ forudgående skriftlige samtykke.",
            form_label_yesPro1: "JA, jeg bekræfter, at jeg er en",
            form_label_yesPro2: "Professionel Investor",
            form_label_yesPro3: "som defineret i MiFID II.",
            form_label_noPro1: "Nej, jeg er ikke en",
            form_label_noPro2: "Professionel Investor.",
            form_label_yesEea: "JA, jeg er hjemmehørende og bosiddende i et EØS medlemsland eller i Storbritannien.",
            form_label_noEaa: "Nej, jeg er ikke hjemmehørende og bosiddende i et EØS medlemsland eller i Storbritannien.",
        },
        cookieconsent: {
            bannerText: "Danske Bank koncernen bruger egne cookies samt cookies fra tredjeparter. Cookies bruges til at huske dine indstillinger, trafikmåling og til at vise målrettede annoncer til dig. Ved at klikke OK giver du samtykke til denne brug af cookies.",
            rejectText: "Ønsker du ikke at acceptere brugen af cookies, så klik her.",
            readMoreText: "Læs mere om vores brug af cookies.",
            acceptText: "OK",
            tos: {
                heading: "Behandling af personoplysninger og cookies",
                subheading: "Behandling af personoplysninger og brug af cookies på Danske Bank koncernens websites og i Danske Bank koncernens elektroniske selvbetjeningsløsninger",
                h_1: "Sådan behandler vi dine oplysninger, når du benytter Danske Bank koncernens websites og digitale løsninger.",
                p_1: "Hvis du beder om at blive kontaktet, sender os en mail eller beder om at få tilsendt brochurer eller lignende via vores websites, oplyser du navn og adresse, mailadresse, telefonnummer og andre oplysninger, herunder CPR-nummer. Vi bruger disse oplysninger til at behandle og besvare din henvendelse. Vi gemmer ikke oplysningerne i cookies.",
                h_2: "Cookies",
                p_2: "Cookies er passive filer, som lægges på din computer eller andet it-udstyr. De gør det muligt for os at genkende din computer og få oplysninger om, hvilke sider der besøges og funktioner der bruges fra din computer/dit udstyr. Cookies indeholder ikke oplysninger om, hvem du er, eller hvor du fx bor. Cookies kan ikke sprede virus eller andre skadelige programmer.",
                h_3: "Formål",
                p_3: "Vi bruger cookie-oplysninger i forbindelse med den ordinære drift og de forskellige løsninger, vi udbyder. Desuden bruger vi cookies til udarbejdelse af statistik, der bl.a. kan anvendes til at forbedre vores websites og tilpasse oplevelsen til dine behov. Vi bruger også enkelte cookies til vores markedsføring.",
                p_4: "Cookies kan ikke bruges til at identificere dig som person, men vi kan bruge oplysningerne om, hvilke websites der er besøgt fra din computer, sammen med de øvrige oplysninger, vi har om dig. Vi bruger naturligvis kun oplysningerne i henhold til gældende lovgivning.",
                p_5: "Når du logger ind på vores selvbetjeningsløsninger, fx netbank, gemmer vi oplysninger om, hvor og hvornår du har besøgt vores websites. Vi bruger disse oplysninger til at gøre vores rådgivning og markedsføring til dig så relevant som muligt. Vi gemmer kun oplysningerne, hvis du også har givet samtykke til anvendelse af cookies. Bemærk dog, at session-, funktionalitets- og driftcookies sættes under alle omstændigheder, idet du ellers ikke kan benytte vores selvbetjeningsløsninger.",
                p_6: "Du kan læse mere om de forskellige cookies, vi bruger, i afsnittet ”Vi bruger forskellige cookies”.",
                h_4: "Samtykke til anvendelse af cookies, tilbagekaldelse af samtykke og afvisning af cookies",
                p_7: "Første gang du benytter en af vores websites eller apps, vises en meddelelse om anvendelse af cookies. Du giver samtykke til brug af cookies ved at klikke ”OK” eller ”Ja, jeg accepterer”. Hvis du undlader at klikke ”OK” eller klikker ”Nej tak” gives der ikke samtykke, og der sættes ingen cookies, bortset fra de funktionsnødvendige cookies.",
                p_8: "Vi gemmer dog en cookie for at huske dit valg, og der vil ved dit næste besøg på siden ikke blive anvendt andre cookies, hvis du har undladt at klikke ”OK” eller har sagt ”Nej tak”.",
                p_9: "Ønsker du at tilbagekalde dit samtykke eller helt at undgå cookies, skal du slå cookies fra i din browser. Du skal dog være opmærksom på, at du ved at slå cookies fra ikke kan logge på vores selvbetjeningsløsninger eller bruge andre funktioner, som forudsætter, at den pågældende side kan huske dine valg.",
                h_5: "Vi bruger forskellige cookies",
                p_10: "Beskrivelse af forskellige typer cookies, som vi bruger:",
                h_6: "1. Type",
                p_11_1: "Session cookies:",
                p_11_2: "Disse cookies har en levetid fra det tidspunkt, du åbner browseren, og til det tidspunkt, du lukker den igen. Når du lukker browseren, slettes disse cookies automatisk.",
                p_12_1: "Vedvarende eller ”persistent” cookies:",
                p_12_2: "Disse cookies har en foruddefineret levetid og forbliver i browseren, indtil de udløber, eller indtil du sletter dem.",
                h_7: "2. Virkefelt",
                p_13_1: "Globale cookies:",
                p_13_2: "En global cookie er en cookie, der kan sættes på en hvilken som helst side på vores websites.",
                p_14_1: "Lokale cookies:",
                p_14_2: "En lokal cookie er en cookie, der kun sættes på en udvalgte sider.",
                h_8: "3. Egne eller 3. parters cookies",
                p_15_1: "1. parts cookies:",
                p_15_2: "Det er vores egne cookies.",
                p_16_1: "3. parts cookies:",
                p_16_2: "Det er cookies der sættes af 3. parter efter aftale med os.",
                h_9: "4. Formål",
                p_17_1: "Statistik - Adobe Analytics og Celebrus:",
                p_17_2: "Danske Bank koncernen anvender Adobe Analytics og Celebrus, som begge er værktøjer, der bl.a. anvendes til at udføre statistiske analyser samt profilering.",
                p_18: "Læs Adobe Analytics’ privacy statement",
                p_19: "Læs Celebrus pricacy statement",
                p_20_1: "Brugerundersøgelse - Qualtrics (QST – 3. parts-cookie):",
                p_20_2: "Danske Bank koncernen anvender Research Suite og Site Intercept fra Qualtrics, som er brugerundersøgelsesværktøjer.",
                p_20_3: "Læs Qualtrics’ privacy statement",
                p_21_1: "Markedsføring (3. parts-cookies):",
                p_21_2: "Vi anvender disse cookies til at følge brug på tværs af flere sider og websites. Disse cookies kan blive brugt til at opbygge besøgsprofiler, der viser, hvilke søgninger der er foretaget, eller hvilke sider der er besøgt. Vi deler kun anonyme data med 3. part, med mindre du har specifikt accepter andet.",
                p_22_1: "Indholdsforbedring:",
                p_22_2: "Vi anvender disse cookies til at kunne vise det indhold, der er mest relevant for vores besøgende.",
                p_23_1: "Chat og hjælp (MV9VSWQx og RUc4MTU3MjcxMl9TSWQx – 3. parts-cookies):",
                p_23_2: "Disse cookies sættes for at muliggøre chat-samtaler med besøgende og for at tilbyde relevant hjælp i forhold til de sider, der besøges.",
                p_24_1: "Video statistik - Adobe Analytics (demdex – 3. parts-cookie):",
                p_24_2: "Vi anvender Adobe Analytics, som er et værktøj, der anvendes til at udføre statistiske analyser.",
                p_24_3: "Læs Adobe Analytics’ privacy statement",
                p_25_1: "Web monitorering:",
                p_25_2: "Vi bruger disse cookies til at sikre en høj kvalitet i vores it-tjenester.",
                p_26_1: "E-magasin (ARPT_STATS – 3. parts-cookie):",
                p_26_2: "Vi Bank anvender ZMAGS til at vise interaktive PDF´er.",
                p_26_3: "Læs Zmags Cookie Policy",
                p_27: "Facebook, Twitter, LinkedIn (3. parts-cookies)",
                p_28: "På enkelte af vores websites kan du linke til/benytte Like-knap til Facebook, Twitter og LinkedIn. Du skal være opmærksom på, at, hvis du gør det, modtager Facebook, Twitter og LinkedIn tekniske data om din browser, IP-adresse, og hvilke sider du besøger.",
                p_29: "Danske Bank koncernen anvender Facebook re-targeting/pixel, der b.la. anvendes til at udføre statistiske analyser samt profilering.",
                p_30: "Når du benytter disse tjenester, er det deres cookie-regler, der gælder. Du kan læse mere om disse cookie-regler her:",
                h_10: "5. Klassifikation",
                p_31_1: "Kat. 1 – funktionsnødvendig:",
                p_31_2: "Disse cookies er såkaldte funktionsnødvendige cookies, som gør, at en given funktion eller et givent website kan fungere helt eller delvist. De bruges fx i forbindelse med chat og hjælp og vores spørgeskemaer.",
                p_32_1: "Kat. 2 – drift:",
                p_32_2: "Disse cookies er såkaldte driftscookies, der sættes for at sikre driften af vores systemer. De bruges fx til at gøre et webside mere relevante for brugeren på baggrund af statistik og indholdsforbedring.",
                p_33: "Disse cookies er ikke omfattet af dit cookie samtykke, men bliver sat uanset du har sagt ”nej” til cookies, fordi de er nødvendige for  funktionerne på hjemmesiden.",
                h_11: "Sletning af cookies",
                p_34: "Vi kan ikke selv slette vores cookies, når de først er sat i din browser.",
                p_35: "Alle browsere giver dig imidlertid mulighed for at slette cookies enkeltvis eller alle på en gang.",
                p_36_1: "Fremgangsmåden for",
                p_36_2: "sletning af cookies",
                p_36_3: "afhænger af, hvilken browser du anvender, men du kan altid finde hjælp til sletning af cookies i browseren. Husk at slette cookies i alle browsere, hvis du bruger flere forskellige.",
            }
        },
    },
    SE: {
        TR1: "All Editions",
        TR2: "All research available",
        TR3: "UK Edition",
        TR4: "All research available in English",
        TR5: "Denmark Edition",
        TR6: "All research available in Danish and English",
        TR7: "Norway Edition",
        TR8: "All research available in Norwegian and English",
        TR9: "Sweden Edition",
        TR10: "All research available in Swedish and English",
        TR11: "Finland Edition",
        TR12: "All research available Finnish and English",
        TR13: "Kontakta oss",
        TR14: "Danske Bank Analys",
        TR15: "Huvudpunkter",
        TR16: "sök efter artiklar",
        TR17: "Datumformat",
        TR18: "Läs mer",
        TR19: "Nyheter",
        TR20: "Morgonrapport",
        TR21: "Weekly Focus Sweden",
        TR22: "Välj språk",
        TR23: "Senaste analyser",
        TR24: "Visa allt",
        TR25: "Mest läst",
        TR26: "Senaste tweets",
        TR27: "Senaste podcasts",
        TR28: "Senaste Youtube-klipp",
        TR29: "Analysabonnemang",
        TR30: "När vi publicerar en ny analys mottager du en sammanfattning och länk till analysen direkt på mail. Välj Prenumerera nedan för att anmäla dig.",
        TR31: "Prenumerera",
        TR32: "SE:Danske Bank footer + disclaimer? Which one to use for Research website",
        TR33: "Denne analyse er kun tilgængelig for kvalificerede investorer*. Bekræft venligst at du er en kvalificeret investor for at få adgang.",
        TR33: "Denna analys är tillgänglig fär kvalificerade investerare*. Vänligen bekräfta att du är en kvalificerad investor för att få åtkomst.",
        TR34: "Jag bekräftar härmed att jag är en kvalificerad investerare.",
        TR35: "Bekräfta",
        TR36: "Avbryt",
        TR37: "Välj en eller flera analyser att prenumerera på",
        TR38: "Klicka här för att prenumerera på DCM research",
        TR39: "Fortsätt",
        TR40: "Tack för att du prenumererar på vår analys!",
        TR41: "Du mottager inom kort en bekräftelse per e-post",
        TR42: "Bekräfta prenumeration",
        TR43: "Namn",
        TR44: "Ange ditt namn",
        TR45: "E-post",
        TR46: "Ange din e-post",
        TR47: "Företag",
        TR48: "Ange ditt företagsnamn",
        TR49: "Kontaktperson i Danske Bank",
        TR50: "Ange ev. kontaktperson i Danske Bank",
        TR51: "Jag har förstått och ",
        TR52: "Prenumerera",
        TR53: "Välj en nyhetskategori",
        TR54: "Makroekonomi",
        TR55: "Räntor",
        TR56: "Valuta",
        TR57: "Hyödykkeet",
        TR58: "Välj en nyhetskategori",
        TR59: "For DCM Research,",
        TR60: "Please note that DCM Research is for certified professional investors only.",
        TR61: "godkänner villkoren",
        TR62: "Norden",
        TR63: "Internationellt",
        TR64: "Fasta publikationer",
        TR65: "Strategi",
        TR66: "Reading the markets",
        TR67: "Hedging",
        TR68: "Handböcker",
        TR69: "Strategi",
        TR70: "Kredit",
        TR71: "Kreditanalys (Corporates)",
        TR72: "Kreditanalys (finans)",
        TR73: "Covered bonds",
        TR74: "Prognoser",
        TR75: "Danske Daily är vår dagliga morgonrapport",
        TR76: "Weekly Focus är vår veckorapport som går igenom viktiga ekonomiska händelser under den kommande veckan",
        // TR77: "Konfirmering",
        TR77: "Disclaimer",
        TR78: "Denna analyskategori är inte tillgänglig för privatpersoner",
        TR79: "Förslag:",
        TR80: "Prova ett annat sökord i sökfältet",
        TR81: "Prova en annan underkategori i menyn",
        TR82: "Aktier",
        TR83: "Om",
        TR84: "Om Danske Bank Analys",
        TR85: "Vanliga frågor (FAQ)",
        TR86: "Välj en eller flera kategorier och välg relevant analys",
        TR87: "OK",
        TR88: "Youtube Video",
        TR89: "Makroekonomi",
        TR90: "Norden",
        TR91: "Danmark",
        TR92: "Sverige",
        TR93: "Norge",
        TR94: "Finland",
        TR95: "Nordic Outlook",
        TR96: "Internationellt",
        TR97: "Euroområdet",
        TR98: "USA",
        TR99: "Storbritannien",
        TR100: "Kina",
        TR101: "Japan",
        TR102: "Emerging Markets",
        TR103: "The Big Picture",
        TR104: "Fasta publikationer",
        TR105: "Morgonrapport",
        TR106: "Weekly Focus Sweden",
        TR107: "Nyckeltalskalender",
        TR108: "Scandi Markets Ahead",
        TR109: "Strategi",
        TR110: "Räntor",
        TR111: "Fasta publikationer",
        TR112: "Government Bonds Weekly",
        TR113: "Danish Mortgage Bonds",
        TR114: "EUR Covered Bonds Weekly",
        TR115: "Yield Outlook",
        TR116: "Scandi Markets Ahead",
        TR117: "Danish Mortgage Bonds key figures",
        TR119: "Strategi",
        TR120: "Räntestrategi",
        TR121: "Reading the Markets",
        TR122: "RtM Denmark",
        TR123: "RtM Norway",
        TR124: "RtM Sweden",
        TR125: "Corporate Hedging",
        TR126: "Marknadsguiden",
        TR127: "Räntesäkring",
        TR128: "Handböcker",
        TR129: "Danish Covered Bond Handbook",
        TR130: "Nordic Covered Bond Handbook",
        TR131: "Valuta",
        TR132: "Fasta publikationer",
        TR133: "FX Forecast Update",
        TR134: "IMM Positioning Update",
        TR136: "Danske Technical Update",
        TR137: "FX Top Trades",
        TR138: "Syn",
        TR139: "FX Strategy",
        TR140: "FX Edge",
        TR141: "FX Trading Portfolio",
        TR142: "Reading the Markets",
        TR143: "RtM Denmark",
        TR144: "RtM Norway",
        TR145: "RtM Sweden",
        TR146: "Corporate Hedging",
        TR147: "Marknadsguiden",
        TR148: "Valutasäkring",
        TR149: "Kredit",
        TR150: "Fasta publikationer",
        TR151: "Handböcker",
        TR152: "Credit Weekly",
        TR153: "Kreditanalys (Corporates)",
        TR155: "Consumer goods",
        TR156: "Energy",
        TR157: "Industrials",
        TR158: "Healthcare",
        TR159: "Materials",
        TR160: "Real estate",
        TR161: "Services",
        TR162: "Shipping & Transportation",
        TR163: "TMT",
        TR165: "Utilities",
        TR166: "Kreditanalys (finans)",
        TR168: "Financials",
        TR169: "Covered Bond Update",
        TR170: "Covered bonds",
        TR171: "Prognoser",
        TR172: "Makroekonomi",
        TR173: "Makroprognos",
        TR174: "Kalender",
        TR175: "Finansiell prognos",
        TR176: "mot EUR",
        TR177: "mot SEK",
        TR178: "mot DKK",
        TR179: "mot NOK",
        TR180: "Valutaprognos",
        TR181: "EUR",
        TR182: "USD",
        TR183: "DKK",
        TR184: "SEK",
        TR185: "NOK",
        TR186: "Aktier",
        TR187: "Ny analystäckning",
        TR188: "Beställda analyser Commissioned Research",
        TR189: "Syn",
        TR190: "Log in to Danske Bank Equities",
        TR191: "Vänligen acceptera villkoren",
        TR192: "DCM Research",
        TR193: "För att prenumerera på DCM-analys, vänligen kontakta din kundrådgivare",
        TR194: "Tillbaka",
        TR195: "Titta på You Tube",
        TR196: "Föregående",
        TR197: "Gå till analys",
        TR198: "Om Danske Bank Analys",
        TR199: "Danske Bank Research är Danske Banks analysavdelning. Vi har specialiserade team som bevakar ett brett spektrum av marknader och ekonomier:",
        TR200: "Makroekonomi och strategi ",
        TR201: "Ekonomiska analyser, marknadsöversikter och prognoser omfattande de nordiska länderna samt Europa, USA, Asien och tillväxtmarknader.",
        TR202: "Räntemarknad",
        TR203: "Analyser av statsobligationer från Skandinavien, eurozonen och de nordiska marknaderna för säkerställda obligationer, med djupgående fokus på danska bostadsobligationer. Avdelningen bevakar även de globala ränte- och derivatmarknaderna.",
        TR204: "Valuta",
        TR205: "professionella analyser, prognoser och handelsrekommendationer för de globala valutamarknaderna, med särskilt fokus på G10 och de skandinaviska valutorna",
        TR206: "Råvaror",
        TR207: "djupgående analyser och prognoser för börshandlade råvaror.",
        TR208: "Kreditmarknad",
        TR209: "uppdateringar och analyser med särskilt fokus på Skandinavien. Våra periodiska analyser ger ett top-down perspektiv på kreditmarknaderna, medan våra företagsanalyser inkluderar profiler och uppdateringar rörande skandinaviska emittenter, inklusive bevakning och rekommendationer avseende företagsobligationer och kreditswappar",
        TR210: "Aktier",
        TR211: "analyser av ett brett spektrum av nordiska noterade företag med en mix av stor lokalkännedom, marknadserfarenhet och fundamental expertis. ",
        TR212: "Beställda analyser",
        TR213: "är företagsanalyser av mindre noterade företag som på grund av sin storlek och handlade volym inte ingår i det spektrum som normalt analyseras av Danske Bank. Dessa analyser är beställda av de företag som är föremål för analyserna och innehåller inga rekommendationer eller målkurser. Alla beställda analyser är tillgängliga för allmänheten. ",
        TR214: "Danske Bank Equity Research ",
        TR215: "är en plattform som ger tillgång till alla aktieanalyser som Danske Bank, bland annat företagsuppgifter, branschkommentarer och strategiuppdateringar, liksom tillgång till vår omfattande databas med fundamentala data och värderingsunderlag. Webbplatsen är endast tillgänglig för betalande kunder. Kontakta din kundansvarige på Danske Bank för mer information.",
        TR216: "Publikationer",
        TR217: "Du kan läsa våra makroekonomiska analyser och våra olika prognoser på den här webbplatsen. Vi utarbetar även analyser som uteslutande riktar sig till professionella investerare.",
        TR218: "Om du har frågor eller synpunkter, vänligen kontakta Danske Bank kundsupport på ",
        TR219: "eller skicka ett mail till",
        TR220: "Senaste analyser",
        TR221: "Idag",
        TR222: "Presentationer",
        TR223: "Alla presentationer",
        TR224: "Fasta publikationer",
        TR225: "Other",
        TR226: "Se alla {0}",
        TR227: "Inflationsprognos",
        TR228: "By clicking “subscribe” below you consent to receiving our research emails and our tracking of your opening of the mails and the attached documents for the purpose of obtaining updated subscribers’ information as well as measuring interest on the subjects of our research. You may always withdraw your consent by unsubscribing to our research emails or by writing to us to researchsubscriptions@danskebank.com. We keep this information for the period of your subscription. You may see more on how we process your personal data by pressing the link below.",
        TR229: "Scandi Vol Monitor",
        TR230: "GomSpace",
        TR231: "Podcasts",
        TR232: "Lyssna på SoundCloud",
        TR233: "Lyssna på iTunes",
        TR234: "Lyssna",
        TR235: "Zutec",
        TR236: "Denna analys är enbart tillgänglig för institutionella- och professionella investerare enligt definitionerna i MiFID II och som är hemmahörande och bosatta i en EEA-medlemsstat.",
        TR237: "Klicka här för att komma till hemsidans disclaimer om du vill se hur du tidigare uppgivit din investerarstatus",
        TR238: "Utsikter",
        TR239: "Denna analys är inte längre tillgänglig",
        TR240: "GBP",
        TR241: "Tikkurila",
        TR242: "Senzime",
        TR244: "Consti",
        TR245: "Aktia",
        TR246: "Glaston",    
        TR247: "Taaleri",    
        TR248: "Talenom",
        TR249: "Danske Bank Commissioned Research",
        TR250: "Companies",
        TR251: "Other Danske Bank Research",
        TR252: "Home",
        TR253: "Se alla Makroekonomi ESG/Klimat",
        TR254: "Se alla Räntor ESG/Klimat",
        TR255: "Se alla Kredit ESG/Klimat",
        TR256: "ESG/Klimat",
        TR257: "Icke-nordisk kreditforskning",
        TR259: "Other Comments",
        TR260: "Kriget i Ukraina",
        TR261: "Updates",
        TR262: "læsser",
        disclaimer: {
            tos_p1: "Analysmaterialet på denna webbplats tillhandahålls uteslutande i informationssyfte. Det utgör inte ett erbjudande att köpa eller sälja finansiella instrument. Analysmaterialet utgör inte investeringsrådgivning i form av en personlig rekommendation till läsaren.",
            tos_p2: "Även om skäliga åtgärder vidtagits för att säkerställa att webbplatsens innehåll inte är oriktigt eller missvisande lämnas inga garantier för dess riktighet eller fullständighet och Danske Bank påtar sig inte något som helst ansvar för förlust som uppkommit genom att någon förlitat sig på webbplatsens innehåll.",
            tos_p3: "Danske Bank A/S, dess koncernbolag och anställda kan utföra eller erbjuda tjänster till, inneha långa eller korta positioner i eller på annat sätt ha ett intresse av investeringar (inklusive derivat) från en emittent som omnämns i analyser som är tillgängliga på denna webbplats.",
            tos_p4: "Analyserna bygger på information som enligt vår bedömning är tillförlitlig, men Danske Bank A/S har inte gjort någon oberoende kontroll av innehållet. De åsikter, uppskattningar och prognoser som framförs i analyserna gäller på publiceringsdagen och kan förändras. Danske Bank A/S lämnar ingen uttrycklig eller underförstådd garanti för att information, åsikter eller prognoser som framförs i analyserna är rättvisande, riktiga, kompletta eller rimliga och råder ingen att förlita sig på dessa. Danske Bank A/S påtar sig inte något som helst ansvar för eventuell förlust som kan uppkomma genom användning av analyserna eller deras innehåll eller på annat sätt i anslutning till dessa.",
            tos_p5_1: "Analyserna på denna webbplats är endast lämpliga för och riktar sig till institutionella och professionella kunder",
            tos_p5_2: "(”Professionella Investerare”)",
            tos_p5_3: "och är inte lämpliga eller avsedda för privatpersoner.",
            tos_p6: "Varje person som får tillgång till analyserna på denna webbplats genom att klicka ”Ja” (bekräfta) att de är Professionella Investerare, anses ha försäkrat och bekräftat att denne är en Professionell Investerare i den mening som avses i definitionen i MiFID II (Europeiska parlamentets och Rådets direktiv 2014/65/EU om marknader för finansiella instrument), eller i det brittiska ”onshored” MiFID II",
            tos_p7: "Analyserna är inte riktade till och får inte vidaredistribueras till privatpersoner utanför Europeiska Ekonomiska Samarbetsområdet (EES), eller Storbritannien.",
            tos_p8: "Varje person som får tillgång till analyserna genom att klicka ”Ja” (bekräftar) att denne är bosatt och har hemvist inom en medlemsstat i EES eller i Storbritannien ska anses ha försäkrat och bekräftat att denne är bosatt och har hemvist inom en medlemsstat i EES eller i Storbritannien .",
            tos_p9: "Danske Bank A/S förlitar sig på att svaret är sant och korrekt. Personer som inte är Professionella Investerare bör inte agera utifrån eller förlita sig på denna webbplats eller dess innehåll.",
            tos_p10: "Analysmaterial från Danske Bank A/S får inte, helt eller delvis, mångfaldigas eller spridas av någon mottagare för något ändamål utan föregående skriftligt medgivande av Danske Bank A/S.",
            form_label_yesPro1: "JA, jag bekräftar att jag är en",
            form_label_yesPro2: "Professionell Investerare",
            form_label_yesPro3: "enligt definitionen i MiFID II.",
            form_label_noPro1: "NEJ, jag är inte en",
            form_label_noPro2: "Professionell Investerare.",
            form_label_yesEea: "JA, jag bekräftar att jag är bosatt och har hemvist i en medlemsstat i EES eller i Storbritannien.",
            form_label_noEaa: "NEJ, jag är inte bosatt och har inte hemvist i en medlemsstat i EES eller i Storbritannien.",
        },
        cookieconsent: {
            bannerText: "Danske Bank-koncernen använder egna cookies och cookies från tredje part. Cookies används för att komma ihåg dina inställningar, för trafikmätning och för att visa riktade annonser. Genom att klicka på OK samtycker du till denna användning av cookies.",
            rejectText: "Om du inte vill godkänna användningen av cookies, klicka här.",
            readMoreText: "Läs mer om hur vi använder cookies.",
            acceptText: "OK",
            tos: {
                heading: "Behandling av personuppgifter och cookies",
                subheading: "Behandling av personuppgifter och användning av cookies på Danske Bank-koncernens webbplatser och i Danske Bank-koncernens elektroniska självbetjäningslösningar",
                h_1: "Så här behandlar vi dina data när du använder Danske Bank-koncernens webbplatser och digitala lösningar.",
                p_1: "Om du ber att bli kontaktad, skickar e-post till oss eller beställer en broschyr eller liknande, anger du ditt namn och din adress, din e-postadress, ditt telefonnummer och andra uppgifter, inklusive ditt personnummer. Vi använder informationen för att hantera och svara på din förfrågan. Vi lagrar inte sådana data i cookies.",
                h_2: "Cookies",
                p_2: "Cookies är passiva filer som lagras på din dator eller i annan IT-utrustning. De gör det möjligt för oss att identifiera din dator och få information om vilka webbsidor som har besökts och vilka funktioner som har använts från din dator eller datorutrustning. Cookies innehåller inte information om exempelvis vem du är eller var du bor. Cookies kan inte sprida virus eller andra skadliga program.",
                h_3: "Syfte",
                p_3: "Vi använder cookiedata i ett antal av våra aktiviteter och i de olika lösningar som vi erbjuder. Vi använder också cookies för att sammanställa statistik som till exempel kan användas för att förbättra våra webbplatser och anpassa upplevelsen efter dina behov. Vissa cookies använder vi i vår marknadsföring.",
                p_4: "Cookies kan inte användas för att identifiera dig som person, men vi kan använda informationen om de webbplatser som besöks från din dator, kombinerat med andra lagrade data om dig. Självklart använder vi informationen endast i enlighet med gällande lagstiftning.",
                p_5: "När du loggar in i våra självbetjäningslösningar, till exempel Danske eBanking, sparar vi information om var och när du besökte våra webbplatser. Den sparade informationen använder vi för att göra våra rådgivningstjänster och vår marknadsföring så relevant som möjligt. Vi sparar endast informationen om du har samtyckt till användningen av cookies. Observera dock att sessionscookies, funktionella cookies och operativa cookies alltid placeras på din dator – i annat fall skulle det inte gå att använda självbetjäningslösningarna.",
                p_6: "Mer information om vilka cookies vi använder finns i avsnittet ”Vi använder olika cookies”.",
                h_4: "Godkänna användning av cookies, återkalla godkännandet och neka användning av cookies",
                p_7: "Första gången som du besöker någon av våra webbplatser eller appar visas ett meddelande om användning av cookies. Du samtycker till användningen genom att klicka på ”OK” eller ”Ja, jeg accepterar”. Om du klickar på ”Nej tack” eller inte klickar på ”OK” avvisar du användning av cookies, vilket innebär att inga cookies lagras på din dator, förutom sådana cookies som krävs för att webbplatsen ska fungera.",
                p_8: "Därutöver lagrar vi också en cookie för att kunna ”komma ihåg” ditt val när du besöker webbplatsen nästa gång. Inga andra cookies används om du har valt att avvisa cookies (”Nej tack”).",
                p_9: "Om du vill återkalla ditt samtycke eller helt förhindra användning av cookies, måste du avaktivera cookies i webbläsaren. Sådan inaktivering av cookies innebär dock att du inte kan logga in i våra självbetjäningslösningar eller använda andra funktioner som kräver att webbsidan kommer ihåg dina val.",
                h_5: "Vi använder olika cookies",
                p_10: "Här följer en beskrivning av de olika typer av cookies som vi använder:",
                h_6: "1. Typ",
                p_11_1: "Sessionscookies:",
                p_11_2: "Sessionscookies existerar från det ögonblick då du öppnar webbläsaren, tills du stänger den igen. När du stänger webbläsaren, raderas dessa cookies automatiskt.",
                p_12_1: "Permanenta cookies:",
                p_12_2: "Permanenta cookies har en fördefinierad livslängd och finns kvar i webbläsaren tills livslängden löper ut eller tills du raderar dem.",
                h_7: "2. Omfattning",
                p_13_1: "Globala cookies:",
                p_13_2: "En global cookie är en cookie som kan placeras på alla sidor på våra webbplatser.",
                p_14_1: "Lokala cookies:",
                p_14_2: "En lokal cookie är en cookie som endast kan placeras på vissa sidor.",
                h_8: "3. Cookies från förstapart eller tredjepart",
                p_15_1: "Förstapartscookies:",
                p_15_2: "Det är våra egna cookies.",
                p_16_1: "Tredjepartscookies:",
                p_16_2: "Det här är cookies som, med vårt godkännande, placeras av tredjeparter.",
                h_9: "4. Syfte",
                p_17_1: "Statistik: Adobe Analytics och Celebrus:",
                p_17_2: "Danske Bank-koncernen använder Adobe Analytics och Celebrus, vilket är två verktyg som kan användas till exempel för att utföra statistiska analyser och profilering.",
                p_18: "Läs Adobe Analytics sekretesspolicy",
                p_19: "Läs Celebrus sekretesspolicy",
                p_20_1: "Användarundersökning – Qualtrics (QST – tredjepartscookie):",
                p_20_2: "Danske Bank-koncernen använder verktygen Research Suite och Site Intercept från Qualtrics, för att utföra användarundersökningar.",
                p_20_3: "Läs Qualtrics sekretesspolicy",
                p_21_1: "Marknadsföring (tredjepartscookies):",
                p_21_2: "Den här typen av cookies använder vi för att spåra användningen på flera webbsidor och webbplatser. Marknadsföringscookies kan användas för att skapa besöksprofiler som visar vilka sidor som har besökts eller vilka sökningar som har utförts. Endast anonyma data delas med tredjeparter, om du inte uttryckligen har godkänt något annat.",
                p_22_1: "Innehållsförbättring:",
                p_22_2: "Den här typen av cookies använder vi för att kunna visa det mest relevanta innehållet för webbplatsens olika besökare.",
                p_23_1: "Chatt och hjälp (MV9VSWQx och RUc4MTU3MjcxMl9TSWQx – tredjepartscookies):",
                p_23_2: "Dessa cookies placeras för att kunna erbjuda chattmöjligheter och hjälp som är anpassad för de sidor som besöks.",
                p_24_1: "Videostatistik – Adobe Analytics (demdex – tredjepartscookie):",
                p_24_2: "Vi använder Adobe Analytics, som är ett verktyg för att utföra statistiska analyser.",
                p_24_3: "Läs Adobe Analytics sekretesspolicy",
                p_25_1: "Webbövervakning:",
                p_25_2: "Det här är cookies som vi använder för att se till att våra IT-tjänster håller hög kvalitet. ",
                p_26_1: "E-magasin (ARPT_STATS – tredjepartscookie): ",
                p_26_2: "Vi använder ZMAGS för att visa interaktiva PDF-filer.",
                p_26_3: "Läs zmags cookiepolicy",
                p_27: "Facebook, Twitter, LinkedIn (tredjepartscookies)",
                p_28: "På några av våra webbplatser kan du länka till och/eller använda Facebooks, Twitters och LinkedIns ”gilla-knapp”. Om du gör det får Facebook, Twitter och LinkedIn teknisk information om din webbläsare, din dators IP-adress och vilka sidor du besöker, vilket du bör vara medveten om.",
                p_29: "Danske Bank-koncernen använder Facebooks pixel-/omriktningsfunktion, till exempel för att utföra statistiska analyser och profilering.",
                p_30: "När du använder de nämnda tjänsterna, gäller deras cookieregler. Här kan du läsa mer om dem:",
                h_10: "5. Klassificering",
                p_31_1: "Kat. 1 – funktionella:",
                p_31_2: "Det här är cookies som säkerställer att vissa webbplatsfunktioner, webbplatsområden eller hela webbplatser fungerar. De används till exempel i samband med chatt- och hjälpfunktioner samt våra frågeformulär.",
                p_32_1: "Kat. 2 – operativa:",
                p_32_2: "Operativa cookies placeras för att driva våra system. De används till exempel för att göra webbplatsen mer relevant för användaren, baserat på statistik och innehållsförbättringar.",
                p_33: "Våra operativa cookies placeras oberoende av ditt samtycke, eftersom de är nödvändiga för webbplatsernas funktion.",
                h_11: "Radera cookies",
                p_34: "När våra cookies har placerats i din webbläsare finns det inget sätt för oss att radera dem.",
                p_35: "Men i alla webbläsare kan du själv välja att radera antingen en cookie i taget eller alla samtidigt.",
                p_36_1: "Proceduren för att",
                p_36_2: "radera cookies",
                p_36_3: "beror på vilken webbläsare du använder. Anvisningar finns i varje webbläsares hjälpinformation. Kom ihåg att radera cookies i alla webbläsare om du använder fler än en webbläsare.",
            }
        },
    },
    NO: {
        TR1: "All Editions",
        TR2: "All research available",
        TR3: "UK Edition",
        TR4: "All research available in English",
        TR5: "Denmark Edition",
        TR6: "All research available in Danish and English",
        TR7: "Norway Edition",
        TR8: "All research available in Norwegian and English",
        TR9: "Sweden Edition",
        TR10: "All research available in Swedish and English",
        TR11: "Finland Edition",
        TR12: "All research available Finnish and English",
        TR13: "Kontakt oss",
        TR14: "Danske Bank Research",
        TR15: "Hovedpunkter",
        TR16: "Søk etter artikler",
        TR17: "Dato format",
        TR18: "Les mer",
        TR19: "Hva er nytt?",
        TR20: "Danske Daily",
        TR21: "Norsk Ukefokus",
        TR22: "Velg språk",
        TR23: "Siste analyser",
        TR24: "Vis alt",
        TR25: "Mest lest",
        TR26: "Siste tweets",
        TR27: "Siste podcaster",
        TR28: "Siste Youtube-videoer",
        TR29: "Nyhetsbrev",
        TR30: "Når en ananyse er publisert vil den bli sendt dirkete til din innboks. Trykk på abonner-knappen under, og du vil motta nyhetsbrevet.",
        TR31: "Bestill",
        TR32: "NO:Danske Bank footer + disclaimer? Which one to use for Research website",
        TR33: "Denne analysen er kun tilgjengelig for profesjonelle investorer. Vennligst bekreft under dersom du er en profesjonell investor for å få tilgang",
        TR34: "Jeg bekrefter herved at jeg er en profesjonell investor.",
        TR35: "Bekreft",
        TR36: "Kansellere",
        TR37: "Velg en eller flere nyhetsbrev",
        TR38: "For DCM analyse, vennligst klikk her for å bestille",
        TR39: "Fortsett",
        TR40: "Takk for bestillingen!",
        TR41: "Du vil snart motta en epost med en bestillingsbekreftelse",
        TR42: "Bekreft bestillingen",
        TR43: "Navn",
        TR44: "Sett in ditt navn",
        TR45: "E-post",
        TR46: "Sett in din e-post adresse",
        TR47: "Selskap",
        TR48: "Sett inn navnet på ditt selskap",
        TR49: "Din kundekontakt",
        TR50: "Sett inn navnet på din kundekontakt",
        TR51: "Ved å klikke på boksen aksepterer ",
        TR52: "Bestill",
        TR53: "Velg en nyhetskategori",
        TR54: "Makroøkonomi",
        TR55: "Renter",
        TR56: "Valuta",
        TR57: "Råvarer",
        TR58: "click here to subscribe.",
        TR59: "For DCM Research,",
        TR60: "Please note that DCM Research is for certified professional investors only.",
        TR61: "du gjeldende vilkår og betingelser",
        TR62: "Nordiske",
        TR63: "Internasjonal",
        TR64: "Regulære publikasjoner",
        TR65: "Strategier",
        TR66: "Reading the markets",
        TR67: "Avdekning",
        TR68: "Håndbøker",
        TR69: "Syn",
        TR70: "Kreditt",
        TR71: "Kreditanalyse (Corporates)",
        TR72: "Kreditanalyse (Finans)",
        TR73: "Covered bonds",
        TR74: "Prognoser",
        TR75: "Danske Daily er vår daglige morgenrapport",
        TR76: "Weekly Focus er vår ukerapport som ser nærmere på viktige økonomiske begivenheter kommende uke",
        // TR77: "Bekreftelse",
        TR77: "Disclaimer",
        TR78: "Analysene i denne kategori ikke tilgjengelig for retail-investorer",
        TR79: "Forslag:",
        TR80: "Prøv å skrive et annet søkeord i søkefeltet",
        TR81: "Prøv en annen underkategori i menyen",
        TR82: "Aksjer",
        TR83: "Om",
        TR84: "Om Danske Bank Research",
        TR85: "Ofte stilte spørsmål (FAQ)",
        TR86: "Velg en eller flere kategorier og velg relevant analyse",
        TR87: "ok",
        TR88: "Youtube Video",
        TR89: "Makroøkonomi",
        TR90: "Nordisk",
        TR91: "Danmark",
        TR92: "Sverige",
        TR93: "Norge",
        TR94: "Finland",
        TR95: "Nordic Outlook",
        TR96: "Internasjonal",
        TR97: "Euroområdet",
        TR98: "USA",
        TR99: "Storbritannia",
        TR100: "Kina",
        TR101: "Japan",
        TR102: "Emerging Markets",
        TR103: "The Big Picture",
        TR104: "Regulære publikasjoner",
        TR105: "Danske Daily",
        TR106: "Norsk Ukefokus",
        TR107: "Nøkkeltallskalender",
        TR108: "Scandi Markets Ahead",
        TR109: "Strategi",
        TR110: "Renter",
        TR111: "Regulære publikasjoner",
        TR112: "Government Bonds Weekly",
        TR113: "Danish Mortgage Bonds",
        TR114: "EUR Covered Bonds Weekly",
        TR115: "Yield Outlook",
        TR116: "Scandi Markets Ahead",
        TR117: "Danish Mortgage Bonds key figures",
        TR119: "Strategier",
        TR120: "Rentestrategi",
        TR121: "Reading the Markets",
        TR122: "RtM Denmark",
        TR123: "RtM Norway",
        TR124: "RtM Sweden",
        TR125: "Corporate Hedging",
        TR126: "Markedsguiden",
        TR127: "Rentesikring",
        TR128: "Håndbøker",
        TR129: "Danish Covered Bond Handbook",
        TR130: "Nordic Covered Bond Handbook",
        TR131: "Valuta",
        TR132: "Regulære publikasjoner",
        TR133: "FX Forecast Update",
        TR134: "IMM Positioning Update",
        TR136: "Danske Technical Update",
        TR137: "FX Top Trades",
        TR138: "Syn",
        TR139: "FX Strategy",
        TR140: "FX Edge",
        TR141: "FX Trading Portfolio",
        TR142: "Reading the Markets",
        TR143: "RtM Denmark",
        TR144: "RtM Norway",
        TR145: "RtM Sweden",
        TR146: "Corporate Hedging",
        TR147: "Markedsguiden",
        TR148: "Valutasikring",
        TR149: "Kreditt",
        TR150: "Regulære publikasjoner",
        TR151: "Håndbøker",
        TR152: "Credit Weekly",
        TR153: "Kreditanalyse (Corporates)",
        TR155: "Consumer goods",
        TR156: "Energy",
        TR157: "Industrials",
        TR158: "Healthcare",
        TR159: "Materials",
        TR160: "Real estate",
        TR161: "Services",
        TR162: "Shipping & Transportation",
        TR163: "TMT",
        TR165: "Utilities",
        TR166: "Kreditanalyse (Finans)",
        TR168: "Financials",
        TR169: "Covered Bond Update",
        TR170: "Covered bonds",
        TR171: "Prognoser",
        TR172: "Makroøkonomi",
        TR173: "Makroprognose",
        TR174: "Nøkkeltallskalender",
        TR175: "Finansprognose",
        TR176: "mot EUR",
        TR177: "mot SEK",
        TR178: "mot DKK",
        TR179: "mot NOK",
        TR180: "Valutaprognoser",
        TR181: "EUR",
        TR182: "USD",
        TR183: "DKK",
        TR184: "SEK",
        TR185: "NOK",
        TR186: "Aksjer",
        TR187: "Ny analysedekning",
        TR188: "Bestilte analyser (Commissioned Research)",
        TR189: "Syn",
        TR190: "Log in to Danske Bank Equities",
        TR191: "Aksepter vennligst  alle vilkår og betingelser",
        TR192: "DCM Research",
        TR193: "Kontakt din kunderådgiver i Danske Bank for å abonnere på DCM Research",
        TR194: "Tilbake",
        TR195: "Se på YouTube",
        TR196: "Forrige",
        TR197: "Gå til analyse",
        TR198: "Om Danske Bank Research",
        TR199: "Danske Bank Research er Danske Banks analyseavdeling. Vi er et dedikert team som dekker et bredt spekter av markeder og økonomier.",
        TR200: "Makroøkonomi og strategi",
        TR201: "økonomiske analyser, markedsutsikter og prognoser som dekker de nordiske landene samt Europa, USA, Asia og fremvoksende markeder.",
        TR202: "Fixed income",
        TR203: "Analyser som dekker statsobligasjoner fra Skandinavia og eurosonen og de nordiske OMF-markedene (covered bonds) med dyptgående fokus på danske panteobligasjoner. Avdelingen dekker også globale rente- og derivatmarkeder.",
        TR204: "Valutakurser ",
        TR205: "profesjonelle analyser, prognoser og handelsanbefalinger i globale valutamarkeder med spesielt fokus på G10 og skandinaviske valutaer.",
        TR206: "Råvarer ",
        TR207: "dyptgående analyser av og prognoser for børshandlede råvarer.",
        TR208: "Kreditt ",
        TR209: "oppdateringer og analyser med spesielt fokus på Skandinavia. Våre periodiske analyser tilbyr et «top-down» perspektiv på kredittmarkedene, mens våre selskapsanalyser inkluderer utstederprofiler og oppdateringer om skandinaviske utstedere, inkludert dekning og anbefalinger på kontantobligasjoner og CDS-er (credit default swaps).",
        TR210: "Aksjer ",
        TR211: "analyser av et bredt spekter av nordiske børsnoterte selskaper som bygger på en kombinasjon av lokalkjennskap, markedserfaring og grunnleggende ekspertise. ",
        TR212: "Commissioned Research ",
        TR213: "er selskapsanalyser av mindre børsnoterte selskaper som ikke er inkludert i Danske Banks normale analyser av børsnoterte selskaper på grunn av disse selskapenes størrelse og likviditet. Analysene er som regel bestilt av selskapene selv og tilbyr ikke anbefalinger eller målpriser. Alle bestilte analyser er åpent tilgjengelige. ",
        TR214: "Danske Bank Equity Research ",
        TR215: "er en plattform som tilbyr alle aksjeanalyser som Danske Bank utgir, inkludert selskapsnotater, sektorkommentarer og strategioppdateringer samt tilgang til vår omfattende database med grunnleggende data og verdivurderinger. Adgang til nettstedet krever status som betalende kunde. Vennligst ta kontakt med din kundeansvarlige hos Danske Bank for mer informasjon.",
        TR216: "Publikasjoner",
        TR217: "Du kan lese mer om våre makroøkonomiske analyser og våre forskjellige prognoser på dette nettstedet. Vi kan også levere analyser som utelukkende er målrettet profesjonelle investorer.",
        TR218: "Hvis du har spørsmål eller kommentarer, vennligst kontakt  Danske Bank Kundesupport på",
        TR219: "eller send en mail til",
        TR220: "Seneste analyser",
        TR221: "I dag",
        TR222: "Presentasjoner",
        TR223: "Alle presentasjoner",
        TR224: "Regulære publikasjoner",
        TR225: "Other",
        TR226: "Se alt {0}",
        TR227: "Inflasjonsprognose",
        TR228: "By clicking “subscribe” below you consent to receiving our research emails and our tracking of your opening of the mails and the attached documents for the purpose of obtaining updated subscribers’ information as well as measuring interest on the subjects of our research. You may always withdraw your consent by unsubscribing to our research emails or by writing to us to researchsubscriptions@danskebank.com. We keep this information for the period of your subscription. You may see more on how we process your personal data by pressing the link below.",
        TR229: "Scandi Vol Monitor",
        TR230: "GomSpace",
        TR231: "Podcasts",
        TR232: "Lytt på SoundCloud",
        TR233: "Lytt på iTunes",
        TR234: "Lytte",
        TR235: "Zutec",
        TR236: "Denne analysen er utelukkende tilgjengelig for institusjonelle og profesjonelle investorer som definert i MIFID II, som er hjemmehørende og bosatt i et EØS-medlemsland",
        TR237: "Klikk her for å komme til hjemmesidens disclaimer, der du kan se din tidligere oppgitte investorstatus",
        TR238: "Outlooks",
        TR239: "Denne analysen er ikke lenger tilgjengelig",
        TR240: "GBP",
        TR241: "Tikkurila",
        TR242: "Senzime",
        TR244: "Consti",
        TR245: "Aktia",
        TR246: "Glaston",     
        TR247: "Taaleri",   
        TR248: "Talenom",
        TR249: "Danske Bank Commissioned Research",
        TR250: "Companies",
        TR251: "Other Danske Bank Research",
        TR252: "Home",
        TR253: "Se alt Makroøkonomi ESG/Klima",
        TR254: "Se alt Renter ESG/Klima",
        TR255: "Se alt Kreditt ESG/Klima",
        TR256: "ESG/Klima",
        TR257: "Ikke-nordisk kredittforskning",
        TR259: "Other Comments",
        TR260: "Krigen i Ukraine",
        TR261: "Updates",
        TR262: "Loader",
        disclaimer: {
            tos_p1: "Analysene på denne nettsiden er kun til orientering. De utgjør ikke et tilbud om eller en oppfordring til å kjøpe eller selge finansielle instrumenter. Analysene utgjør ikke investeringsrådgivning i form av en personlig anbefaling til leseren.",
            tos_p2: "Selv om det er utvist rimelig aktsomhet for å sikre at innholdet ikke er usant eller villedende, gis det ingen garantier for innholdets korrekthet, nøyaktighet eller fullstendighet, og Danske Bank påtar seg intet ansvar for noe direkte eller indirekte tap i forbindelse med disposisjoner foretatt eller unnlatt i tillit til analysene.",
            tos_p3: "Danske Bank, dets tilknyttede selskaper eller ansatte kan utføre tjenester for eller inngå forretninger med eller søke å innhente forretninger fra og kan holde lange eller korte posisjoner i eller på annen måte ha interesser i investeringer (inkludert derivater) til andre utstedere som er nevnt i analysene på dette nettstedet.",
            tos_p4: "Analysene er basert på informasjon som vi betrakter som pålitelig, men Danske Bank A/S har ikke uavhengig verifisert innholdet av analysene. Holdningene, estimatene og fremskrivningene som er gjengitt i analysene, gjelder per analysenes dato og kan endres uten varsel. Det fremsettes verken uttrykkelig eller implisitt noen erklæring eller garanti om rimeligheten, nøyaktigheten, fullstendigheten eller riktigheten av informasjonen, holdningene og fremskrivningene som er inneholdt i analysene. Danske Bank A/S påtar seg intet ansvar for noe tap, uansett hvordan det måtte oppstå, som måtte følge av bruk av analysene på dette nettstedet eller deres innhold eller på noen annen måte i forbindelse med disse.",
            tos_p5_1: "En del av analysene på dette nettstedet er kun egnet for og rettet mot institusjonelle investorer og profesjonelle brukere",
            tos_p5_2: "(«Profesjonelle investorer»)",
            tos_p5_3: "og er ikke egnet for eller rettet mot detaljinvestorer.",
            tos_p6: "Enhver person som får tilgang til analysematerialet på dette nettstedet ved å klikke på «Ja», (som bekrefter) at personen er en Profesjonell Investor, anses å ha erklært og bekreftet at vedkommende er en Profesjonell Investor som definert i MiFID II (Europaparlaments- og rådsdirektiv 2014/65/EU av 15. mai 2014 om markeder for finansielle instrumenter) eller i MiFID II som onshored i Storbritannia.",
            tos_p7: "Analysene er ikke målrettet mot og kan heller ikke videreformidles til personer lokalisert utenfor Det europeiske økonomiske samarbeidsområdet (EØS) eller Storbritannia.",
            tos_p8: "Enhver person som får tilgang til analysematerialet på dette nettstedet ved å klikke på «Ja», (som bekrefter) at personen er hjemmehørende og bosatt i et EØS-medlemsland eller i Storbritannia, anses å ha erklært og bekreftet at vedkommende er hjemmehørende og bosatt i et EØS-medlemsland eller i Storbritannia.",
            tos_p9: "Danske Bank A/S handler i tillit til at svaret er sant og nøyaktig. Enhver person som ikke er en Profesjonell Investor, bør ikke disponere eller unnlate å disponere ut fra eller i tillit til analysematerialet eller dets innhold.",
            tos_p10: "Analyser fra Danske Bank A/S tilhører Danske Bank A/S og må ikke reproduseres eller distribueres, helt eller delvis, uten Danske Bank A/S’ forutgående skriftlige samtykke.",
            form_label_yesPro1: "JA, jeg bekrefter at jeg er en",
            form_label_yesPro2: "Profesjonell Investor",
            form_label_yesPro3: "som definert i MiFID II.",
            form_label_noPro1: "NEI, jeg er ikke en",
            form_label_noPro2: "Profesjonell Investor.",
            form_label_yesEea: "JA, jeg bekrefter at jeg er hjemmehørende og bosatt i et EØS-medlemsland eller i Storbritannia.",
            form_label_noEaa: "NEI, jeg er ikke hjemmehørende eller bosatt i et EØS-medlemsland eller i Storbritannia.",
        },
        cookieconsent: {
            bannerText: "Danske Bank Group bruker både egne informasjonskapsler (cookies) og informasjonskapsler fra tredjeparter. Informasjonskapslene brukes til å huske innstillingene dine, til trafikkmåling og til å vise målrettede annonser. Ved å klikke på OK, samtykker du i denne bruken av informasjonskapsler.",
            rejectText: "Klikk her hvis du ikke samtykker i bruken av informasjonskapsler.",
            readMoreText: "Les mer om bruken av informasjonskapsler.",
            acceptText: "OK",
            tos: {
                heading: "Behandling av personopplysninger og informasjonskapsler (cookies)",
                subheading: "Behandling av personopplysninger og bruk av informasjonskapsler på Danske Bank-konsernets nettsider og i Danske Bank Gruppens elektroniske selvbetjeningsløsninger",
                h_1: "Slik behandler vi opplysningene dine når du bruker Danske Bank-konsernets nettsider og digitale løsninger.",
                p_1: "Hvis du ber om å bli kontaktet, sender oss en e-post eller ber om å få tilsendt en brosjyre eller lignende via våre nettsider, oppgir du navn og adresse, e-postadresse, telefonnummer og andre opplysninger, inklusive fødselsnummer. Vi bruker disse opplysningene til å behandle og svare på forespørselen din. Vi lagrer ikke opplysningene i informasjonskapsler.",
                h_2: "Informasjonskapsler",
                p_2: "Informasjonskapsler er passive filer som lagres på datamaskinen eller annet IT-utstyr. De gjør det mulig for oss å gjenkjenne datamaskinen din og få informasjon om sidene du har besøkt og funksjoner som er tilgjengelige fra datamaskinen/enheten. Informasjonskapsler inneholder ikke informasjon om for eksempel hvem du er eller hvor du bor. Informasjonskapsler kan ikke spre virus eller andre skadelige programmer.",
                h_3: "Formål",
                p_3: "Vi bruker informasjonskapsler i forbindelse med våre vanlige aktiviteter og de ulike løsningene vi tilbyr. Vi bruker også informasjonskapsler til å samle inn statistikk som blant annet kan brukes til å forbedre våre nettsider og skreddersy opplevelsen etter dine behov. Vi bruker også noen informasjonskapsler i vår markedsføring.",
                p_4: "Informasjonskapsler kan ikke brukes til å identifisere deg som person, men vi kan bruke informasjonen om nettstedene som du har besøkt med datamaskinen sammen med de andre opplysningene som oppbevares om deg. Vi bruker naturligvis bare opplysningene i samsvar med gjeldende lovgivning.",
                p_5: "Når du logger på våre selvbetjeningsløsninger, som Danske Banks nettbank, lagrer vi informasjon om hvor og når du besøkte våre nettsteder. Vi bruker denne informasjonen til å gjøre våre rådgivningstjenester og vår markedsføring til deg så relevant som mulig. Vi lagrer kun informasjonen hvis du også har samtykket i bruk av informasjonskapsler. Vær imidlertid oppmerksom på at økt-, funksjonalitets- og driftsinformasjonskapsler alltid lagres, siden du ellers ikke ville kunne bruke våre selvbetjeningsløsninger.",
                p_6: "Du kan lese mer om de forskjellige informasjonskapslene vi bruker i delen «Vi bruker ulike informasjonskapsler».",
                h_4: "Samtykke til bruk av informasjonskapsler, tilbakekalle samtykke og avslå bruk av informasjonskapsler",
                p_7: "Første gang du besøker et av våre nettsteder eller applikasjoner, vises det en melding om bruken av informasjonskapsler. Du samtykker i bruken av informasjonskapsler ved å klikke «OK» eller «Ja takk» (Godta informasjonskapsler). Hvis du ikke klikker på «OK» eller klikker «Nei takk» (Avslå bruk av informasjonskapsler), blir det ikke gitt samtykke, og det blir ikke lagret noen informasjonskapsler, bortsett fra de som er nødvendige for at nettsidene våre skal fungere.",
                p_8: "Vi lagrer imidlertid en informasjonskapsel for å huske valget ditt, og ved ditt neste besøk på nettstedet vil det ikke bli brukt andre informasjonskapsler hvis du ikke har valgt «OK» eller hvis du har valgt «Nei takk».",
                p_9: "Hvis du ønsker å tilbakekalle samtykket eller helt unngå informasjonskapsler, må du deaktivere informasjonskapsler i nettleseren din. Du bør imidlertid være oppmerksom på at deaktivering av informasjonskapsler betyr at du ikke kan logge deg på selvbetjeningsløsninger eller bruke andre funksjoner som krever at den aktuelle nettsiden kan huske valgene dine.",
                h_5: "Vi bruker ulike informasjonskapsler",
                p_10: "Beskrivelse av de ulike typene informasjonskapsler vi bruker:",
                h_6: "1. Type",
                p_11_1: "Øktinformasjonskapsler:",
                p_11_2: "Disse informasjonskapslene eksisterer fra det øyeblikket du åpner nettleseren din og til du lukker den igjen. Når du lukker nettleseren, slettes disse informasjonskapslene automatisk.",
                p_12_1: "Faste informasjonskapsler:",
                p_12_2: "Disse informasjonskapslene har en forhåndsdefinert levetid og blir værende i nettleseren til de utløper eller til du sletter dem.",
                h_7: "2. Omfang",
                p_13_1: "Globale informasjonskapsler:",
                p_13_2: "En global informasjonskapsel er en informasjonskapsel som kan plasseres på en hvilken som helst side på våre nettsteder.",
                p_14_1: "Lokale informasjonskapsler:",
                p_14_2: "En lokal informasjonskapsel er en informasjonskapsel som bare kan plasseres på visse sider.",
                h_8: "3. Førsteparts- eller tredjeparts-informasjonskapsler",
                p_15_1: "Førsteparts-informasjonskapsler:",
                p_15_2: "Dette er våre egne informasjonskapsler.",
                p_16_1: "Tredjeparts-informasjonskapsler:",
                p_16_2: "Dette er informasjonskapsler som er plassert av tredjeparter etter avtale med oss.",
                h_9: "4. Formål",
                p_17_1: "Statistikk:",
                p_17_2: "Adobe Analytics og Celebrus: Danske Bank-konsernet bruker Adobe Analytics og Celebrus, som er verktøy som kan brukes til blant annet statistiske analyser og profilering.",
                p_18: "Les Adobe Analytics personvernerklæring",
                p_19: "Les Celebrus’ personvernerklæring",
                p_20_1: "Brukerundersøkelse – Qualtrics (QST – tredjeparts-informasjonskapsel):",
                p_20_2: "Danske Bank-konsernet bruker brukerundersøkelsesverktøyene Research Suite og Site Intercept fra Qualtrics.",
                p_20_3: "Les Qualtrics’ personvernerklæring.",
                p_21_1: "Markedsføring (tredjeparts-informasjonskapsler):",
                p_21_2: "Vi bruker disse informasjonskapslene til å spore bruk over flere nettsider og nettsteder. Disse informasjonskapslene kan brukes til å opprette besøksprofiler som viser søkene som er utført eller sidene som er besøkt. Vi deler kun anonyme data med tredjeparter, med mindre noe annet er uttrykkelig godkjent av deg.",
                p_22_1: "Innholdsforbedring:",
                p_22_2: "Vi bruker disse informasjonskapslene for å kunne vise innhold som er mest relevant for våre besøkende.",
                p_23_1: "Chat og hjelp (MV9VSWQx og RUc4MTU3MjcxMl9TSWQx – tredjeparts-informasjonskapsler):",
                p_23_2: "Disse informasjonskapslene plasseres for å aktivere chatter med besøkende og for å tilby relevant hjelp i forbindelse med de besøkte sidene.",
                p_24_1: "Videostatistikk – Adobe Analytics (demdex – tredjeparts-informasjonskapsel):",
                p_24_2: "Vi bruker Adobe Analytics, som er et verktøy til å utføre statistiske analyser.",
                p_24_3: "Les Adobe Analytics’ personvernerklæring",
                p_25_1: "Nettovervåking:",
                p_25_2: "Vi bruker disse informasjonskapslene for å sikre at våre IT-tjenester holder høy kvalitet.",
                p_26_1: "E-magazine (ARPT_STATS – tredjeparts-informasjonskapsel):",
                p_26_2: "Vi bruker ZMAGS til å vise interaktive PDF-er.",
                p_26_3: "Les Zmags’ erklæring om informasjonskapsler",
                p_27: "Facebook, Twitter, LinkedIn (tredjeparts-informasjonskapsler)",
                p_28: "På noen av våre nettsider kan du lenke til / bruke Like-knappen på Facebook, Twitter og LinkedIn. Vær oppmerksom på at hvis du gjør det, vil Facebook, Twitter og LinkedIn motta tekniske data om nettleseren din, IP-adressen og sidene du besøker.",
                p_29: "Danske Bank-konsernet bruker Facebooks re-targeting/pixel, blant annet for å utføre statistiske analyser og profilering.",
                p_30: "Når du bruker disse tjenestene, gjelder deres informasjonskapsler. Du kan lese mer om disse informasjonskapslene her:",
                h_10: "5. Klassifisering",
                p_31_1: "Kat. 1 – funksjonelt:",
                p_31_2: "Disse informasjonskapslene er såkalte funksjonelle informasjonskapsler som sikrer at en gitt funksjon eller et gitt nettsted kan fungere helt eller delvis. De brukes for eksempel i forbindelse med chat og hjelp, samt til våre spørreskjemaer.",
                p_32_1: "Kat. 2 – drift:",
                p_32_2: "Disse informasjonskapslene er såkalte driftsinformasjonskapsler, som er lagt igjen for å sikre driften av våre systemer. De brukes for eksempel til å gjøre et nettsted mer relevant for brukeren, basert på statistikk og innholdsforbedring.",
                p_33: "Disse informasjonskapslene omfattes ikke av ditt samtykke til informasjonskapsler, men vil bli lagret uansett om du har avslått bruk av informasjonskapsler, fordi de er nødvendige for at nettstedet skal fungere.",
                h_11: "Sletting av informasjonskapsler",
                p_34: "Vi kan ikke selv slette informasjonskapsler når de er plassert i nettleseren din.",
                p_35: "Men alle nettlesere lar deg slette informasjonskapsler enkeltvis, eller alle på én gang.",
                p_36_1: "Fremgangsmåten for",
                p_36_2: "å slette informasjonskapsler",
                p_36_3: "avhenger av nettleseren du bruker, men du kan alltid finne hjelp om hvordan du sletter informasjonskapsler i nettleseren din. Husk å slette informasjonskapsler i alle nettlesere hvis du bruker mer enn én nettleser.",
            }
        },
    },
    FI: {
        TR1: "All Editions",
        TR2: "All research available",
        TR3: "UK Edition",
        TR4: "All research available in English",
        TR5: "Denmark Edition",
        TR6: "All research available in Danish and English",
        TR7: "Norway Edition",
        TR8: "All research available in Norwegian and English",
        TR9: "Sweden Edition",
        TR10: "All research available in Swedish and English",
        TR11: "Finland Edition",
        TR12: "All research available Finnish and English",
        TR13: "Ota meihin yhteyttä",
        TR14: "Danske Bank tutkimus",
        TR15: "Nostot",
        TR16: "Etsi artikkeleita",
        TR17: "Päivämäärämuoto",
        TR18: "Lue lisää",
        TR19: "Uusimmat",
        TR20: "Aamukommentti",
        TR21: "Weekly Focus",
        TR22: "Valitse kieli",
        TR23: "Viimeisin tutkimus",
        TR24: "Näytä kaikki",
        TR25: "Suosituimmat",
        TR26: "Viimeisimmät tweetit",
        TR27: "Viimeisimmät podcastit",
        TR28: "Viimeisimmät Youtube videot",
        TR29: "Tutkimuksen uutiskirje",
        TR30: "Kun uusi tutkimus julkaistaan, se lähetetään suoraan s-postiisi. Paina tilaa-nappulaa, ja saat jatkossa uutiskirjeen.",
        TR31: "Tilaa",
        TR32: "FI:Danske Bank footer + disclaimer? Which one to use for Research website",
        TR33: "Tämä tutkimus on saatavilla vain ammattimaisille* sijoittajille. Vahvista, että sinut on määritelty ammattimaiseksi sijoittajaksi saadaksesi kirjautumistiedot.",
        TR34: "Vahvistan, että olen ammattimainen sijoittaja",
        TR35: "Vahvista",
        TR36: "Peruuta",
        TR37: "Lisää yksi tai useampi uutiskirje",
        TR38: "Lisää tästä DCM:n tutkimus",
        TR39: "Jatka",
        TR40: "Kiitos tilauksesta",
        TR41: "Saat pian tilausvahvistuksen s-postiisi",
        TR42: "Vahvista tilaus",
        TR43: "Nimi",
        TR44: "Lisää nimi",
        TR45: "S-posti",
        TR46: "Lisää s-postiosoitteesi",
        TR47: "Yritys",
        TR48: "Lisää yrityksen nimi",
        TR49: "Yhteyshenkilösi Danse Bankissa",
        TR50: "Lisää nimesi ja yhteyshenkilösi",
        TR51: "Klikkaamalla ruutua hyväksyt ",
        TR52: "Vahvista",
        TR53: "Valitset uutiskategoria",
        TR54: "Makrotalous",
        TR55: "Korot",
        TR56: "Valuutat",
        TR57: "Hyödykkeet",
        TR58: "click here to subscribe.",
        TR59: "For DCM Research,",
        TR60: "Please note that DCM Research is for certified professional investors only.",
        TR61: "sovellettavat käyttöehdot",
        TR62: "Pohjoismaat",
        TR63: "Kansainvälinen",
        TR64: "Säännölliset julkaisut",
        TR65: "Strategia",
        TR66: "Reading the markets",
        TR67: "Corporate Hedging",
        TR68: "Käsikirja",
        TR69: "Strategiat",
        TR70: "Yrityslainat",
        TR71: "Yritykset",
        TR72: "Pankit",
        TR73: "Katetut joukkolainat",
        TR74: "Ennusteet",
        TR75: "Aamukommentti on joka aamu ilmestyvä katsaus",
        TR76: "Weekly Focus on viikottainen katsaus, joka käsittelee seuraavan viikon tärkeitä taloustapahtumia",
        // TR77: "Vahvistus",
        TR77: "Disclaimer",
        TR78: "Tämä kategoria ei ole saatavilla ei-ammattimaisille sijoittajille",
        TR79: "Ehdotus:",
        TR80: "Kokeile toista hakusanaa hakukentässä",
        TR81: "Kokeile toista alakategoriaa valikossa",
        TR82: "Osakkeet",
        TR83: "Meistä",
        TR84: "Danske Bank -tutkimuksesta",
        TR85: "Yleisimmin kysytyt kysymykset",
        TR86: "Valitse yksi tai useampia kategorioita ja valitse haluamasi tutkimus",
        TR87: "ok",
        TR88: "Youtube Video",
        TR89: "Makrotalous",
        TR90: "Pohjoismaat",
        TR91: "Tanska",
        TR92: "Ruotsi",
        TR93: "Norja",
        TR94: "Suomi",
        TR95: "Nordic Outlook",
        TR96: "Kansainvälinen",
        TR97: "Euroalue",
        TR98: "USA",
        TR99: "UK",
        TR100: "Kiina",
        TR101: "Japani",
        TR102: "Kehittyvät markkinat",
        TR103: "The Big Picture",
        TR104: "Säännölliset julkaisut",
        TR105: "Aamukommentti",
        TR106: "Weekly Focus",
        TR107: "Market Movers",
        TR108: "Scandi Markets Ahead",
        TR109: "Strategy",
        TR110: "Korot",
        TR111: "Säännölliset julkaisut",
        TR112: "Government Bonds Weekly",
        TR113: "Danish Mortgage Bonds",
        TR114: "EUR Covered Bonds Weekly",
        TR115: "Yield Outlook",
        TR116: "Scandi Markets Ahead",
        TR117: "Danish Mortgage Bonds key figures",
        TR119: "Strategia",
        TR120: "Korkostrategia",
        TR121: "Reading the Markets",
        TR122: "RtM Denmark",
        TR123: "RtM Norway",
        TR124: "RtM Sweden",
        TR125: "Suojaukset",
        TR126: "Risk Manager",
        TR127: "Korkosuojaukset",
        TR128: "Käsikirja",
        TR129: "Danish Covered Bond Handbook",
        TR130: "Nordic Covered Bond Handbook",
        TR131: "Valuutat",
        TR132: "Säännölliset julkaisut",
        TR133: "Valuuttaennuste",
        TR134: "IMM Positioning Update",
        TR136: "Danske Technical Update",
        TR137: "FX Top Trades",
        TR138: "Näkymät",
        TR139: "FX Strategy",
        TR140: "FX Edge",
        TR141: "FX Trading Portfolio",
        TR142: "Reading the Markets",
        TR143: "RtM Denmark",
        TR144: "RtM Norway",
        TR145: "RtM Sweden",
        TR146: "Corporate Hedging",
        TR147: "Risk Manager",
        TR148: "Valuuttasuojaukset",
        TR149: "Yrityslainat",
        TR150: "Säännölliset julkaisut",
        TR151: "Käsikirja",
        TR152: "Credit Weekly",
        TR153: "DCM Research (Yritykset)",
        TR155: "Consumer goods",
        TR156: "Energy",
        TR157: "Industrials",
        TR158: "Healthcare",
        TR159: "Materials",
        TR160: "Real estate",
        TR161: "Services",
        TR162: "Shipping & Transportation",
        TR163: "TMT",
        TR165: "Utilities",
        TR166: "DCM Research (Pankit)",
        TR168: "Financials",
        TR169: "Covered Bond Update",
        TR170: "Katetut joukkolainat",
        TR171: "Ennusteet",
        TR172: "Makrotalous",
        TR173: "Makroennusteet",
        TR174: "Kalenteri",
        TR175: "Ennusteet",
        TR176: "vs EUR",
        TR177: "vs SEK",
        TR178: "vs DKK",
        TR179: "vs NOK",
        TR180: "FX taulukot",
        TR181: "EUR",
        TR182: "USD",
        TR183: "DKK",
        TR184: "SEK",
        TR185: "NOK",
        TR186: "Osakkeet",
        TR187: "Seurannassa olevat yhtiöt",
        TR188: "Tilaustutkimus",
        TR189: "Näkymät",
        TR190: "Log in to Danske Bank Equities",
        TR191: "Hyväksy kaikki sopimusehdot",
        TR192: "DCM Research",
        TR193: "DCM researchin osalta ole yhteydessä Danske Bankin yhteyshenkilöösi",
        TR194: "Takaisin",
        TR195: "Katso YouTubesta",
        TR196: "Edellinen",
        TR197: "Mene julkaisuun",
        TR198: "Danske Bank -tutkimuksesta",
        TR199: "Danske Bank Research on Danske Bankin tutkimusosasto. Tutkimusta tekevät tiimimme käsittelevät kattavasti markkinoiden ja talouden eri ulottuvuuksia:",
        TR200: "Makrotaloustiede ja strategia",
        TR201: "talouden tutkimus, markkinanäkymät ja ennusteet kattaen Pohjoismaat, Euroopan, USA:n, Aasian ja kehittyvät markkinat.",
        TR202: "Korkotuotteet",
        TR203: "Skandinavian ja euroalueen valtionobligaatioiden ja pohjoismaisten katettujen joukkolainojen markkinoiden tutkimus, joka keskittyy erityisesti tanskalaisiin asunto-obligaatioihin. Tutkimuksemme kattaa myös kansainväliset korko- ja johdannaismarkkinat. ",
        TR204: "Valuutat",
        TR205: "asiantuntevaa tutkimusta, ennusteita ja kaupankäyntisuosituksia kansainvälisiin valuuttamarkkinoihin ja erityisesti Skandinavian ja G10-ryhmän valuuttoihin liittyen.",
        TR206: "Hyödykkeet",
        TR207: "syvällistä tutkimustietoa ja ennusteita pörssilistattuihin hyödykkeisiin liittyen.",
        TR208: "Luotot",
        TR209: "ajantasaista tietoa ja tutkimusta erityisesti Skandinaviasta. Määräajoin tuottamamme tutkimustieto tarjoaa analyyttisiä näkemyksiä luottomarkkinoista. Yrityspuolen tutkimuksemme profiloi liikkeellelaskijoita ja  tarjoaa ajantasaista tietoa liikkeellelaskijoista Skandinaviassa sekä tietoa ja suosituksia joukkovelkakirjalainoista ja luottoriskijohdannaisista.",
        TR210: "Osakkeet",
        TR211: "kattavaa tutkimustietoa Pohjoismaiden pörssilistatuista yrityksistä laajan paikallistuntemuksen, markkinaosaamisen ja perusteellisen asiantuntemuksen pohjalta.",
        TR212: "Tilaustutkimukset",
        TR213: "ovat tutkimuksia pienemmistä pörssilistatuista yrityksistä, jotka eivät ole mukana Danske Bankin osaketutkimuksessa kokonsa ja likviditeettinsä takia. Tällaiset tutkimukset ovat usein kyseisten yritysten tilaamia, eivätkä ne sisällä suosituksia tai tavoitehintoja. Kaikki tilaustutkimukset ovat julkisesti saatavilla.",
        TR214: "Danske Bankin osaketutkimus ",
        TR215: "on alusta, joka tarjoaa kaiken Danske Bankin tuottaman osaketutkimustiedon, mukaan lukien yritys- ja toimialakommentit, strategiapäivitykset ja pääsyn laajaan tietokantaamme, joka sisältää perustiedot ja tunnusluvut. Tämä sivusto on maksullinen palvelu. Kysy lisää yhteyshenkilöltäsi Danske Bankissa.",
        TR216: "Julkaisut",
        TR217: "Tällä verkkosivustolla voit tutustua tuottamaamme makrotaloustieteelliseen tutkimukseen ja erilaisiin ennusteisiin. Tuotamme myös tutkimustietoa, joka on tarkoitettu vain ammattimaisille sijoittajille.",
        TR218: "Ongelmatilanteissa ota yhteys Danske Bankin asiakastukeen",
        TR219: "tai lähetä meille s-postia",
        TR220: "Viimeisimmät julkaisut",
        TR221: "Tänään",
        TR222: "Esitykset",
        TR223: "Kaikki esitykset",
        TR224: "Säännölliset julkaisut",
        TR225: "Muut",
        TR226: "Katso tutkimukset aiheesta {0}",
        TR227: "Inflaatioennusteet",
        TR228: "By clicking “subscribe” below you consent to receiving our research emails and our tracking of your opening of the mails and the attached documents for the purpose of obtaining updated subscribers’ information as well as measuring interest on the subjects of our research. You may always withdraw your consent by unsubscribing to our research emails or by writing to us to researchsubscriptions@danskebank.com. We keep this information for the period of your subscription. You may see more on how we process your personal data by pressing the link below.",
        TR229: "Scandi Vol Monitor",
        TR230: "GomSpace",
        TR231: "Podcasts",
        TR232: "Kuuntele SoundCloudissa",
        TR233: "Kuutele iTunesissa",
        TR234: "Kuuntele",
        TR235: "Zutec",
        TR236: "Tämä tutkimus on saatavilla MiFid II sääntelyn mukaan vain institutionaalisille ja ammattimaisile sijoittajille, jotka ovat verovelvollisia ja asuvat EEA-jäsenvaltiossa.",
        TR237: "Klikkaa tästä vastuuvapauslausekkeeseen, josta voit tarkistaa sijoittajastatuksesi.",
        TR238: "Outlooks",
        TR239: "Tämä julkaisu ei ole enää saatavilla",
        TR240: "GBP",
        TR241: "Tikkurila",
        TR242: "Senzime",
        TR244: "Consti",
        TR245: "Aktia",
        TR246: "Glaston",      
        TR247: "Taaleri",  
        TR248: "Talenom",
        TR249: "Danske Bank Commissioned Research",
        TR250: "Companies",
        TR251: "Other Danske Bank Research",
        TR252: "Home",
        TR253: "Katso tutkimukset aiheesta Makrotalous ESG/Ilmasto",
        TR254: "Katso tutkimukset aiheesta Korot ESG/Ilmasto",
        TR255: "Katso tutkimukset aiheesta Yrityslainat ESG/Ilmasto",
        TR256: "ESG/Ilmasto",
        TR257: "Ei pohjoismainen luottotutkimus",
        TR259: "Other Comments",
        TR260: "Ukrainan sota",
        TR261: "Updates",
        TR262: "Loader",
        disclaimer: {
            tos_p1: "Tällä sivustolla oleva tutkimusaineisto on tarkoitettu vain tiedoksi. Se ei ole tarjous ostaa tai myydä rahoitusinstrumentteja eikä pyyntö rahoitusinstrumenttien ostoa tai myyntiä koskien. Tutkimusaineisto ei edusta lukijalle henkilökohtaisesti tehdyn suosituksen muodossa olevaa sijoitusneuvontaa.",
            tos_p2: "Vaikka sisällön paikkansapitävyys ja harhaanjohtamattomuus on varmistettu kohtuullista huolellisuutta noudattaen, sen virheettömyyttä, oikeellisuutta ja täydellisyyttä ei taata eikä tutkimusaineiston perusteella tehtyjen tai tekemättä jätettyjen toimien seurauksena syntyneistä välittömistä tai välillisistä vahingoista oteta vastuuta.",
            tos_p3: "Danske Bank, sen tytäryhtiöt ja työntekijät voivat tarjota palveluja kaikille tämän sivuston tutkimusaineistossa mainituille liikkeellelaskijoille, tehdä heidän kanssaan liiketoimia tai ehdottaa sellaisia tehtäväksi ja pitää hallussaan pitkiä tai lyhyitä positioita näiden liikkeellelaskijoiden sijoituskohteisiin liittyen tai muuten olla kiinnostuneita näiden liikkeellelaskijoiden sijoituskohteista (johdannaiset mukaan lukien).",
            tos_p4: "Nämä asiakirjat perustuvat luotettavina pitämiimme tietoihin, mutta Danske Bank A/S ei ole itsenäisesti varmistanut niiden sisältöä. Niissä esitetyt mielipiteet, arviot ja ennusteet ovat asiakirjan laatimishetkeltä, ja ne voivat muuttua ilman ennakkovaroitusta. Asiakirjan sisältämien tietojen, mielipiteiden ja ennusteiden oikeellisuutta, paikkansapitävyyttä, täydellisyyttä ja sopivuutta ei taata nimenomaisesti eikä epäsuorasti, eikä niihin tule tukeutua, eikä Danske Bank A/S vastaa mistään vahingoista, jotka syntyvät tämän verkkosivuston asiakirjojen tai niiden sisällön käytöstä tai muuten niihin liittyen.",
            tos_p5_1: "Suuri osa tutkimusaineistosta soveltuu ja on tarkoitettu vain institutionaalisille ja ammattimaisille asiakkaille",
            tos_p5_2: "(”Ammattimaiset sijoittajat”)",
            tos_p5_3: ", eikä aineisto sovellu tai ole tarkoitettu yksityissijoittajille.",
            tos_p6: "Jokaisen, joka käyttää tällä verkkosivustolla olevaa tutkimusaineistoa napsauttamalla ”Kyllä” ja vahvistamalla olevansa Ammattimainen sijoittaja, katsotaan vakuuttaneen ja myöntäneen, että hän on MiFID II -dokumentin määritelmän mukainen Ammattimainen sijoittaja (MiFID II, Euroopan parlamentin ja neuvoston direktiivi 2014/65/EU rahoitusvälineiden markkinoista) tai Ison-Britannian kansallisen lainsäädännön osaksi otetun MiFID II:n määritelmän mukainen Ammattimainen sijoittaja.",
            tos_p7: "Tätä tutkimusta ei ole tarkoitettu Euroopan talousalueen (ETA) ulkopuolisille tai Isossa-Britanniassa sijaitseville henkilöille, eikä sitä saa jakaa sellaisille henkilöille.",
            tos_p8: "Jokaisen, joka käyttää tällä verkkosivustolla olevaa tutkimusaineistoa napsauttamalla ”Kyllä” ja vahvistamalla pitävänsä kotipaikkaansa ja asuvansa Euroopan talousalueen jäsenvaltiossa, tai Isossa-Britanniassa, katsotaan vakuuttaneen ja myöntäneen, että hän pitää kotipaikkaansa ja asuu Euroopan talousalueen jäsenvaltiossa, tai Isossa-Britanniassa.",
            tos_p9: "Danske Bank A/S luottaa vastauksen oikeellisuuteen ja paikkansapitävyyteen. Henkilöiden, jotka eivät ole Ammattimaisia sijoittajia, ei tule toimia tai jättää toimimatta tähän tutkimusaineistoon tai sen sisältöön tukeutuen.",
            tos_p10: "Danske Bank A/S:n tutkimusmateriaali on Danske Bank A/S:n omaisuutta, eikä sitä saa mitään tarkoitusta varten kopioida tai jakaa kokonaisuudessaan tai osittain ilman Danske Bank A/S:n etukäteen antamaa kirjallista suostumusta.",
            form_label_yesPro1: "KYLLÄ, vahvistan olevani MiFID II -dokumentin määritelmän mukainen",
            form_label_yesPro2: "Ammattimainen sijoittaja.",
            form_label_yesPro3: " ",
            form_label_noPro1: "EI, en ole",
            form_label_noPro2: "Ammattimainen sijoittaja.",
            form_label_yesEea: "KYLLÄ, vahvistan pitäväni kotipaikkaani ja asuvani Euroopan talousalueen jäsenvaltiossa, tai Isossa-Britanniassa.",
            form_label_noEaa: "EI, en pidä kotipaikkaani tai asu Euroopan talousalueen jäsenvaltiossa, tai Isossa-Britanniassa.",
        },
        cookieconsent: {
            bannerText: "Danske Bank -konserni käyttää omia evästeitään sekä kolmansien osapuolien evästeitä. Evästeitä käytetään asetustesi muistamiseen, verkkoliikenteen mittaamiseen ja kohdennettujen mainosten näyttämiseen. Valitsemalla OK hyväksyt evästeiden käytön.",
            rejectText: "Jos et halua hyväksyä evästeiden käyttöä, napsauta tästä.",
            readMoreText: "Lue lisää, miten käytämme evästeitä.",
            acceptText: "OK",
            tos: {
                heading: "Henkilötietojen ja evästeiden käsittely",
                subheading: "Henkilötietojen käsittely ja evästeiden käyttö Danske Bank -konsernin verkkosivustoilla ja sähköisissä itsepalveluratkaisuissa",
                h_1: "Miten voimme käsitellä tietojasi, kun käytät Danske Bank -konsernin verkkosivustoja ja digitaalisia ratkaisuja.",
                p_1: "Jos pyydät yhteydenottoa, lähetät meille sähköpostia tai pyydät esitettä verkkosivustojemme kautta, annat meille nimesi ja osoitteesi, sähköpostiosoitteesi, puhelinnumerosi ja muita tietoja, mukaan lukien henkilötunnuksesi. Käytämme näitä tietoja kyselysi käsittelyyn ja siihen vastaamiseen. Emme tallenna tietoja evästeisiin.",
                h_2: "Evästeet",
                p_2: "Evästeet ovat passiivisia tiedostoja, jotka on tallennettu tietokoneellesi tai muihin IT-laitteisiin. Niiden avulla voimme tunnistaa tietokoneesi ja saada tietoa sivuista, joilla olet vieraillut sekä toiminnoista, joita olet tietokoneellasi tai muilla laitteillasi käyttänyt. Evästeet eivät sisällä sinusta tietoja – esimerkiksi siitä, kuka olet tai missä asut. Evästeet eivät voi levittää viruksia tai muita haittaohjelmia.",
                h_3: "Tarkoitus",
                p_3: "Käytämme evästetietoja tavallisen toimintamme ja erilaisten ratkaisujemme yhteydessä. Käytämme evästeitä myös kootaksemme tilastoja, joita voidaan käyttää esimerkiksi verkkosivustojemme kehittämiseen ja asiointikokemuksen räätälöimiseen tarpeitasi vastaavaksi. Käytämme joitakin evästeitä myös markkinoinnissa.",
                p_4: "Evästeiden avulla sinua ei voida tunnistaa, mutta voimme käyttää tietoja verkkosivuista, joilla tietokoneellasi on vierailtu, ja muita sinusta tallennettuja tietoja. Käytämme tietenkin tietoja vain voimassa olevan lainsäädännön mukaisesti.",
                p_5: "Kun kirjaudut itsepalveluratkaisuihimme, kuten verkkopankkiin, tallennamme tietoja siitä, missä ja milloin vierailit verkkosivustoillamme. Käytämme näitä tietoja tarjotaksemme sinulle paremmin tilanteeseesi sopivia neuvontapalveluja ja markkinointia. Tallennamme tiedot vain, jos olet hyväksynyt evästeiden käytön. Huomaa kuitenkin, että istuntoon, toiminnallisuuteen ja sivuston käyttöön liittyvät evästeet tallennetaan laitteellesi joka tapauksessa, sillä muuten et voisi käyttää itsepalveluratkaisujamme.",
                p_6: "Voit lukea lisää käyttämistämme evästeistä osiosta \"Käytämme monenlaisia evästeitä\".",
                h_4: "Evästeiden käyttämisen hyväksyminen, hyväksynnän peruuttaminen ja evästeiden käytön kieltäminen",
                p_7: "Kun vierailet verkkopalvelussamme ensimmäistä kertaa, sinulle näytetään evästeiden käytöstä kertova viesti. Hyväksyt evästeiden käytön napsauttamalla ”OK” tai 'Kyllä, hyväksyn evästeet”. Jos napsautat \"En hyväksy evästeitä”, suostumusta ei anneta eikä laitteellesi tallenneta muita evästeitä kuin ne, jotka ovat verkkopalvelumme toiminnan kannalta välttämättömiä.",
                p_8: "Tallennamme kuitenkin evästeen valintasi muistamiseksi, joten seuraavilla vierailuillasi muita evästeitä ei käytetä, jos olet valinnut ”En hyväksy evästeitä”.",
                p_9: "Jos haluat peruuttaa suostumuksesi tai välttää evästeet kokonaan, sinun on poistettava evästeet käytöstä selaimessasi. Sinun tulee kuitenkin olla tietoinen siitä, poistettuasi evästeet käytöstä et voi kirjautua itsepalveluratkaisuihimme tai käyttää muita toimintoja, jotka edellyttävät, että kyseinen verkkosivusto muistaa valintasi.",
                h_5: "Käytämme monenlaisia evästeitä",
                p_10: "Kuvaus käyttämistämme evästetyypeistä:",
                h_6: "1. Tyyppi",
                p_11_1: "Istuntoevästeet:",
                p_11_2: "Nämä evästeet toimivat selaimen käynnistämishetkestä sen sulkemiseen asti. Kun suljet selaimen, nämä evästeet poistetaan automaattisesti.",
                p_12_1: "Pysyvät evästeet:",
                p_12_2: "Näillä evästeillä on ennalta määritetty käyttöikä, ja ne säilyvät selaimessa, kunnes ne vanhenevat tai poistat ne.",
                h_7: "2. Soveltamisala",
                p_13_1: "Globaalit evästeet:",
                p_13_2: "Globaali eväste on eväste, joka voidaan sijoittaa mille tahansa sivustojemme sivulle.",
                p_14_1: "Paikalliset evästeet:",
                p_14_2: "Paikallinen eväste on eväste, joka voidaan sijoittaa vain valituille sivuille.",
                h_8: "3. Ensimmäisen tai kolmannen osapuolen evästeet",
                p_15_1: "Ensimmäisen osapuolen evästeet:",
                p_15_2: "Nämä ovat omia evästeitämme.",
                p_16_1: "Kolmannen osapuolen  evästeet:",
                p_16_2: "Nämä evästeet ovat ulkopuolisten tahojen asettamia evästeitä, joista on sovittu kanssamme.",
                h_9: "4. Tarkoitus",
                p_17_1: "Tilastot: ",
                p_17_2: "Adobe Analytics ja Celebrus: Danske Bank -konserni käyttää Adobe Analyticsia ja Celebrusta. Molemmat ovat työkaluja, joita voidaan käyttää muun muassa tilastollisten analyysien ja profiloinnin tekemiseen.",
                p_18: "Lue Adobe Analyticsin tietosuojalausunto",
                p_19: "Lue Celebrusin tietosuojalausunto",
                p_20_1: "Käyttäjätutkimus – Qualtrics (QST – kolmannen osapuolen eväste): ",
                p_20_2: "Danske Bank -konserni käyttää Qualtricsin Research Suite- ja Site Intercept -kyselytutkimustyökaluja.",
                p_20_3: "Lue Qualtricsin tietosuojalausunto.",
                p_21_1: "Markkinointi (kolmannen osapuolen evästeet):",
                p_21_2: "Käytämme näitä evästeitä seurataksemme käyttöä useilla eri verkkosivuilla ja -sivustoilla. Näiden evästeiden avulla voidaan luoda vierailuprofiileja, joista käyvät ilmi tehdyt haut tai avatut sivut. Luovutamme kolmansille osapuolille vain nimettömiä tietoja, ellet nimenomaan anna laajempaa hyväksyntää tietojen luovuttamiselle.",
                p_22_1: "Sisällön kehittäminen:",
                p_22_2: "Evästeiden avulla näytämme vierailijoille sisältöä, joka on heille kaikkein ajankohtaisinta.",
                p_23_1: "Chat ja tuki (MV9VSWQx ja RUc4MTU3MjcxMl9TSWQx – kolmannen osapuolen evästeet):",
                p_23_2: "Näitä evästeitä käytetään chat-keskusteluihin vierailijoiden kanssa ja vierailtujen sivujen suhteen olennaisen tuen tarjoamiseen.",
                p_24_1: "Videotilastot – Adobe Analytics (demdex – kolmannen osapuolen eväste):",
                p_24_2: "Käytämme Adobe Analyticsia, joka on tilastollisten analyysien tekemiseen käytettävä työkalu.",
                p_24_3: "Lue Adobe Analyticsin tietosuojalausunto",
                p_25_1: "Web-seuranta:",
                p_25_2: "Käytämme näitä evästeitä varmistaaksemme, että IT-palvelumme ovat korkealaatuisia.",
                p_26_1: "E-Magazine (ARPT_STATS – kolmannen osapuolen eväste):",
                p_26_2: "Käytämme ZMAGSia interaktiivisten PDF-tiedostojen näyttämiseen.",
                p_26_3: "Lue Zmagsin evästekäytäntö",
                p_27: "Facebook, Twitter, LinkedIn (kolmannen osapuolen evästeet)",
                p_28: "Joillakin verkkosivustoillamme voit käyttää ”tykkäyspainiketta” tai linkittää sivun Facebookiin, Twitteriin tai LinkedIniin. Huomaa, että jos teet niin, Facebook, Twitter ja LinkedIn saavat teknisiä tietoja selaimestasi, IP-osoitteestasi ja sivuista, joilla vierailet.",
                p_29: "Danske Bank -konserni käyttää Facebookin uudelleenkohdennusta/pikseliä, jota käytetään esimerkiksi tilastollisten analyysien ja profiloinnin tekemiseen.",
                p_30: "Kun käytät näitä palveluita, niiden evästesäännöt ovat voimassa. Voit lukea lisää evästesäännöistä täältä:",
                h_10: "5. Luokittelu",
                p_31_1: "Kat. 1 – toiminnallinen:",
                p_31_2: "Nämä evästeet ovat niin sanottuja toiminnallisia evästeitä, jotka varmistavat, että tietty toiminto tai tietty verkkosivusto voi toimia kokonaan tai osittain. Niitä käytetään esimerkiksi chatin ja tuen sekä kyselylomakkeiden yhteydessä.",
                p_32_1: "Kat. 2 – operatiivinen:",
                p_32_2: "Nämä evästeet ovat niin sanottuja operatiivisia evästeitä, jotka on sijoitettu sivustoille järjestelmiemme toiminnan varmistamiseksi. Niitä käytetään esimerkiksi tekemään sivustosta tilastojen ja sisällön kehittämisen avulla paremmin käyttäjän tarpeisiin vastaava.",
                p_33: "Nämä evästeet eivät kuulu evästehyväksynnän piiriin, vaan ne tallennetaan hyväksynnästä riippumatta, koska ne ovat välttämättömiä verkkosivustojen toiminnalle.",
                h_11: "Evästeiden poistaminen",
                p_34: "Me emme voi poistaa evästeitä, kun ne on tallennettu selaimeesi.",
                p_35: "Kuitenkin kaikilla selaimilla voit itse poistaa evästeitä yksitellen tai kaikki kerralla.",
                p_36_1: "Evästeiden poistamiseen tarvittava",
                p_36_2: "menettely",
                p_36_3: "määräytyy käyttämäsi selaimen mukaan, mutta löydät aina ohjeet evästeiden poistamiseen selaimestasi. Muista poistaa evästeet kaikista selaimista, jos käytät useampaa kuin yhtä selainta.",
            }
        },
    },
});
