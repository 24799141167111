export const navlink = {
    float: 'left', 
    padding: '0 36px 25px 0',    
    '@media(min-width: 768px) and (max-width:1024px)': {
        padding: '0 1% 25px 0 !important',
        float: 'left !important'
    },   
    '@media(min-width: 1025px) and (max-width:1100px)': {
        padding: '0 10px 25px 10px !important'        
    }
}
