import React, { Component } from 'react';
import { css, style } from 'glamor';
import * as styles from './footer.style';
import * as homePageStyles from '../../home/homepage.style';
import logo from '../../../assets/icons/logo.svg'
import HeaderSection from '../header/header';
import HeaderTabletSection from '../header/header.tablet';
import NavBar from '../../navbar/navbar';
import * as navigation from '../../../configs/navigation';
import * as commonstyles from '../../common/common.style';

import Disclaimer from '../../disclaimer'

import TermOfUse from '../../disclaimer/TermOfUse';

const AgreementSignedKey = 'AgreementSigned';
const DescribeProfessionalInvestorKey = 'DescribeProfessionalInvestor';

export default class FooterSection extends Component {
    constructor(props) {
        super(props);

        let agreementSigned = localStorage.getItem(AgreementSignedKey) || false;
        let describingProfesionalInvestor = localStorage.getItem(DescribeProfessionalInvestorKey) || false;
        this.state = { disclaimerModalIsOpen: !agreementSigned && !describingProfesionalInvestor, showRemainingInfo: false, TermOfUserModalIsOpen: false };
        this.handleDisclaimerClose = this.handleDisclaimerClose.bind(this);
        this.handleDisclaimerOpen = this.handleDisclaimerOpen.bind(this);
        this.handleDisclaimerSigned = this.handleDisclaimerSigned.bind(this);
        this.handleTermOfUserClose = this.handleTermOfUserClose.bind(this);
    }

    handleDisclaimerClose() {
        this.setState({ disclaimerModalIsOpen: false });
    }

    handleDisclaimerOpen() {
        this.setState({ disclaimerModalIsOpen: true });
    }

    handleTermOfUserClose() {
        this.setState({ TermOfUserModalIsOpen: false });
    }

    handleTermOfUserOpen() {
        this.setState({ TermOfUserModalIsOpen: true });
    }

    handleDisclaimerSigned() {
        this.setState({ disclaimerModalIsOpen: false });
        localStorage.setItem(AgreementSignedKey, true);
    }

    render() {
        return (
            <div {...css(homePageStyles.mainContainer)}>

                <Disclaimer modalIsOpen={this.state.disclaimerModalIsOpen} onDisclaimerRequestingClose={this.handleDisclaimerClose}
                    onDisclaimerAgreementSigned={this.handleDisclaimerSigned} commr={this.props.commr} />

                <TermOfUse modalTermOfUserIsOpen={this.state.TermOfUserModalIsOpen} onTermOfUserRequestingClose={this.handleTermOfUserClose} />

                <footer {...css(styles.footerContainer)}>
                    <div className="container-fluid">
                        <div className="row" style={{ marginBottom: '4rem' }}>
                            <div className="col-md-8 col-lg-9 col-sm-8 col-12">
                                <p {...css(styles.footerParagraph)} style={{ marginBottom: '1rem' }}>
                                    Information about funds on this website has been prepared as marketing communication and does not constitute investment advice. You should always consult with your advisors before investing and get familiar with your investor rights in the prospectus, the key investor information, and information regarding complaints handling.
                                </p>
                                <p {...css(styles.footerParagraph)} style={{ marginBottom: '1rem' }}>
                                    Danske Bank does not offer investment advisory services (“Investment Advisory Services”) or securities execution and other securities brokerage and dealing services (“Broker-Dealer Services”) to US Persons, as defined below, and the material on this website is not intended for distribution to, or use by, any such US Persons. Nothing in the material on this website should be construed as an offer of Investment Advisory Services or Broker-Dealer Services to a US Person.
                                </p>
                                {
                                    this.state.showRemainingInfo == true ?
                                        <>
                                            <p {...css(styles.footerParagraph)} style={{ marginBottom: '1rem' }}>
                                                With respect to Investment Advisory Services, a US Person is a natural person resident in the United States; or a company or partnership incorporated or organized in the US, but excluding an offshore branch or agency of a US Person that operates for valid business reasons and is engaged and regulated as an insurance company or bank; or a branch or agency of a foreign entity located in the US; or a trust of which the trustee is a US Person, unless a non-US Person has or shares investment discretion; or an estate of which a US Person is the executor or administrator, unless the estate is governed by foreign law and a non-US Person has or shares investment discretion; or a non-discretionary account held for the benefit of a US Person; or a discretionary account held by a US dealer or fiduciary, unless held for the benefit of a non-US Person; or any entity organized or incorporated for the purposes of evading US securities laws. The term “US Person” does not include any person who was not in the United States at the time of becoming an investment advisory client of Danske Bank.
                                            </p>
                                            <p {...css(styles.footerParagraph)} style={{ marginBottom: '1rem' }}>
                                                With respect to Broker-Dealer Services, a US Person is any customer present within the United States, other than a customer who resided outside of the United States at the time his or her relationship with Danske Bank was established and who—when present in the United States—is neither (i) a US citizen (including a dual citizen of the US and another country), (ii) a US lawful permanent resident (i.e., “green card holder”), nor (iii) a person who is otherwise in the United States other than on a temporary basis.
                                            </p>
                                        </>
                                        : <></>
                                }

                                {
                                    this.state.showRemainingInfo == false &&

                                    <a href="#" {...css(styles.FooterReadMore)} onClick={(e) => {
                                        this.setState({ showRemainingInfo: true })
                                        e.preventDefault()
                                    }}>Read more »</a>
                                }
                            </div>
                        </div>
                        <hr {...css(styles.footerHrTag)} />
                        <div className="row" style={{ marginTop: '8.25rem' }}>
                            <div className='col-md-8 col-lg-9 col-sm-8 col-12'>
                                <p {...css(styles.footerParagraph)} style={{ marginBottom: '1rem' }}>
                                    Danske Bank A/S, Bernstorffsgade 40, 1577 Copenhagen V. Tel. <a aria-label="(+45) 70 123 456" alt="(+45) 70 123 456" href="tel:+4533440000" {...css(styles.anchorLink)}>(+45) 70 123 456</a>, e-mail: <a aria-label="danskebank@danskebank.dk" alt="danskebank@danskebank.dk" href="mailto:danskebank@danskebank.dk" {...css(styles.anchorLink)}>danskebank@danskebank.dk</a>, CVR No. 61126228, SWIFT: DABADKKK.
                                    <br />
                                    Phone conversations may be recorded and stored due to documentation and security purposes

                                </p>
                                <p {...css(styles.footerParagraph)} style={{ marginBottom: '1rem' }}>
                                    Danske Bank reserves all rights. Danske Bank A/S is authorised by the Danish Financial Supervisory Authority.
                                    <br />
                                    Department responsible for complaints: Danske Bank, Complaint Management Function, Bernstorffsgade 40, 1577 Copenhagen V.
                                </p>
                                <p {...css(styles.footerParagraph)} style={{ marginBottom: '1rem' }}>
                                    Read our <a aria-label="privacy notice" alt="privacy notice" target="_blank" href="https://danskebank.dk/PDF/GDPR/Danske_Bank_privacy_notice.pdf" {...css(styles.anchorLink)}>privacy notice</a>, <a aria-label="terms of use of website" alt="terms of use of website" href="#" onClick={(event) => this.handleTermOfUserOpen()} {...css(styles.anchorLink)}>terms of use of website</a> and <a aria-label="statement on use of cookies and personal information on our websites etc." alt="statement on use of cookies and personal information on our websites etc." href="https://danskebank.dk/treatment-of-data-and-cookies" {...css(styles.anchorLink)}>statement on use of cookies and personal information on our websites etc.</a>
                                </p>
                                <p {...css(styles.footerParagraph)} style={{ marginBottom: '1rem' }}>
                                    You are welcome to contact us if you have questions about how we process personal data - contact our Data Protection Officer, Bernstorffsgade 40, 1577 Copenhagen V, e-mail: <a aria-label="DPOfunction@danskebank.com" alt="DPOfunction@danskebank.com" href="mailto:DPOfunction@danskebank.com" {...css(styles.anchorLink)}>DPOfunction@danskebank.com</a>
                                </p>
                            </div>
                            <div className='col-md-4 col-lg-3 col-sm-4 col-12'>
                                <img className="float-right" {...css(styles.logo)} src={logo} alt={"Danske Bank"} />
                            </div>

                            <br />
                            <div className="col-md-8 col-lg-9 col-sm-8 col-12" >
                                <p {...css(styles.footerParagraph)}>
                                    <a alt="Research Disclaimer" {...css(styles.anchorLink)} onClick={(event) => this.handleDisclaimerOpen()} > Research Disclaimer </a>
                                    &nbsp;
                                    &nbsp;
                                    <a aria-label="Further disclosures and disclaimers" alt="Further disclosures and disclaimers" {...css(styles.anchorLink)} target="blank" href={`https://danskeci.com/ci/research/research-disclaimer`}>Further disclosures and disclaimers</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
