export const imageTextContainer = {
    marginTop: '50px',
    border: 'none',
    height: '100%'
};
export const H3 = {
    color: '#003755',
    fontSize: '1.5em',
    fontWeight: '600'
};
export const rowHeight = {
    height: '100%'
}
export const textContent = {
    padding: '25px 0 0 6.5%'
}
export const textParagraph = {
    color: '#003755',
    fontSize: '.875em',
    lineHeight: '1.125em',
    marginTop: '15px',
}
export const date = {
    fontSize: '.875em',
    lineHeight: '1.275em',
    margin: '5px 0',
    // color: '#7F9BAA'
    color: '#556D7E'
}
export const H2 = {
    textAlign: 'left',
    color: '#003755',
    font: '500 2.25em Danske Human',
    marginTop: '10px',
    '&:hover': {
        color: '#009FDA'
    }
};
export const image = {
    width: '100%',
    height: '55%',
    transition: 'transform 1s cubic-bezier(.215,.61,.355,1)'
}
export const flagIcons = {
    height: '28px',
    width: '28px',
    marginRight: '10px',
    cursor: 'pointer'
}
export const container = {
    position: 'relative',
    '&:hover div > img': {
        transform: 'scale(1.1)'
    },
    '&:hover a > img': {
        '-ms-transform': 'translateX(.3em)',
        transform: 'translateX(.3em)'
    }
}
export const flagsContent = {
    padding: '0 0 0 6.5%',
    position: 'absolute',
    bottom: '15px',
    width: '90%',
    '@media(min-width:0px) and (max-width:767px)': {
        bottom: '-25px'
    },
    '@media(min-width:768px) and (max-width:1024px)': {
        bottom: '5px'
    }
}
export const readMore = {
    float: 'right',
    fontSize: '.875em',
    marginTop: '7px',
    // color: '#003755',
    color: '#007BC7',
    cursor: 'pointer',
    '&:hover': {
        color: '#009FDA'
    }
}
export const rightArrow = {
    verticalAlign: 'middle',
    height: '16px'
}
export const imageContainer = {
    width: '100%',
    overflow: 'hidden'
}