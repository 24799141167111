import React from 'react';
import { css } from 'glamor';
import * as styles from './section.style';
import * as translate from '../../assets/translations/translations';
import Config from '../../app.config';

export default class Section extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div {...css(styles.mainContainer)}>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12" {...css(styles.padding0)}>
                        <p className="summary-text" dangerouslySetInnerHTML={{ __html: this.props.summary }} {...css(styles.summaryText)}></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12 " {...css(styles.padding0)}>
                        <img src={this.props.image} {...css(styles.image)} alt={'articleImage'} />
                    </div>
                </div>
            </div>
        )
    }
}