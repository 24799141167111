import React from 'react';
import * as translate from '../../assets/translations/translations';
import { css } from 'glamor';
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import CloseIcon from '../../assets/icons/close-small_darkblue.svg';
import DKFlag from '../../assets/icons/DK.svg';
import UKFlag from '../../assets/icons/GB.svg';
import SEFlag from '../../assets/icons/SE.svg';
import NOFlag from '../../assets/icons/NO.svg';
import FIFlag from '../../assets/icons/FIN.svg';
import Cookies from 'universal-cookie';
import classNames from 'classnames';

const CookiesAcceptedKey = "cookies-accepted" // used in index.html adobeAnalytics function.

const bannerCustomStyles = {
    backgroundColor: "#003755",
    color: "#fff",
    padding: "10px",
    fontSize: ".875em",
}

const H2 = {
    textAlign: 'left',
    color: '#003755',
    font: '500 2.25em Danske Human',
    marginBottom: '5px'
};

const closeImage = {
    width: "100%",
    height: "100%",
}
const readAboutCls = {
    textDecoration: 'underline'
}

export default class DbCookies extends React.Component {
    constructor(props) {
        super(props);

        const cookies = new Cookies();
        // May be null or undefined before users makes explicit choice.        
        let cookiesAccepted = cookies.get(CookiesAcceptedKey);
        // language is mixed together with Edition feature. When language is 'ALL' (meaning user selecter All Editions) we should set disclaimer language to EN.
        var lang = localStorage.getItem('language');
        if (lang == 'ALL' || lang == null) {
            lang = 'EN';
        }
        this.state = {
            showCookieBanner: (cookiesAccepted == null),
            showAboutCookies: false,
            currentLanguage: lang,
        };
        this.onAcceptCookies = this.onAcceptCookies.bind(this);
        this.onRejectCookies = this.onRejectCookies.bind(this);
        this.handleShowAboutCookies = this.handleShowAboutCookies.bind(this);
        this.handleHideAboutCookies = this.handleHideAboutCookies.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        Modal.setAppElement('#root');
    }

    onAcceptCookies() {
        const cookies = new Cookies();
        const expiresInDays = 180;
        let d = new Date();
        d.setTime(d.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
        cookies.set(CookiesAcceptedKey, "true", { path: "/", expires: d })

        this.setState({ showCookieBanner: false });
        window.adobeAnalytics();
    }

    onRejectCookies() {
        const cookies = new Cookies();
        const expiresInHours = 24;
        let d = new Date();
        d.setTime(d.getTime() + (expiresInHours * 60 * 60 * 1000));
        cookies.set(CookiesAcceptedKey, "false", { path: "/", expires: d })
        this.setState({ showCookieBanner: false });
    }

    handleShowAboutCookies() {
        this.setState({ showAboutCookies: true });
    }

    handleHideAboutCookies() {
        this.setState({ showAboutCookies: false });
    }

    handleChangeLanguage(newLanguage) {
        this.setState({ currentLanguage: newLanguage });
        localStorage.setItem('language', newLanguage);
        translate.strings.setLanguage(newLanguage);
    }

    render() {
        if (!this.state.showCookieBanner) return null;
        return (
            <div {...css(bannerCustomStyles)} className="row" aria-hidden="true">
                <div className="col-s-12 col-md-10">
                    {translate.strings.cookieconsent.bannerText}
                    &nbsp;
                    <a href="#" tabindex="-1" onClick={() => this.onRejectCookies()} style={{ color: "#FFFFFF" }}>{translate.strings.cookieconsent.rejectText}</a>
                    &nbsp;
                    <a href="#" tabindex="-1" onClick={() => this.handleShowAboutCookies()} style={{ color: "#FFFFFF" }}>
                        <span  {...css(readAboutCls)}>{translate.strings.cookieconsent.readMoreText}</span>
                    </a>
                    {this.state.showAboutCookies === true ? this.renderAboutCookies() : null}
                </div>
                <div className="col-s-12 col-md-2">
                    <button tabindex="-1" className="btn-block btn-danske btn mt-3 mt-md-0" style={{ background: '#FFFFFF', borderColor: '#FFFFFF', color: '#003755' }} onClick={() => { this.onAcceptCookies(); }}>{translate.strings.cookieconsent.acceptText}</button>
                </div>
            </div>
        );
    }

    renderAboutCookies() {
        return (
            <div aria-hidden="true">
                <Modal
                    isOpen={true}
                    onRequestClose={this.handleHideAboutCookies}
                    contentLabel="Cookie Policy"
                    shouldCloseOnOverlayClick={true}
                    overlayClassName="Overlay"
                    className="Modal"
                    aria-hidden="true"
                >
                    <div className="modal-flex-container">
                        <header className="modal-flex-header" >
                            <h2 className="h2" {...css(H2)}>{translate.strings.cookieconsent.tos.heading}</h2>
                            <div className="modal-flagBlock">
                                <img className={classNames('modal-flagIcon', { active: this.state.currentLanguage === 'DK' })} src={DKFlag} onClick={() => this.handleChangeLanguage('DK')} />
                                <img className={classNames('modal-flagIcon', { active: this.state.currentLanguage === 'SE' })} src={SEFlag} onClick={() => this.handleChangeLanguage('SE')} />
                                <img className={classNames('modal-flagIcon', { active: this.state.currentLanguage === 'NO' })} src={NOFlag} onClick={() => this.handleChangeLanguage('NO')} />
                                <img className={classNames('modal-flagIcon', { active: this.state.currentLanguage === 'FI' })} src={FIFlag} onClick={() => this.handleChangeLanguage('FI')} />
                                <img className={classNames('modal-flagIcon', { active: this.state.currentLanguage === 'EN' })} src={UKFlag} onClick={() => this.handleChangeLanguage('EN')} />
                            </div>
                            <a onClick={this.handleHideAboutCookies} aria-label="Close" className="button-close close-reveal-modal" >
                                <img {...css(closeImage)} src={CloseIcon} />
                            </a>
                            <small className="text-muted">{translate.strings.cookieconsent.tos.subheading}</small>
                        </header>
                        <hr />
                        {this.renderTosText()}
                    </div>
                </Modal>
            </div>
        );
    }

    renderTosText() {
        return (
            <div className="modal-flex-content" aria-hidden="true">
                <h3 className="h3">{translate.strings.cookieconsent.tos.h_1}</h3>
                <p>{translate.strings.cookieconsent.tos.p_1}</p>

                <h3 className="h3">{translate.strings.cookieconsent.tos.h_2}</h3>
                <p>{translate.strings.cookieconsent.tos.p_2}</p>

                <h3 className="h3">{translate.strings.cookieconsent.tos.h_3}</h3>
                <p>{translate.strings.cookieconsent.tos.p_3}</p>
                <p>{translate.strings.cookieconsent.tos.p_4}</p>
                <p>{translate.strings.cookieconsent.tos.p_5}</p>
                <p>{translate.strings.cookieconsent.tos.p_6}</p>

                <h3 className="h3">{translate.strings.cookieconsent.tos.h_4}</h3>
                <p>{translate.strings.cookieconsent.tos.p_7}</p>
                <p>{translate.strings.cookieconsent.tos.p_8}</p>
                <p>{translate.strings.cookieconsent.tos.p_9}</p>

                <h3 className="h3">{translate.strings.cookieconsent.tos.h_5}</h3>
                <p>{translate.strings.cookieconsent.tos.p_10}</p>

                <h4 className="h4">{translate.strings.cookieconsent.tos.h_6}</h4>
                <p><strong>{translate.strings.cookieconsent.tos.p_11_1}</strong> {translate.strings.cookieconsent.tos.p_11_2}</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_12_1}</strong> {translate.strings.cookieconsent.tos.p_12_2}</p>

                <h4 className="h4">{translate.strings.cookieconsent.tos.h_7}</h4>
                <p><strong>{translate.strings.cookieconsent.tos.p_13_1}</strong> {translate.strings.cookieconsent.tos.p_13_2}</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_14_1}</strong> {translate.strings.cookieconsent.tos.p_14_2}</p>

                <h4 className="h4">{translate.strings.cookieconsent.tos.h_8}</h4>
                <p><strong>{translate.strings.cookieconsent.tos.p_15_1}</strong> {translate.strings.cookieconsent.tos.p_15_2}</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_16_1}</strong> {translate.strings.cookieconsent.tos.p_16_2}</p>

                <h4 className="h4">{translate.strings.cookieconsent.tos.h_9}</h4>
                <p><strong>{translate.strings.cookieconsent.tos.p_17_1}</strong> {translate.strings.cookieconsent.tos.p_17_2}</p>
                <p><a tabindex="-1" href="http://www.adobe.com/privacy/analytics.html" target="_blank">{translate.strings.cookieconsent.tos.p_18}</a>.</p>
                <p><a tabindex="-1" href="https://www.d4t4solutions.com/example-privacy-policy/" target="_blank">{translate.strings.cookieconsent.tos.p_19}</a></p>
                <p><strong>{translate.strings.cookieconsent.tos.p_20_1}</strong> {translate.strings.cookieconsent.tos.p_20_2} <a href="http://www.qualtrics.com/privacy-statement/" target="_blank" tabindex="-1">{translate.strings.cookieconsent.tos.p_20_3}</a>.</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_21_1}</strong> {translate.strings.cookieconsent.tos.p_21_2}</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_22_1}</strong> {translate.strings.cookieconsent.tos.p_22_2}</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_23_1}</strong> {translate.strings.cookieconsent.tos.p_23_2}</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_24_1}</strong> {translate.strings.cookieconsent.tos.p_24_2} <a href="http://www.adobe.com/privacy/analytics.html" target="_blank" tabindex="-1">{translate.strings.cookieconsent.tos.p_24_3}</a>.</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_25_1}</strong> {translate.strings.cookieconsent.tos.p_25_2}</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_26_1}</strong> {translate.strings.cookieconsent.tos.p_26_2} <a href="http://viewer.zmags.com/privacypolicy?locale=da_DK" target="_blank" tabindex="-1">{translate.strings.cookieconsent.tos.p_26_3}</a>.</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_27}</strong></p>
                <p>{translate.strings.cookieconsent.tos.p_28}</p>
                <p>{translate.strings.cookieconsent.tos.p_29}</p>
                <p>{translate.strings.cookieconsent.tos.p_30}</p>
                <p><a tabindex="-1" href="https://www.facebook.com/about/privacy/" target="_blank">https://www.facebook.com/about/privacy/</a></p>
                <p><a tabindex="-1" href="https://twitter.com/privacy" target="_blank">https://twitter.com/privacy</a></p>
                <p><a tabindex="-1" href="https://www.linkedin.com/legal/privacy-policy" target="_blank">https://www.linkedin.com/legal/privacy-policy</a></p>

                <h4 className="h4">{translate.strings.cookieconsent.tos.h_10}</h4>
                <p><strong>{translate.strings.cookieconsent.tos.p_31_1}</strong> {translate.strings.cookieconsent.tos.p_31_2}</p>
                <p><strong>{translate.strings.cookieconsent.tos.p_32_1}</strong> {translate.strings.cookieconsent.tos.p_32_2}</p>
                <p>{translate.strings.cookieconsent.tos.p_33}</p>

                <h3 className="h3">{translate.strings.cookieconsent.tos.h_11}</h3>
                <p>{translate.strings.cookieconsent.tos.p_34}</p>
                <p>{translate.strings.cookieconsent.tos.p_35}</p>
                <p>{translate.strings.cookieconsent.tos.p_36_1} <a tabindex="-1" href="http://www.aboutcookies.org/how-to-delete-cookies/" target="_blank">{translate.strings.cookieconsent.tos.p_36_2}</a> {translate.strings.cookieconsent.tos.p_36_3}</p>
            </div>
        );
    }
}