import React, { Component }  from 'react';
import { Redirect } from 'react-router-dom';
import * as styles from './tabmenu.style';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import TabContent from './tabcontent';
import * as navigation from '../../configs/navigation';
import { css } from 'glamor';
import * as translate from '../../assets/translations/translations';
import backArrow from '../../assets/icons/arrow-link-back-darkblue.svg';
import nextArrow from '../../assets/icons/arrow-link-next_darkblue.svg';
import twitter from '../../assets/icons/Twitter.svg';
import youtube from '../../assets/icons/Youtube.svg';

export default class TabMenu extends Component {
    
      constructor(props) {
          super(props);
          this.state = {
            show : true,
            category: "",
            showPodcasts: false
          };
      }
      
      getContents = (items) =>{          
        return (
                <ul {...css(styles.padding15)}>
                    {items.map(item => 
                        <li {...css(styles.categories)} onClick={() => this.getMenuContents(item)}>                             
                             {item.text}  
                             {typeof item.submenu !== 'undefined' && <img src={nextArrow} {...css(styles.nextArrow)}/>}                          
                        </li>                                      
                    )}
                    <li title="Twitter12" {...css(styles.externalContent)}><a {...css(styles.externalContentAnchor)} href="https://twitter.com/danske_research?lang=en" target="blank"><img src={twitter} {...css(styles.externalImages)}/>Danske Bank Twitter</a></li>
                    {/*<li title="Youtube" {...css(styles.externalContent)}><a {...css(styles.externalContentAnchor)} href="https://www.youtube.com/channel/UCcHOIATRxWwfoJyxq4gk1-A" target="blank"><img src={youtube} {...css(styles.externalImages)}/>Danske Bank Youtube</a></li>                            */}
                </ul>  
        );
      };

      getMenuContents = (item) => {
        this.setState({show : false});
        this.props.showSearch(false);
        if (item.url === 'Podcasts') {
            if (typeof this.props.togglePodcastsVisibility !== 'undefined') {
                this.props.togglePodcastsVisibility();
            }
            this.setState({ showPodcasts: true });
        }
        else {
            this.setState({category : item});
        }
      };

      goBack = () => {                  
        this.setState({show : true});
        this.props.showSearch(true);
      };

      changeLanguage(language){
        translate.strings.setLanguage(language);
        this.setState({});
      }

      getEdition = () => {
        return <ul>
                    <li className="locale-items" {...css(styles.locale)}>
                        <span onMouseEnter={() => this.changeLanguage("EN")}> {translate.strings.TR1} </span>
                        <span>{translate.strings.TR2}</span>
                    </li>
                    <li className="locale-items" {...css(styles.locale)}>
                        <span onMouseEnter={() => this.changeLanguage("DK")}> {translate.strings.TR5}</span>
                        <span >{translate.strings.TR6}</span>
                    </li>
                    <li className="locale-items" {...css(styles.locale)}>
                        <span  onMouseEnter={() => this.changeLanguage("FI")}> {translate.strings.TR11}</span>
                        <span >{translate.strings.TR12}</span>
                    </li>
                    <li className="locale-items" {...css(styles.locale)}>
                        <span  onMouseEnter={() => this.changeLanguage("NO")}> {translate.strings.TR7}</span>
                        <span >{translate.strings.TR8}</span>
                    </li>
                    <li className="locale-items" {...css(styles.locale)}>
                        <span  onMouseEnter={() => this.changeLanguage("SE")}> {translate.strings.TR9}</span>
                        <span >{translate.strings.TR10}</span>
                    </li>
                    <li className="locale-items" {...css(styles.locale)}>
                        <span  onMouseEnter={() => this.changeLanguage("EN")}> {translate.strings.TR3}</span>
                        <span >{translate.strings.TR4}</span>
                    </li>
                </ul>
      };

      render() {  
          var items = navigation.navInfo;  
          var content = this.getContents(items);   
          var cont = this.getEdition(); 
          if (this.state.showPodcasts === true) {
              return <Redirect to={{ pathname: '/home', state: { showPodcasts: true } }} />
          }
          return (
              <div>
                  {
                      this.state.show ?
                          <div>
                              {content}
                          </div>
                          :
                          <div>
                              <ul {...css(styles.categoryItem)}>
                                  <li {...css(styles.selectedCategory)} onClick={() => this.goBack()}>
                                      <img src={backArrow}/>{this.state.category.text}
                                  </li>
                              </ul>
                              <TabContent item={this.state.category} showSearch={this.props.showSearch} />
                          </div>
                  }
              </div>
          );
      }
  };
