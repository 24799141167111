export const contactMargin = {
    marginTop: '230px'
}

export const aboutBankResearchParagraph = {
    textAlign: 'left',
    marginTop: '0',
    marginBottom: '10px',
    color: '#003755',
    fontWeight: '500',
    // fontSize: '16px',
    fontSize: '1.0000em',
    lineHeight: '24px',
    fontFamily: 'Danske Text',
}

export const faqTextFont = {
    color: '#003755',
    fontWeight: '600',
    lineHeight: '24px',
    fontFamily: 'Danske Text'
}

export const faqTextMargin = {
    marginBottom: '15px',
}

export const faqAnswersStyle = {
    color: '#003755',
    marginTop: '20px',
    marginBottom: '20px',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Danske Text'
}

export const collapsibleContainer = {
    marginTop: '50px',
    cursor: 'pointer'
}
export const subCategory = {
    color: '#003755',
    fontSize: '1em'
}
export const subCategoryCountry = {
    color: '#7F9BAA',
    fontSize: '.875em'
}
export const headingRow = {
    padding: '10px 0 5px 0',
    borderBottom: '1px solid rgba(0,55,85,0.2)'
}
export const categoryDetails = {
    color: '#003755',
    fontSize: '1em',
    padding: '30px 0'
}
export const heading = {
    padding: '105px 0 25px',
    '@media(min-width:0px) and (max-width:767px)': {
        padding: '40px 0 25px'
    }
};

export const equityFont = {
    font: '600 1em Danske Human'
}