export const H2 = {
    textAlign: 'left',
    color: '#002337',
    font: '500 2.25em Danske Human',
    marginBottom: '5px'
};
export const closeIcon = {
    width: '100%',
    height: '100%'
};

export const paragraph = {
    marginTop: '0',
    marginBottom: '20px',
    color: '#002337',
    lineHeight: '1.3em'
}

export const subParagraph = {
    marginTop: '0',
    marginBottom: '10px',
    color: '#002337',
    fontWeight: '800'
}

export const divParah = {
    marginTop: "40px",
    overflowY: 'auto',
    height: '700px',
}