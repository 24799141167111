import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './podcastcard.style';
import * as translate from '../../assets/translations/translations';
import rightArrow from '../../assets/icons/arrow-link-next_darkblue.svg';
import { Link } from 'react-router-dom';

export default class PodcastCard extends Component {
    constructor(props) {
        super(props);
    }

    renderLinks(links) {
        return links.map((link, key) => {
            return (
                <div>
                    {link.External ?
                        <a {...css(styles.readMore)}
                            target="_blank"
                            href={link.Link}>
                            {translate.strings[link.LinkText]} <img {...css(styles.rightArrow)} src={rightArrow} />
                        </a> :
                        <Link to={link.Link}
                            onClick={(e) => e.stopPropagation()}
                            {...css(styles.readMore)}>
                            {translate.strings[link.LinkText]} <img {...css(styles.rightArrow)} src={rightArrow} />
                        </Link>}
                </div>
            );
        });
    }

    renderThumbnail(imagepath) {
        return <img className="card-image" src={process.env.PUBLIC_URL + '/' + this.props.thumbnail} alt="Podcast Thumbnail" {...css(styles.cardImage)} />
    }

    render() {
        return (
            <div className="card" {...css(styles.card)}>
                {this.renderThumbnail(this.props.thumbnail)}
                <div className="card-body"  {...css(styles.cardBody)}>
                    <h4 {...css(styles.cardTitle)}>{this.props.title}</h4>
                    <h5 {...css(styles.cardSubtitle)}>({this.props.language})</h5>
                    <div {...css(styles.shareContainer)}>
                        {this.renderLinks(this.props.links)}
                    </div>
                </div>
            </div>
        );
    }
}