import {
    UPDATESUBSCRIPTION, RETRIVESUBSCRIPTION
} from './actions';

export const INITIAL_STATE = {
    subscriptionDetails: [],    
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATESUBSCRIPTION:
            return {
            ...state
            };
            
        case RETRIVESUBSCRIPTION:
            return {
            ...state
            };
        
        default:
            return state;
    }
  };