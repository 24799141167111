export const link = {
    color: '#003755',
    fontSize: '1em',
    fontWeight: '600',  
    cursor:'pointer',
    '&:hover':{
        color: '#003755',
        textDecoration: 'none'
    },
    '@media(min-width: 768px) and (max-width:1024px)': {
        fontSize: '.75em',
        width: '100% !important'
    },
    '@media(min-width: 1025px) and (max-width:1220px)': {
        fontSize: '.75em'        
    },
    '@media(min-width: 1221px) and (max-width:1380px)': {
        fontSize: '.9em'        
    }         
}