import * as nav from './navigation'

export const excludedCategories = [
    {
        "Category" : "Macroeconomics",
        "SubCategory" : "Danske Daily"
    },
    {
        "Category" : "Macroeconomics",
        "SubCategory" : "Weekly Focus"
    },
    {
        "Category" : "Forecasts"
    },
    {
        "Category" : "Foreign Exchange",
        "SubCategory" : "Danske Technical Update" 
    },
    {
        "Category" : "Fixed Income",
        "SubCategory" : "Danish Mortgage Bonds key figures"  
    },
    {
        "Category" : "Equities",
        "SubCategory" : "Aktienyt"
    },
    {
        "Category" : "Credit",
        "SubCategory" : "Credit Comments"
    },
    {
        "Category" : "Foreign Exchange",
        "SubCategory" : "FX Briefs"
    },
    {
        "Category" : "Fixed Income",
        "SubCategory" : "EUR Fixed Income Comments"
    },
    {
        "Category" : "Fixed Income",
        "SubCategory" : "DKK Fixed Income Comments"
    }
];   

export const equityCategories = nav.companyList.map(c => ({"Category": "Equities", "SubCategory": c.url}));
