export const paragraph = {
    textAlign: 'center',    
    color: '#003755',
    font: '600 1em Danske Text',
    marginTop: '25px' 
};

export const articleContent = {
    minHeight: 'calc(100vh - 350px)',
    maxWidth: '600px',
    marginTop: '50px',
    '@media(min-width:0px) and (max-width:767px)': {
        margin: '50px 5% 0 3% !important'
    }
}

export const disclaimerButton = {
    cursor: 'pointer',
    border: '1px solid rgba(0,55,85,0.12)',
    borderRadius: '100px',
    background: 'linear-gradient(0deg, #F5F5F5 0%, #FFFFFF 47.23%, #FFFFFF 100%)',
    padding: '15px 50px 15px 35px',
    color: '#003755',
    fontSize: '1em',
    position:'relative',
    marginTop: '50px',
    display:'inline-block'
};

export const textCenter = {
    textAlign: 'center'
}