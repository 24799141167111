import React, { Component }  from 'react';
import * as styles from './burgermenu.style';
import * as navigation from '../../configs/navigation';
import BurgerItem from './burgeritem';
import * as translate from '../../assets/translations/translations';
import searchIcon from '../../assets/icons/search_darkblue.svg';
import { Link, Redirect, withRouter } from 'react-router-dom';
import TabMenu from '../../components/tabmenu/tabmenu';
import { css } from 'glamor';
import expandIcon from '../../assets/icons/expand-small_darkblue.svg';
import collapseIcon from '../../assets/icons/collapse-small_darkblue.svg';

export default class BurgerMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
          show : true,
          searchText: '',
          props: props,
          showLocale: false
        };
        this.showSearchComponent = this.showSearchComponent.bind(this);
    }

 showSettings (item) {     
   return (
       <BurgerItem item={item} />
   );
 }
 
 updateInputValue(event) {        
    this.setState({
        searchText: event.target.value
    });        
}

 showSearchComponent = (value) => {        
    this.setState({show: value});      
}

toggleNav = () => {
    this.setState({ showLocale: ! this.state.showLocale })          
   
}

changeLanguage = (language) => {   
    translate.strings.setLanguage(language);
    this.setState({show: false})
    localStorage.setItem('language', language);     
    this.props.history.push(`/home/`);
    window.location.reload();    
}

showLocale(){
    return(
      <ul {...css(styles.localeDropdown)} className="country-list">
          <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("ALL")}>
            <span {...css(styles.localeTitle)}> ALL </span>           
          </li>
          <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("DK")}>
            <span {...css(styles.localeTitle)}> DK</span>            
          </li>
          <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("FI")}>
            <span {...css(styles.localeTitle)}>FI</span>            
          </li>
          <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("NO")}>
            <span {...css(styles.localeTitle)}> NO</span>            
          </li>
          <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("SE")}>
            <span {...css(styles.localeTitle)}>SE</span>            
          </li>
          <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("EN")}>
            <span {...css(styles.localeTitle)}> EN</span>            
          </li>
      </ul>
    );
  }

 render () {
    var items = navigation.navInfo;
    let language = localStorage.getItem('language') || 'EN'; 
   return (
    <div className="container-fluid">        
        {this.state.show ?<div className="row" {...css(styles.blueBackground)}>
            <div className="col-md-10 col-lg-10 col-sm-10 col-8" {...css(styles.searchColumn)}>                
                    <input type="text" placeholder={translate.strings.TR16} {...css(styles.searchInput)} onChange={evt => this.updateInputValue(evt)} type="text" value={this.state.searchText}/>
                        <Link to={`/articlesearch/${this.state.searchText}`}> 
                            <img src={searchIcon} alt={"search Icon"} />
                        </Link>                    
            </div> 
            <div className="col-md-2 col-lg-2 col-sm-2 col-3">
                    <ul {...css(styles.countryList)}>                                                                            
                        <li  onClick={() => this.toggleNav()}{...css(styles.countryListItems)}> <span>
                        { 
                            language == 'ALL'?  'ALL': language == 'DK'? 'DK': language == 'FI'? 'FI' : language == 'NO'? 'NO': language == 'SE'? 'SE': 'EN'                                    
                        }
                            </span>
                            <span> {this.state.showLocale? <img {...css(styles.directionIcon)} src= { collapseIcon } /> : <img {...css(styles.directionIcon)} src={expandIcon} /> } </span>

                            {this.state.showLocale ? this.showLocale(): null}
                        </li> 
                        
                    </ul>
            </div>           
        </div> : null }
        <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-12" {...css(styles.padding0)}>
                <TabMenu showSearch={this.showSearchComponent} togglePodcastsVisibility={this.props.togglePodcastsVisibility}/>  
            </div>
        </div>              
    </div>
   );
 }
}
