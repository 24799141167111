import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './header.style';
import logo from '../../../assets/icons/logo.svg';
import { Link, Redirect, withRouter } from 'react-router-dom';
import * as translate from '../../../assets/translations/translations';
import ReactDOM from 'react-dom';
import expandIcon from '../../../assets/icons/expand-small_darkblue.svg';
import collapseIcon from '../../../assets/icons/collapse-small_darkblue.svg';
import searchIcon from '../../../assets/icons/search_darkblue.svg';
import DKFlag from '../../../assets/icons/DK.svg';
import UKFlag from '../../../assets/icons/GB.svg';
import SEFlag from '../../../assets/icons/SE.svg';
import NOFlag from '../../../assets/icons/NO.svg';
import FIFlag from '../../../assets/icons/FIN.svg';
import GLOBALFlag from '../../../assets/icons/GLOBAL.svg';

export default class HeaderSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showSearch: false,
      showSearchButton: (this.props.showSearchButton === undefined ? true : this.props.showSearchButton),
      showHomeLink: (this.props.commr
        ? false
        : this.props.showHomeLink === undefined ? true : this.props.showHomeLink)
    };
    this.self = this;
  }

  changeLanguage = (language) => {
    translate.strings.setLanguage(language);
    this.setState({ show: false })
    localStorage.setItem('language', language);
    if (this.state.showHomeLink) {
      this.props.history.push(`/home/`);
    }
    window.location.reload();
  }

  showLocale() {
    return (
      <ul {...css(styles.localeDropdown)} className="country-list">
        <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("ALL")}>
          <span {...css(styles.localeTitle)}> <img {...css(styles.flagIcons)} src={GLOBALFlag} alt={translate.strings.TR2} /> {translate.strings.TR1} </span>
          <span {...css(styles.localeSubTitle)}>{translate.strings.TR2}</span>
        </li>
        <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("DK")}>
          <span {...css(styles.localeTitle)}> <img {...css(styles.flagIcons)} src={DKFlag} alt={translate.strings.TR5} /> {translate.strings.TR5}</span>
          <span {...css(styles.localeSubTitle)}>{translate.strings.TR6}</span>
        </li>
        <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("FI")}>
          <span {...css(styles.localeTitle)}> <img {...css(styles.flagIcons)} src={FIFlag} alt={translate.strings.TR11} /> {translate.strings.TR11}</span>
          <span {...css(styles.localeSubTitle)}>{translate.strings.TR12}</span>
        </li>
        <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("NO")}>
          <span {...css(styles.localeTitle)}> <img {...css(styles.flagIcons)} src={NOFlag} alt={translate.strings.TR7} /> {translate.strings.TR7}</span>
          <span {...css(styles.localeSubTitle)}>{translate.strings.TR8}</span>
        </li>
        <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("SE")}>
          <span {...css(styles.localeTitle)}> <img {...css(styles.flagIcons)} src={SEFlag} alt={translate.strings.TR9} /> {translate.strings.TR9}</span>
          <span {...css(styles.localeSubTitle)}>{translate.strings.TR10}</span>
        </li>
        <li className="locale-items" {...css(styles.locale)} onClick={() => this.changeLanguage("EN")}>
          <span {...css(styles.localeTitle)}> <img {...css(styles.flagIcons)} src={UKFlag} alt={translate.strings.TR3} /> {translate.strings.TR3}</span>
          <span {...css(styles.localeSubTitle)}>{translate.strings.TR4}</span>
        </li>
      </ul>
    );
  }

  toggleNav = () => {
    this.setState({ show: !this.state.show })
    if (this.props.navEvent) {
      setTimeout(() => { this.props.navEvent(this.state.show); }, 25)
    }
  }



  handleClick = (e) => {
    if (ReactDOM.findDOMNode(this.self).contains(e.target)) {

    }
    else {
      this.setState({ show: false })
    }
  };

  componentWillMount = () => {
    window.addEventListener('click', this.handleClick, false);
  };

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClick, false);
  };

  showSearchComponent = (value) => {
    this.props.showSearch(!value)
    this.setState({ showSearch: !value })
  }
  render() {
    let language = localStorage.getItem('language') || 'ALL';
    return (
      <header {...css(styles.headerContainer)}>
        <div className="container-fluid"  {...css(styles.headerContent)}>
          <div className="row">
            <div className="col-md-3 col-lg-3 col-sm-3 col-3">
              {this.state.showHomeLink ?
                <Link to="/home">
                  <img src={logo} alt={"DanskeBank Logo"} {...css(styles.logo)} />
                </Link> :
                <img src={logo} {...css(styles.logo)} alt={"DanskeBank Logo"} />
              }

            </div>
            <div className="col-md-1 col-lg-3 col-sm-1 col-1">

            </div>
            <div className="col-md-1 col-lg-1 col-sm-1 col-1">

            </div>
            {
              this.props.commr ?
                <div {...css(styles.logoPaddingBottom)} />
                :
                <div className="col-md-7 col-lg-5 col-sm-7 col-7">
                  <ul {...css(styles.countryList)}>
                    {this.state.showSearchButton &&
                      <li {...css(styles.countryListItems)} onClick={() => this.showSearchComponent(this.state.showSearch)}><img {...css(styles.directionIcon)} src={searchIcon} alt={"search icon"} /></li>
                    }
                    <li onClick={() => this.toggleNav()}{...css(styles.countryListItems)}>
                      <span>
                        {
                          language == 'EN' ? <img {...css(styles.flagIconsSelected)} src={UKFlag} alt={'UK'} /> : language == 'DK' ? <img {...css(styles.flagIconsSelected)} src={DKFlag} alt={"DK Flag"} /> : language == 'FI' ? <img {...css(styles.flagIconsSelected)} src={FIFlag} alt={"FI Flag"} /> : language == 'NO' ? <img {...css(styles.flagIconsSelected)} src={NOFlag} alt={"No Flag"} /> : language == 'SE' ? <img {...css(styles.flagIconsSelected)} src={SEFlag} alt={"SE Flag"} /> : <img {...css(styles.flagIconsSelected)} src={GLOBALFlag} alt={"Global Flag"} />
                        }
                      </span>
                      <span>
                        {
                          language == 'EN' ? translate.strings.TR3 : language == 'DK' ? translate.strings.TR5 : language == 'FI' ? translate.strings.TR11 : language == 'NO' ? translate.strings.TR7 : language == 'SE' ? translate.strings.TR9 : translate.strings.TR1
                        }
                      </span>
                      <span> {this.state.show ? <img {...css(styles.directionIcon)} src={collapseIcon} alt={"collapse Icon"} /> : <img {...css(styles.directionIcon)} src={expandIcon} alt={"Expand Icon"} />} </span>

                      {this.state.show ? this.showLocale() : null}
                    </li>
                  </ul>
                </div>
            }




          </div>
        </div>
      </header>
    );
  }
}