export const submenuContainer = {
    backgroundColor: '#F3F5F4',
    position: 'absolute',
    zIndex: '999',
    padding: '25px 7% 0 7% !important',
    borderRadius: '0 0 2px 2px',
    margin: '0 auto',  
    width: '100%',  
    '@media(max-width: 1023px)': {
        width: '100%'
    },
    '@media(min-width: 1440px)': {
        maxWidth: '1440px'
    }
}

export const submenuItemsTitle = {
    color: '#003755',
    fontSize: '1em',
    margin: '0 0 15px',
    fontWeight: '600',
    '@media(min-width: 768px) and (max-width:1024px)': {
        fontSize: '.825em'
    }
}

export const submenuItems = {
    color: '#003755',
    fontSize: '1em',
    margin: '10px 0',
    '&:hover':{
        color: '#009fda !important'
    },
    '@media(min-width: 768px) and (max-width:1024px)': {
        fontSize: '.825em'
    }    
}

export const submenuInnerContainer = {
    '@media(min-width: 1024px)': {
        margin: '0 auto',
    }
}

export const submenuNavigateParent = {
    marginBottom: '30px'
}


export const externalSubmenuItems = {
    color: '#009fda',
    fontSize: '1em',
    margin: '10px 0',
    '@media(min-width: 768px) and (max-width:1024px)': {
        fontSize: '.825em'
    },
    '&:hover':{
        color: '#009fda'
    }
}

export const listcontainer = {
    display:'inline-block',    
    marginBottom:'20px',
    verticalAlign:'top',
}

export const navOverlay = {
    position: 'absolute',
    zIndex: '998',
    height: '660vh',
    background: 'rgba(18,64,97,.8)',
    opacity: '.7',
    left:'-50%',
    width:'200%',
    '@media(min-width: 768px) and (max-width:1366px)': {
        width:'100%',
        left:'0%',
    }
}
export const hidePopupOnSmallSize = {
    '@media(max-width: 767px)': {
        display: 'None'
    }
}

export const imgRotate90 = {
    transform: 'rotate(270deg)',
    marginTop:'-2',
}