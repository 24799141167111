import React, { Component } from 'react';
import { css } from 'glamor';
import axios from 'axios';
import * as styles from './youtube.style';
import * as translate from '../../assets/translations/translations';
import CommonService from '../../services/common.service';

export default class Youtube extends Component {
    constructor() {
        super();
        this.state = { videoId: "", videoTitle: "", videoUrl: "", loaded:false};
    }
    componentDidMount() {
        var youtubeProps =  CommonService.getYoutubeProps();
        if(youtubeProps.videoUrl !== "")
        {
            this.setState({videoId:  youtubeProps.videoId});
            this.setState({videoUrl:  youtubeProps.videoUrl});
            this.setState({videoTitle: youtubeProps.videoTitle});
            this.setState({loaded:true});this.setState({loaded:true});
        }
        else
        {
            CommonService.loadYoutubePropsfromGoogleApis().then(res =>{
                this.setState({videoId:  res.videoId});
                this.setState({videoUrl:  res.videoUrl});
                this.setState({videoTitle: res.videoTitle});
                this.setState({loaded:true});this.setState({loaded:true});
            });
        }
    }

    render() {
        let language = localStorage.getItem('language') || 'EN'; 
        return (
            this.state.loaded?
            (
                language == 'DK' ?  
                <div>
                    <h2 {...css(styles.H2)}>{translate.strings.TR28}</h2>
                    <div className="row">
                        <div className="col-md-7 col-lg-7 col-sm-7 col-12" {...css(styles.videoContainer)}>
                            <iframe width="100%" height="520px" scrolling="no" frameborder="no" src={this.state.videoId}></iframe>
                        </div>
                        <div {...css(styles.titleBox)} className="col-md-5 col-lg-5 col-sm-5 col-12">
                            <p {...css(styles.title)}>{this.state.videoTitle}</p>
                                <button {...css(styles.titleButton)}><a {...css(styles.anchor)} target="_blank" href={this.state.videoUrl}>{translate.strings.TR195}</a></button>
                        </div>
                    </div>
                   
                </div>: 
                <div className="col-md-6 col-lg-6 col-sm-6 col-12" {...css(styles.videoContainerSmall)}>
                     <h2 {...css(styles.H2)}>{translate.strings.TR28}</h2>
                    <iframe width="100%" height="480px" scrolling="no" frameborder="no" src={this.state.videoId}></iframe>
                    <p {...css(styles.titlesmall)}>{this.state.videoTitle}</p>                    
                </div>                                    
                        
            ) : null
        ); 
    }
}