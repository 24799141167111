import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './search.style';
import searchIcon from '../../assets/icons/search_white.svg';
import { Link, Redirect, withRouter } from 'react-router-dom';
import * as translate from '../../assets/translations/translations';


class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: props.searchkey || '',
            searchByLatest: props.searchByLatest,
            props: props,
            homepage: props.homepage,
            commissionedResearch: props.commissionedResearch
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    updateInputValue(event) {
        this.setState({
            searchText: event.target.value
        });
    }

    handleKeyPress(target) {
        if (target.charCode == 13) {
            if (this.state.searchText !== "") {
                this.props.history.push(`/articlesearch/${this.state.searchText}${this.state.searchByLatest ? '/searchByLatest' : ''}`);
            }
        }
    }

    handleCheckboxChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    render() {
        return (
            <div>
                {this.state.homepage ?
                    <section {...css(styles.searchContainer)}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-12" role="region" aria-label={'Search by latest'}>
                                    <h1 {...css(styles.searchH2)} >{translate.strings.TR14}</h1>
                                    {/* <h2 {...css(styles.searchH2)}>{translate.strings.TR14}</h2> */}
                                    <div {...css(styles.searchInputContiner)}>
                                        <input aria-label="searchText" id="searchText" {...css(styles.searchInput)} onKeyPress={evt => this.handleKeyPress(evt)} type="text" value={this.state.searchText} onChange={evt => this.updateInputValue(evt)} placeholder={translate.strings.TR16} />
                                        <div {...css(styles.searchIconSection)}>
                                            <Link aria-label="Search" to={this.state.searchText ? `/articlesearch/${this.state.searchText}${this.state.searchByLatest ? '/searchByLatest' : ''}` : '/home'}>
                                                <img src={searchIcon} {...css(styles.searchIcon)} alt={"Search Icon"} />
                                            </Link>
                                        </div>
                                    </div>
                                    <label style={{ 'padding': '20px 10px' }} for="searchByLatest">
                                        <input aria-label="searchByLatest" name='searchByLatest' type="checkbox" checked={this.state.searchByLatest} onChange={this.handleCheckboxChange} style={{ 'transform': ' scale(1.5)' }} id="searchByLatest" />
                                        <span style={{ 'padding': '0 10px' }}>Search by latest</span>
                                    </label>
                                </div>

                            </div>

                        </div>
                    </section> :
                    <section {...css(styles.searchContainerDropdown)}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-12" role="region" aria-label={'Search by latest'}>
                                    <div {...css(styles.searchInputContiner)}>
                                        <input aria-label="searchText" id="searchText" {...css(styles.searchInput)} onKeyPress={evt => this.handleKeyPress(evt)} type="text" value={this.state.searchText} onChange={evt => this.updateInputValue(evt)} placeholder={translate.strings.TR16} />
                                        <div {...css(styles.searchIconSection)}>
                                            <Link aria-label="Search" to={this.state.searchText ? `/articlesearch/${this.state.searchText}${this.state.searchByLatest ? '/searchByLatest' : ''}` : '/home'}>
                                                <img src={searchIcon} {...css(styles.searchIcon)} alt={"search Icon"} />
                                            </Link>
                                        </div>
                                    </div>
                                    <label style={{ 'padding': '20px 10px' }} for="searchByLatest">
                                        <input aria-label="searchByLatest" name='searchByLatest' type="checkbox" checked={this.state.searchByLatest} onChange={this.handleCheckboxChange} style={{ 'transform': ' scale(1.5)' }} id="searchByLatest" />
                                        <span style={{ 'padding': '0 10px' }}>Search by latest</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </section>}
            </div>
        );
    }
}

export default withRouter(Search)