import React, { Component }  from 'react';
import * as styles from './tabmenu.style';
import {Tabs ,Tab} from 'react-bootstrap-tabs';
import TabItems from './tabitems';
import { Link } from 'react-router-dom';
import { css } from 'glamor';
import nextArrow from '../../assets/icons/arrow-link-next_darkblue.svg';
import * as translate from '../../assets/translations/translations';
import externalLink from '../../assets/icons/externalLinkIcon.svg';

export default class TabContent extends Component {
    
      constructor(props) {
          super(props);
          this.state = {
            show: false
          };
          this.hideNavigation = this.hideNavigation.bind(this)
      }

      generateSubmenuItems = (item) => {
        return <TabItems/>
      };

      hideNavigation = () => {
        alert(1)
        this.props.showSearch(false);
      }
      generateLink = () => {       
        return (<Link className="nav-item" to={`/articlelist/${this.props.text}`} onClick={this.hideNavigation()}>
                    <span className="nav-item-link-text">
                            {this.props.text}
                    </span>
                </Link>);
      };

      getContents = () =>{
        return (
                <ul >
                    {this.props.items.map(item => 
                        <li onClick={() => this.setState({show : true})}>                             
                                  <label>{item.text}</label>
                        </li>                                      
                    )}
                </ul>  
        );
      };

      generateSubmenuBlock = (submenu) => {
        if(submenu != undefined){
            var menu = submenu.map(this.generateSubmenu);
            return <div style={{backgroundColor:'white'}}> {menu} </div>
        }
      };

      generateSubmenu = (submenu) => {
        if(submenu != undefined){            
            var items = submenu.items.map(this.generateSubmenuItems);
            return <ul >
                    <li {...css(styles.submenuTitle)} >{submenu.title}</li>
                    {items}
            </ul>
        }  
      };

      generateSubmenuItems = (item) => {
        return <li {...css(styles.submenuLinks)}> 
                    {(item.text ==  translate.strings.TR13 || item.text == translate.strings.TR84 || item.text == translate.strings.TR85) ? <Link {...css(styles.link)} to={`/${item.url}`}> {item.text}<img src={nextArrow} {...css(styles.nextArrow)}/></Link> :
                    item.url == 'Other Comments' ?
                    <a target="blank" href="https://danskebank.com/-/media/pdf/danske-research/other-comments.pdf" {...css(styles.link)} >{item.text} <img src={externalLink} /></a> :
                    item.url == 'Danske Equities Log In' ?
                    <a target="blank" href="https://equities.danskemarkets.com/pub/logon/logon.aspx?ss=EP" {...css(styles.link)} >{item.text} <img src={externalLink} /></a> : 
                    <Link {...css(styles.link)} to={`/articlelist/${item.url}`}> {item.text}<img src={nextArrow} {...css(styles.nextArrow)}/></Link>} 
              </li>
      };
    
      render() {     
          var submenu = this.generateSubmenuBlock(this.props.item.submenu);
          return (
             <div {...css(styles.submenuContainer)}>                                  
                 {submenu}
                 {this.state.show ? submenu: null}                   
            </div>         
          );
      }
  };