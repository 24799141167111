export const card = {
    marginTop: '40px',
    border:'none !important',
    height: '90%',
    overflow: 'hidden',
    '&:hover > img': {
        transform: 'scale(1.1)'
    },
    '&:hover a > img': {
        transform: 'none',
        '-ms-transform': 'none'
    }  
};

export const cardBody = {
    padding: '30px 8%',
    position: 'relative'
};

export const cardTitle = {
    fontWeight: '600',
    fontSize: '1.25em',
    lineHeight: '1.275em',
    color: '#003755'
}

export const cardSubtitle = {
    fontWeight: '600',
    fontSize: '1em',
    lineHeight: '1.275em',
    margin: '6px 0 50px',
    color: '#003755'
}

export const rightArrow = {
    verticalAlign: 'middle',
    height: '16px',
    transition: 'all .12s cubic-bezier(0,.3,.7,1)',
    '@media(min-width: 768px) and (max-width:1024px)': {
        display:'inline-block !important',
        width:'auto !important'
    },
    '@media(min-width: 0px) and (max-width:767px)': {
        display:'inline-block !important',
        width:'auto !important'
    } 
}

export const shareContainer = {
    bottom: '15px', 
    position: 'absolute',   
    left: '0',
    right: '0',
    padding: '0 7%',
    marginBottom: '5px',
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'flex-end'
}

export const cardImage = {
    borderRadius: '5px',
    height: '190px',
    transition: 'transform 1s cubic-bezier(.215,.61,.355,1)',
    '@media(max-width: 1024px)' : {
        display : 'none'
    }
};

export const readMore = {
    float: 'right',
    fontSize: '.875em',
    marginTop: '7px',
    color: '#003755',
    cursor: 'pointer',
    '&&:hover img': {
        '-ms-transform': 'translateX(.3em)',
        transform: 'translateX(.3em)'
    },
    '&:hover' : {
        color: '#009FDA'
    }
}