export const infocusContainer = {    
    padding: '50px 7%'
};
export const H2 = {
    textAlign: 'center',    
    color: '#003755',
    font: '500 2.25em Danske Human'
};
export const button = {
    border: '1px solid rgba(0,55,85,0.12)',
    borderRadius: '100px',
    background: 'linear-gradient(0deg, #F5F5F5 0%, #FFFFFF 47.23%, #FFFFFF 100%)',
    padding: '15px 50px 15px 35px',
    color: '#003755',
    fontSize: '1em',
    position:'relative',
    marginTop: '50px',
    display:'inline-block'
}
export const rightArrow = {
    position: 'absolute',
    right: '20px',    
    height: '20px'
}