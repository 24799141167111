export const menubar = {
    padding: '0',
    overflow: 'auto',
    marginBottom: '0'    
}
export const headerContainer = {
    '@media(max-width: 767px)': {
        display : 'none'
    }
}

export const underline = {
    padding: '0 7% 0 7% !important',
    borderBottom: '2px solid #EFF0F0'
};

export const column = {
    padding: '0',
};

export const externalContent = {
    float: 'right',
    marginLeft: '10%',
    '@media(min-width: 768px) and (max-width:1024px)': {
        marginLeft: '6%'
    }
}
export const underlineTablet = {
    '@media(min-width: 1024px)': {
        display : 'none !important'
    }
}
export const popupMenuContainer = {
    padding: 0,
    margin: 0,
    border: 0,
}
